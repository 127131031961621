import { REGISTERATION_REDUCER } from "../constants/actionConstants";

export const initialState = {
  isEmailIdExist: false,
  registerEmailId: "",
  registerPhoneNumber: "",
  userId: "",
  registerUserName: "",
  isSignUpClick: false,
  sendOtp: 0,
  isOtpInvalid: false,
  isOtpSend: false,
  isUserVerified: false,
  userPassword: "",
  confirmPassword: "",
  companyList: [],
  selectedUserCompany: {},
  createdCompanyId: "",
  companyCreationSuccess: false,
  isJoinButtonClick: false,
  registartionStatus: "",
  successRegisterUser: false,
  isCompanyRegisterSuccess: false,
};

export const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTERATION_REDUCER.SET_IF_EMAIL_EXIST:
      return { ...state, isEmailIdExist: action.value };
    case REGISTERATION_REDUCER.REGISTER_EMAIL_ID:
      return { ...state, registerEmailId: action.value };
    case REGISTERATION_REDUCER.REGISTER_PHONE_NUMBER:
      return { ...state, registerPhoneNumber: action.value };
    case REGISTERATION_REDUCER.REGISTER_USER_NAME:
      return { ...state, registerUserName: action.value };
    case REGISTERATION_REDUCER.IS_SIGN_UP_CLICK:
      return { ...state, isSignUpClick: action.value };
    case REGISTERATION_REDUCER.SET_IS_COMPANY_REGISTER_SUCCESS:
      return { ...state, isCompanyRegisterSuccess: action.value };
    case REGISTERATION_REDUCER.SEND_OTP_VALUE:
      return { ...state, sendOtp: action.value };
    case REGISTERATION_REDUCER.SET_IS_OTP_INVALID:
      return { ...state, isOtpInvalid: action.value };
    case REGISTERATION_REDUCER.IS_OTP_SEND:
      return { ...state, isOtpSend: action.value };
    case REGISTERATION_REDUCER.SET_REGISTER_USER_ID:
      return { ...state, userId: action.value };
    case REGISTERATION_REDUCER.IS_USER_VERIFIED:
      return { ...state, isUserVerified: action.value };
    case REGISTERATION_REDUCER.SET_USER_PASSWORD:
      return { ...state, userPassword: action.value };
    case REGISTERATION_REDUCER.SET_COMPANY_LIST:
      return { ...state, companyList: action.value };
    case REGISTERATION_REDUCER.SET_SELECTED_COMPANY:
      return { ...state, selectedUserCompany: action.value };
    case REGISTERATION_REDUCER.SET_CREATED_COMPANY_ID:
      return { ...state, createdCompanyId: action.value };
    case REGISTERATION_REDUCER.SET_SUCCESS_COMPANY_CREATION:
      return { ...state, companyCreationSuccess: action.value };
    case REGISTERATION_REDUCER.SET_IS_JOIN_BUTTON_CLICK:
      return { ...state, isJoinButtonClick: action.value };
    case REGISTERATION_REDUCER.SET_REGISTRATION_STATUS:
      return { ...state, registartionStatus: action.value };
    case REGISTERATION_REDUCER.SET_SUCCESS_REGISTER_USER:
      return { ...state, successRegisterUser: action.value };
    default:
      return state;
  }
};
