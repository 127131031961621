import { callApi } from "./apiHelper";

export const getInfluencerCities = (data) => {
  return callApi(
    "/resource/eventsMatrix/getEventDetailsByCityOrCountry",
    "POST",
    data
  );
};

export const getInfluencerEventMatrix = (data) => {
  return callApi("/resource/eventsMatrix/getEventDetails", "POST", data);
};

export const getAllInfluencerEventMatrix = (data) => {
  return callApi("/resource/eventsMatrix/getAllEventDetails", "POST", data);
};

export const getEventDetailsByDevicePlatform = (data) => {
  return callApi(
    "/resource/eventsMatrix/getEventDetailsByDevicePlatform",
    "POST",
    data
  );
};
