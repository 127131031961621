import { SEGMENTATION_REDUCER } from "../constants/actionConstants";
import moment from "moment";

export const initialState = {
  isGetFieldLIstSuccess: false,
  userPropertyFieldList: [],
  queryCount: [],
  isQueryLoaded: false,
  failerQueryCount: false,
  showData: false,
  recentSegmentData: {},
  userQueryRequestData: [],
  downloadUrlArray: [],
  isFetchDataLoaded: true,
  downlaodUrlLink: "",
  segmentListByCampaign: [],
};
export const segmentationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEGMENTATION_REDUCER.SET_IS_FIELD_LIST_SUCCESS:
      return { ...state, isGetFieldLIstSuccess: action.value };
    case SEGMENTATION_REDUCER.SET_USER_PROPERTY_FIELD_LIST:
      return { ...state, userPropertyFieldList: action.value };
    case SEGMENTATION_REDUCER.SET_IS_QUERY_LOADED:
      return { ...state, isQueryLoaded: action.value };
    case SEGMENTATION_REDUCER.SET_DOWNLOAD_URL_LINK:
      return { ...state, downlaodUrlLink: action.value };
    case SEGMENTATION_REDUCER.IS_FETCH_DATA_LOADED:
      return { ...state, isFetchDataLoaded: action.value };
    case SEGMENTATION_REDUCER.SET_FETCH_DATA_ARRAY:
      return { ...state, userQueryRequestData: action.value };
    case SEGMENTATION_REDUCER.SET_RECENT_SEGMENT_DATA:
      return { ...state, recentSegmentData: action.value };
    case SEGMENTATION_REDUCER.SET_SEGMENT_LIST_BY_CAMPAIGN:
      return { ...state, segmentListByCampaign: action.value };
    case SEGMENTATION_REDUCER.SET_QUERY_COUNT_REQUEST_ERROR:
      return { ...state, failerQueryCount: action.value };
    case SEGMENTATION_REDUCER.SET_SHOW_SEGMENT_DATA:
      return { ...state, showData: action.value };
    case SEGMENTATION_REDUCER.SET_DOWNLOAD_URL:
      return {
        ...state,
        downloadUrlArray: [
          {
            segmentId: action?.value?.segmentId,
            url: action?.value?.url,
          },
          ...state.downloadUrlArray,
        ],
      };
    case SEGMENTATION_REDUCER.SET_QUERY_COUNT_NUMBER: {
      return {
        ...state,
        queryCount: [
          {
            queryTime: moment().format("hh:mm A DD MMM YYYY"),
            source: "Segmentation",
            count: action?.value?.count,
            segmentId: action?.value?.segmentId,
            showData: action?.value?.showData,
            button:
              action.value > 0 && state.showData === true
                ? "Show Data"
                : "Fetch Data",
          },
          ...state.queryCount,
        ],
      };
    }
    default:
      return state;
  }
};
