import React from "react";
import { Card, Col, Row, ListGroupItem, ListGroup } from "react-bootstrap";

const CampaignInfluencerCard = (props) => {
  const { inf } = props;

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <Card.Title>{inf.influencerName}</Card.Title>
            <Card.Text></Card.Text>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <Row>
                  <strong>Short Link :</strong>
                  <Card.Link href={inf.shortLink} style={{ color: "blue" }}>
                    {inf.shortLink}
                  </Card.Link>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <strong>UTM Link:</strong>
                  <Card.Link href={inf.utmLink} style={{ color: "blue" }}>
                    {inf.utmLink}
                  </Card.Link>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CampaignInfluencerCard;
