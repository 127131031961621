import React, { Fragment, useEffect, useState } from "react";
import StepTwoAudianceDetails from "../Manage/AddCampaign/StepTwoAudianceDetails";
import StepThreeInfluencerDetails from "../Manage/AddCampaign/StepThreeInfluencerDetails";
import StepOneCampaignDetails from "./AddCampaign/StepOneCampaignDetails";
import StepFourDetails from "./AddCampaign/StepFourDetails";
import "./progress-task.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const AddCampaigns = () => {
  const [step, setstep] = useState(1);
  const { isUpdateCampaignClick } = useSelector(
    (state) => state.addCampaignReducers
  );
  const { campaignById } = useSelector((state) => state.dashboardReducers);
  const [currentStep, setCurrentStep] = useState(1);
  const [formTitle, setFormTitle] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  /*************************************************************** */
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  /*************************************************************** */
  const [stepOneDetails, setStepOneDetails] = useState({
    nameCamp: "",
    minBuget: "",
    maxBuget: "",
    startDate: "",
    endDate: "",
    // weblink: "",
    goalList: [],
    eventList: [],
  });

  const [stepTwoDetails, setStepTwoDetails] = useState({
    audienceAge: [],
    audienceGender: [],
    audienceIntrest: [],
    platformTags: ["Youtube", "Instagram"],
  });

  const [stepThreeDetails, setStepThreeDetails] = useState({
    influArray: [
      {
        name: "",
        webpageTotarget: "",
        handle: [{ platform: "Youtube", platformHandler: "" }],
      },
    ],
    hashTags: ["#tag"],
    countriesArray: [{ country: "", cities: [] }],
  });
  const [influArray, setInfluArray] = useState([
    {
      name: "",
      webpageTotarget: "",
      handle: [{ platform: "Youtube", platformHandler: "" }],
    },
  ]);
  const [hashTags, setHashTags] = useState(["#tag"]);
  const [countriesArray, setCountriesArray] = useState([
    { country: "", cities: [] },
  ]);

  useEffect(() => {
    if (currentStep === 1) {
      setFormErrors(validate(stepOneDetails));
    } else if (currentStep === 2) {
      setFormErrors(validate(stepTwoDetails));
    } else {
      setFormErrors(validate({ influArray, hashTags, countriesArray }));
    }
  }, [
    stepOneDetails,
    stepTwoDetails,
    currentStep,
    formValues,
    influArray,
    hashTags,
    countriesArray,
  ]);

  const notifyTopFullWidth = (msg) => {
    toast.error(`❗ ${msg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (isUpdateCampaignClick) {
      const goalList = campaignById.goals.map((data) => {
        return {
          value: data,
          label: data,
        };
      });
      const eventList = campaignById.eventsToTrack.map((data) => {
        return {
          value: data,
          label: data,
        };
      });
      setStepOneDetails({
        ...stepOneDetails,
        nameCamp: campaignById.name,
        minBuget: campaignById.budget.min,
        maxBuget: campaignById.budget.max,
        startDate: campaignById.startDate,
        endDate: campaignById.endDate,
        // weblink: campaignById.webpageToTarget,
        goalList: goalList,
        eventList: eventList,
      });
      const audienceGenderList = campaignById?.audienceDetails?.gender?.map(
        (data) => {
          return {
            value: data,
            label: data,
          };
        }
      );
      const audienceAgeList = campaignById?.audienceDetails?.ageGroup?.map(
        (data) => {
          return {
            value: data,
            label: data,
          };
        }
      );
      const audienceIntrestList = campaignById?.audienceDetails?.interest?.map(
        (data) => {
          return {
            value: data,
            label: data,
          };
        }
      );
      setStepTwoDetails({
        ...stepTwoDetails,
        audienceAge: audienceAgeList,
        audienceGender: audienceGenderList,
        audienceIntrest: audienceIntrestList,
        platformTags: campaignById.platform,
      });
      setInfluArray([...campaignById.influencers]);
      setHashTags([...campaignById.hashTags]);
      setCountriesArray([...campaignById.geoAreaOfImpact]);
    }
  }, [isUpdateCampaignClick, campaignById]);

  const validate = (values) => {
    let errors = {};

    const urlValidation = (value) => {
      if (
        value.match(
          /(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        ) === null
      )
        return true;
      else return false;
    };

    const handleValidation = (value, platform) => {
      switch (platform) {
        case "Youtube": {
          if (value.startsWith("https://") || value.startsWith("http://")) {
            if (urlValidation(value)) {
              // return "Please Enter Valid Youtube Channel Link";
              return true;
            } else {
              return false;
            }
          } else return false;
        }
        // case "Instagram": {
        //   if (
        //     value.match(
        //       /(?:#)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/
        //     ) === null
        //   )
        //     return true;
        //   else return false;
        // }
        // case "TikTok": {
        //   if (
        //     value.match(
        //       /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
        //     ) === null
        //   )
        //     return true;
        //   else return false;
        //   // return "Please Enter valid username";
        // }
        // case "Twitter": {
        //   if (value.match(/^@?(w){1,15}$/) === null) return true;
        //   else return false;
        //   // return "Please Enter valid username";
        //   // else return false;
        // }
      }
    };

    const usernameVerification = (value) => {
      if (value.match(/^[a-zA-Z0-9._]+$/) === null) return true;
      else return false;
    };
    if (currentStep === 1) {
      if (!values.nameCamp) {
        errors.nameCamp = "Please Enter Campaign Name";
      }
      if (!values.minBuget) {
        errors.minBuget = "Please Enter Minimun Buget";
      }
      if (!values.maxBuget) {
        errors.maxBuget = "Please Enter Maximun Buget";
      }
      if (values.minBuget > values.maxBuget) {
        errors.minBuget = "Min Budget should be less then Max budget";
        errors.maxBuget = "Max Budget should be greater then Min budget";
      }
      if (!values.startDate || !values.endDate) {
        errors.date = "Please set the Date Range";
      }
      if (!values.goalList || values.goalList.length === 0) {
        errors.goalList = "Please Select at least one Goal";
      }
      if (!values.eventList || values.eventList.length === 0) {
        errors.eventList = "Please Select at least one Event ";
      }
      // if (!values.weblink) {
      //   errors.webLink = "Please Enter website link";
      // }
    }
    if (currentStep === 2) {
      if (!values.audienceAge || values.audienceAge.length === 0) {
        errors.audienceAge = "Please Select at least one Age group";
      }
      if (!values.audienceGender || values.audienceGender.length === 0) {
        errors.audienceGender = "Please Select at least one Gender";
      }
      if (!values.audienceIntrest || values.audienceIntrest.length === 0) {
        errors.audienceIntrest = "Please Select at least one Intrest";
      }
      if (!values.platformTags || values.platformTags.length === 0) {
        errors.platformTags = "Please Select at least one Platform tags";
      }
    }
    if (currentStep === 3) {
      if (values?.influArray?.length !== 0) {
        values?.influArray?.map((infobj, i) => {
          if (!infobj.name) {
            errors["name" + i] = i;
            errors.name = "Please Enter Influerncer Name";
          }
          if (/\d/.test(infobj.name)) {
            errors["name" + i] = i;
            errors.name = "Influencer Name should only contains Alphabets";
          }
          if (!infobj.webpageTotarget) {
            errors.webpageTotarget = "Please Enter Webpage Target Link";
          }
          if (
            infobj.webpageTotarget.match(
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            ) === null
          ) {
            errors["webpage" + i] = i;
            errors.webpageTotarget = "Please Enter Valid URL Format";
          }
          if (infobj?.handle?.length !== 0) {
            infobj.handle.map((handlerObj, handleI) => {
              if (!handlerObj.platform) {
                errors.platform = "Please Enter Platform";
              }

              if (
                !handlerObj.platformHandler &&
                handlerObj.platform === "Youtube"
              ) {
                errors["handlerIndex" + i + handleI] = i + handleI;
                errors["platformHandler" + i + handleI] =
                  "Please add Channel Link or Channel Name";
              }

              if (
                !handlerObj.platformHandler &&
                handlerObj.platform !== "Youtube"
              ) {
                errors["handlerIndex" + i + handleI] = i + handleI;
                errors["platformHandler" + i + handleI] =
                  "Please Enter Username";
              }
              // if(handleValidation(handlerObj.platformHandler,handlerObj.platform)){

              // }
              if (
                handlerObj.platformHandler !== "" &&
                handleValidation(
                  handlerObj.platformHandler,
                  handlerObj.platform
                )
              ) {
                errors["handlerIndex" + i + handleI] = i + handleI;
                errors["platformHandler" + i + handleI] = "Invalid handler";
              }
              // if (
              //   handleValidation(
              //     handlerObj.platformHandler,
              //     handlerObj.platform
              //   )
              // ) {
              //   errors["platformHandler" + i] = i;
              //   errors.platformHandler =
              //     handlerObj.platform === "Youtube"
              //       ? "Please add valid Channel Link or Channel Name"
              //       : "Please Enter valid Username";
              // }
            });
          }
        });
      }
      if (values?.hashTags?.length === 0) {
        errors.hashTags = "Please add at least one Hash Tags";
      }
      if (values?.countriesArray?.length !== 0) {
        values?.countriesArray?.map((countryobj) => {
          if (!countryobj?.country || countryobj?.country?.length === 0) {
            errors.country = "Please Select Country";
          }
          if (!countryobj?.cities || countryobj?.cities?.length === 0) {
            errors.cities = "Please Select at least one City";
          }
        });
      }
    }

    return errors;
  };

  const renderData = (step) => {
    switch (step) {
      case 1:
        return (
          <div>
            <StepOneCampaignDetails
              nextStep={nextStep}
              validateFunction={validate}
              setFormErrors={setFormErrors}
              setCurrentStep={setCurrentStep}
              setFormTitle={setFormTitle}
              formErrors={isSubmit && formErrors}
              currentStep={currentStep}
              setStepOneDetails={setStepOneDetails}
              stepOneDetails={stepOneDetails}
              setFormValues={setFormValues}
            />
          </div>
        );
      case 2:
        return (
          <div className="App">
            <StepTwoAudianceDetails
              nextStep={nextStep}
              prevStep={prevStep}
              isUpdateCampaignClick={isUpdateCampaignClick}
              setCurrentStep={setCurrentStep}
              setFormTitle={setFormTitle}
              currentStep={currentStep}
              formErrors={isSubmit && formErrors}
              setStepTwoDetails={setStepTwoDetails}
              setFormValues={setFormValues}
              stepTwoDetails={stepTwoDetails}
            />
          </div>
        );
      case 3:
        return (
          <div className="App">
            <StepThreeInfluencerDetails
              prevStep={prevStep}
              nextStep={nextStep}
              isUpdateCampaignClick={isUpdateCampaignClick}
              setCurrentStep={setCurrentStep}
              setFormValues={setFormValues}
              currentStep={currentStep}
              formErrors={isSubmit && formErrors}
              setFormTitle={setFormTitle}
              setStepThreeDetails={setStepThreeDetails}
              stepThreeDetails={stepThreeDetails}
              influArray={influArray}
              hashTags={hashTags}
              countriesArray={countriesArray}
              setInfluArray={setInfluArray}
              setHashTags={setHashTags}
              setCountriesArray={setCountriesArray}
            />
          </div>
        );
      case 4:
        return (
          <StepFourDetails
            prevStep={prevStep}
            nextStep={nextStep}
            isUpdateCampaignClick={isUpdateCampaignClick}
            campaignById={campaignById}
            campaignId={campaignById._id}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setFormTitle={setFormTitle}
            stepOneDetails={stepOneDetails}
            stepTwoDetails={stepTwoDetails}
            influArray={influArray}
            hashTags={hashTags}
            countriesArray={countriesArray}
          />
        );
      default:
        return <div className="App"></div>;
    }
  };

  const handleOnValidInput = () => {
    setIsSubmit(false);
  };
  return (
    <Fragment>
      <div className="row">
        <ToastContainer
          style={{ width: "400px" }}
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="col-xl-12 col-xxl-12" style={{ height: "50%" }}>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{formTitle}</h4>
            </div>
            <div className="card-body">{renderData(step)}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddCampaigns;
