import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, CloseButton } from "react-bootstrap";
import {
  DoubleValueInputField,
  BadgeValue,
} from "../../components/Comman/ReadOnlyInputField";
import moment from "moment";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import CampaignUpdate from "./CampaignUpdate";
import { useDispatch, useSelector } from "react-redux";
import CampaignDetailsTable from "../Dashboard/CampaignDetailsTable";
import { deleteCampaignRequest } from "../../../actions/Campaign/addCampaignAction";
import DynamicInfluencerPostFields from "../Comman/DynamicInfluencerPostFields";

const CampaignSummary = (props) => {
  const { toShowInfluencer, campaignById } = props;
  const [showAddPost, setShowAddPost] = useState(false);
  const history = useHistory();
  const [influencerArray, setInfluencerArray] = useState([]);
  const dispatch = useDispatch();
  const { showEditCampaignForm } = useSelector(
    (state) => state.addCampaignReducers
  );

  const [updateObj, setUpdateObj] = useState({
    startDate: campaignById?.startDate,
    endDate: campaignById?.endDate,
    minBudget: campaignById?.budget?.min,
    maxBudget: campaignById?.budget?.max,
    goals:
      campaignById?.goals?.length !== 0 &&
      campaignById?.goals?.map((data) => {
        return { value: data, label: data };
      }),
    ageGroup:
      campaignById?.audienceDetails?.ageGroup.length !== 0 &&
      campaignById?.audienceDetails?.ageGroup?.map((data) => {
        return { value: data, label: data };
      }),
    gender:
      campaignById?.audienceDetails?.gender.length !== 0 &&
      campaignById?.audienceDetails?.gender?.map((data) => {
        return { value: data, label: data };
      }),
    interest:
      campaignById?.audienceDetails?.interest.length !== 0
        ? campaignById?.audienceDetails?.interest?.map((data) => {
            return { value: data, label: data };
          })
        : [],
    platform: campaignById?.platform,
    influencers: campaignById?.influencers,
  });

  const cancleStyle = {
    fontSize: "26px",
  };
  useEffect(() => {
    const influencerUpdate = campaignById?.influencers;
    const postArray = [""];

    for (let j = 0; j < influencerUpdate?.length; j++) {
      let handleArray = influencerUpdate[j]?.handle;
      for (let i = 0; i < handleArray?.length; i++) {
        handleArray[i].postUrl = postArray;
      }
    }

    setUpdateObj({
      startDate: campaignById?.startDate,
      endDate: campaignById?.endDate,
      minBudget: campaignById?.budget?.min,
      maxBudget: campaignById?.budget?.max,
      goals:
        campaignById?.goals?.length !== 0 &&
        campaignById?.goals?.map((data) => {
          return { value: data, label: data };
        }),
      ageGroup:
        campaignById?.audienceDetails?.ageGroup.length !== 0 &&
        campaignById?.audienceDetails?.ageGroup?.map((data) => {
          return { value: data, label: data };
        }),
      gender:
        campaignById?.audienceDetails?.gender.length !== 0 &&
        campaignById?.audienceDetails?.gender?.map((data) => {
          return { value: data, label: data };
        }),
      interest:
        campaignById?.audienceDetails?.interest.length !== 0 &&
        campaignById?.audienceDetails?.interest?.map((data) => {
          return { value: data, label: data };
        }),
      platform: campaignById?.platform,
      influencers: influencerUpdate,
    });
  }, [campaignById]);

  useEffect(() => {
    setInfluencerArray(updateObj?.influencers);
  }, [updateObj]);

  let array = [""];

  const handleAddInfluFields = (influencerId, handlerId) => {
    console.log("influencerId", influencerId, "handlerId", handlerId);
    const values = { ...updateObj };

    let handleObj = values.influencers[influencerId].handle[handlerId];

    handleObj.postUrl.push("");
    console.log("handle", handleObj);
    console.log("Values", values?.influencers);
    setUpdateObj({ ...values });
  };

  const handleRemoveFields = (influncerId, handlerId, postUrlIndex) => {
    // const values = { ...updateObj };
    // values.splice(index, 1);
    // setAddPostArray([...addPostArray]);
  };

  // const handleRemoveFields = (influncerId, handlerId) => {
  //   const values = { ...updateObj };
  //   // values.splice(index, 1);
  //   // setAddPostArray([...addPostArray]);
  // };
  const onDeleteButtonClick = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this campaign!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteCampaignRequest({ id: campaignById._id }));
      }
    });
  };

  const handleOnChanges = (
    e,
    influencerId,
    handlerId,
    postUrlIndex,
    data,
    platform,
    influencerName
  ) => {
    const value = updateObj;
    console.log(
      "Influencer--->",
      influencerId,
      "handlerId",
      handlerId,
      "postUrlIndex",
      postUrlIndex,
      "data",
      data,
      "platform",
      platform,
      "influencerName",
      influencerName
    );

    // let handleObj = data;
    // handleObj.postUrl[postUrlIndex] = e.target.value;
    // console.log("HandleObj---->", handleObj);
    // const handleId = value.influencers[influencerId].handle[handlerId];
    // // .postUrl[postUrlIndex] =
    // //   e.target.value;

    // const postArray = handleId?.postUrl;
    // postArray[postUrlIndex] = e.target.value;

    // value.influencers[influencerId].handle[handlerId];
    // console.log("postArray", postArray);
    // console.log("HAnldeId", handleId);
    // console.log("VALUE----->", value?.influencers);

    value.influencers.map((infObj, infIndex) => {
      if (infObj.name === influencerName) {
        console.log("InfluencerName", influencerName);
        infObj.handle.map((handleObj, handleIndex) => {
          if (handleObj.platform === platform) {
            console.log("Name");
            handleObj.postUrl[postUrlIndex] = e.target.value;
          }
        });
      }
    });
  };

  return (
    <Card>
      <Card.Header>
        <h4>{campaignById.name}</h4>

        {!toShowInfluencer && (
          <Button onClick={() => history.push("/manageCampaign")}>
            Manage Campaign
          </Button>
        )}
        {toShowInfluencer && (
          <Button
            variant="danger tp-btn-light"
            onClick={() => onDeleteButtonClick()}
          >
            <i className="fa fa-trash font-18 align-middle me-2"></i> Delete
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        {!showEditCampaignForm && (
          <div>
            <DoubleValueInputField
              labelNameOne="Start Date"
              valueOne={moment(campaignById?.startDate).format("MMM D, YYYY")}
              labelNameTwo="End Date"
              valueTwo={moment(campaignById?.endDate).format("MMM D, YYYY")}
            />

            <DoubleValueInputField
              labelNameOne="Min Budget"
              valueOne={campaignById?.budget?.min}
              labelNameTwo="Max Budget"
              valueTwo={campaignById?.budget?.max}
            />

            <BadgeValue
              labelName="Goals"
              valueList={campaignById?.goals}
              tag={true}
            />
            <BadgeValue
              labelName="Audience Age"
              valueList={campaignById?.audienceDetails?.ageGroup}
              tag={true}
            />
            <BadgeValue
              labelName="Audience Gender"
              valueList={campaignById?.audienceDetails?.gender}
              tag={true}
            />
            <BadgeValue
              labelName="Interest"
              valueList={campaignById?.audienceDetails?.interest}
              tag={true}
            />

            <BadgeValue
              labelName="Platform"
              valueList={campaignById?.platform}
              tag={true}
            />

            <label>
              <h5>Influencer Details</h5>
            </label>
            <CampaignDetailsTable influencers={campaignById?.influencers} />
          </div>
        )}
        {showEditCampaignForm && (
          <CampaignUpdate
            campaignById={campaignById}
            setShowAddPost={setShowAddPost}
            showAddPost={showAddPost}
            updateObj={updateObj}
            setUpdateObj={setUpdateObj}
          />
        )}
      </Card.Body>
      {toShowInfluencer && showAddPost && (
        <Card.Footer>
          <>
            <Col>
              <CloseButton
                className="close-button"
                style={cancleStyle}
                onClick={() => setShowAddPost(false)}
              />
            </Col>
            <div style={{ marginTop: "1.5rem" }}>
              <DynamicInfluencerPostFields
                influArray={influencerArray}
                setInfluencerArray={setInfluencerArray}
              />
            </div>

            {updateObj?.influencers?.map((infObj, influencerId) => (
              <Row>
                <Col
                  xl={3}
                  lg={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                  }}
                >
                  {infObj.name}
                </Col>
                <div class="w-100 d-none d-md-block"></div>
                {infObj?.handle?.map((handleObj, handlerId) => (
                  <>
                    <Col xl={12} lg={12}>
                      <div className="mb-3 mt-2 ml-2 row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ fontSize: "12px" }}
                        >
                          Add Post Link:
                        </label>
                        <label className="col-sm-3 col-form-label">
                          <span style={{ fontWeight: "500", fontSize: "16px" }}>
                            {handleObj?.platform}
                          </span>
                        </label>

                        <div className="col-sm-6">
                          {handleObj?.postUrl?.map(
                            (postValue, postUrlIndex) => (
                              <Row className="mt-2">
                                <input
                                  type="text"
                                  className="form-control col"
                                  name="post"
                                  onChange={(e) =>
                                    handleOnChanges(
                                      e,
                                      influencerId,
                                      handlerId,
                                      postUrlIndex,
                                      handleObj,
                                      handleObj?.platform,
                                      infObj.name
                                    )
                                  }
                                />
                                <Col sm={3}>
                                  <div className="row">
                                    <Button
                                      variant="danger tp-btn-light"
                                      className="col me-2"
                                      disabled={
                                        handleObj?.postUrl?.length === 1
                                      }
                                      onClick={() =>
                                        handleRemoveFields(
                                          influencerId,
                                          handlerId,
                                          postUrlIndex
                                        )
                                      }
                                    >
                                      <span className="">
                                        <i className="fa fa-minus color-danger" />
                                      </span>
                                    </Button>
                                    <Button
                                      variant="danger tp-btn-light"
                                      className="col me-1"
                                      onClick={() =>
                                        handleAddInfluFields(
                                          influencerId,
                                          handlerId,
                                          postUrlIndex
                                        )
                                      }
                                    >
                                      <span className="">
                                        <i className="fa fa-plus" />
                                      </span>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            )
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                ))}
              </Row>
            ))}
          </>
          {showAddPost && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button>Update Campaign</Button>
            </div>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

export default CampaignSummary;
