import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Label,
} from "recharts";
import { useSelector } from "react-redux";
import {
  setOnInfluencerDetailsCLick,
  setEventMatrixError,
} from "../../../actions/Analyze/analyzeAction";

const DonutPieChart = (props) => {
  const {
    campaignById,
    isDeviceEventContainer,
    citiesArray,
    isCitiesContainer,
    countriesArray,
    isCountryContainer,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isGraphDataSet, setIsGraphDataSet] = useState(true);
  const [graphArray, setGraphArray] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const {
    onClickDonutGraph,
    selectedEventMatrixValue,
    influencerEventArray,
    deviceEventDetailsData,
  } = useSelector((state) => state.analyzeReducers);

  const { influencerArray } = useSelector((state) => state.dashboardReducers);

  useEffect(() => {}, [onClickDonutGraph]);

  const handleOnClick = (entry) => {
    if (isCitiesContainer || isCountryContainer) {
    } else if (isDeviceEventContainer) {
      if ("list" in entry) {
        const deviceSubArray = entry?.list?.map((obj) => {
          return {
            value: obj.eventCount,
            name: obj.devicePlatform,
          };
        });
        setGraphArray([...deviceSubArray]);
        setIsGraphDataSet(false);
        var count = 0;
        for (let i = 0; i < deviceSubArray?.length; i++) {
          count = count + deviceSubArray[i].value;
        }
        setTotalCount(count);
      } else {
        setGraphArray([...deviceEventDetailsData]);
        setIsGraphDataSet(false);
        count = 0;
        for (let i = 0; i < deviceEventDetailsData?.length; i++) {
          count = count + deviceEventDetailsData[i].value;
        }
        setTotalCount(count);
      }
    } else {
      if (selectedEventMatrixValue === null) {
        dispatch(setEventMatrixError("Please Select Event Name"));
        history.push("/influencers");
      } else {
        dispatch(setOnInfluencerDetailsCLick(entry.influencerDetails));
        history.push("/influencerProfile");
      }
    }
  };

  const randColor = () => {
    let color = "#";
    for (let i = 0; i < 3; i++)
      color += (
        "0" +
        Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)
      ).slice(-1);
    return color;
  };

  useEffect(() => {
    // function getUniqueListBy(arr, key) {
    //   return [...new Map(arr.map((item) => [item[key], item])).values()];
    // }

    if (isCitiesContainer) {
      setGraphArray([...citiesArray]);
      setIsGraphDataSet(false);
      var count = 0;
      for (let i = 0; i < citiesArray?.length; i++) {
        count = count + citiesArray[i].value;
      }
      setTotalCount(count);
    } else if (isCountryContainer) {
      setGraphArray([...countriesArray]);
      setIsGraphDataSet(false);
      count = 0;
      for (let i = 0; i < countriesArray?.length; i++) {
        count = count + countriesArray[i].value;
      }
      setTotalCount(count);
    } else if (isDeviceEventContainer) {
      setGraphArray([...deviceEventDetailsData]);
      setIsGraphDataSet(false);
      count = 0;
      for (let i = 0; i < deviceEventDetailsData?.length; i++) {
        count = count + deviceEventDetailsData[i].value;
      }
      setTotalCount(count);
    } else {
      const influenceDataArray = influencerEventArray.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.name === thing.name && t.eventName === selectedEventMatrixValue
          )
      );
      setGraphArray([...influenceDataArray]);
      setIsGraphDataSet(false);
      count = 0;
      for (let i = 0; i < influenceDataArray?.length; i++) {
        count = count + influenceDataArray[i].value;
      }
      setTotalCount(count);
    }
  }, [
    influencerArray,
    isCitiesContainer,
    citiesArray,
    countriesArray,
    isCountryContainer,
    selectedEventMatrixValue,
    influencerEventArray,
    deviceEventDetailsData,
    isDeviceEventContainer,
  ]);

  const renderLegend = (props) => {
    const { payload } = props;
    // let arrayData = payload;
    // if (isDeviceEventContainer) {
    //   console.log(
    //     "ARRAYA---->",
    //     arrayData?.map((data) => console.log(data))
    //   );
    // }
    // if (!isCitiesContainer) {
    //   function duplcicateArray(data, key) {
    //     return [...new Map(data.map((x) => [key(x), x])).values()];
    //   }
    //   arrayData = duplcicateArray(
    //     payload,
    //     (it) => it?.payload?.influencerDetails?.name
    //   );
    // }
    return (
      <ul>
        {payload?.map((entry, index) => (
          <>
            <li
              key={`item-${index}`}
              onClick={() => handleOnClick(entry.payload)}
            >
              <i className="fas fa-square" style={{ color: entry.color }}></i>{" "}
              {entry.value} -{"  "}
              {totalCount === 0 ? 0 : entry.payload.value}
            </li>
          </>
        ))}
      </ul>
    );
  };

  return (
    <div style={{ width: "100%", height: 400 }}>
      {!isGraphDataSet && graphArray?.length === 0 ? (
        <div className="not-data">No data available</div>
      ) : isGraphDataSet ? (
        <div class="text-center loader">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <ResponsiveContainer>
          <PieChart margin={{ top: 0, left: 70, right: 0, bottom: 0 }}>
            <Tooltip />
            <Legend
              verticalAlign="middle"
              layout="vertical"
              align="right"
              iconType="square"
              className="chart-legend"
              dataKey="value"
              content={renderLegend}
              onClick={(payload) => {
                handleOnClick(payload.payload);
              }}
            />
            <Pie
              data={graphArray}
              cx="20%"
              innerRadius="60%"
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              <Label
                width={70}
                position="center"
                styles={{ fontWeight: "300" }}
                fontSize="24px"
              >
                {`${totalCount}`}
              </Label>

              {graphArray?.map((entry, index) =>
                totalCount === 0 ? (
                  <Cell value={100} fill="white" stroke="lightgrey"></Cell>
                ) : (
                  <Cell
                    key={`cell-${index}`}
                    fill={totalCount === 0 ? "lightgrey" : randColor()}
                    onClick={() => {
                      handleOnClick(entry);
                    }}
                  />
                )
              )}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default DonutPieChart;
