import { REGISTERATION_REDUCER } from "../constants/actionConstants";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  isEmailIdPresent,
  generateOTPApi,
  verifyOTPApi,
  getCompanyListApi,
  companyRegistrationApi,
  userRegistrationApi,
  addUserCompanyApi,
  changeUserStatus,
  userUpdateData,
} from "../services/registerService";
import {
  setIfEmailExist,
  setSendOtp,
  setIsUserVerified,
  setCompanyList,
  setCreatedCompanyId,
  setSuccessCompanyCreation,
  setRegisterStatus,
  setSuccessRegisterUser,
  addUserCompanyRequestAction,
  setRegisterUserIdAction,
  setIsOtpInvalid,
} from "../actions/Register/registerAction";
import { logoutRequest } from "../actions/Login/logout";
import { resetDashboardDetails } from "../actions/Dashboard/dashboardAction";
import { resetLoginDetails } from "../actions/Login/loginAction";

export function* isEmailIdPresentSaga(action) {
  try {
    const data = yield call(isEmailIdPresent, action.email);
    const status = data?.data?.data?.registrationStatus;
    const isPresent = data?.data?.data?.isPresent;

    if (Object.keys(data?.data?.data).length !== 1 && isPresent) {
      if (
        (isPresent && status === "COMPANY_SELECTED_FOR_APPROVAL") ||
        (isPresent && status === "COMPANY_APPROVED") ||
        (isPresent && status === "COMPANY_REJECTED")
      ) {
        yield action.history.push("/");
      } else if (isPresent && status === "PASSWORD_CREATED") {
        console.log("isPresent && status----> /companySelection");
        yield action.history.push("/companySelection");
      } else {
        yield action.history.push("/userRegisterDetails");
      }
    } else if (!isPresent && status === "NOT_STARTED") {
      yield action.history.push("/userRegisterDetails");
    } else {
      yield action.history.push("/userRegisterDetails");
    }
    yield put(setIfEmailExist(data.data.data.isPresent));
    if (data?.data?.data?.registrationStatus) {
      yield put(setRegisterStatus(data?.data?.data?.registrationStatus));
    }
  } catch (error) {
    if (error?.response?.message === "Network Error")
      yield action.history.push("/page-error-500");
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* updateUserProfileSaga(action) {
  try {
    yield call(userUpdateData, action.data);
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 403 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* generateOtpSaga(action) {
  try {
    const data = yield call(generateOTPApi, action.data);
    yield put(setSendOtp(data.data.data.otp));
  } catch (error) {
    // if (error.message === "Network Error")
    //   yield action.history.push("/page-error-500");
  }
}

export function* verifyOtpSaga(action) {
  try {
    const data = yield call(verifyOTPApi, action.data);
    yield put(setIsUserVerified(data.data.data.emailVerified));
    yield put(setIsOtpInvalid(false));
    // yield put(setIsOtpSend(true));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
    if (error?.message === "Network Error")
      yield action.history.push("/page-error-500");
  }
}

export function* changeUserStatusSaga(action) {
  try {
    yield call(changeUserStatus, action.data);
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
    if (error.message === "Network Error")
      yield action.history.push("/page-error-500");
  }
}

export function* addUserCompanySaga(action) {
  try {
    yield call(addUserCompanyApi, action.data);
    yield put(setSuccessCompanyCreation(true));
  } catch (error) {
    yield put(setSuccessCompanyCreation(false));
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
    if (error.message === "Network Error")
      yield action.history.push("/page-error-500");
  }
}

export function* getCompanyListSaga(action) {
  try {
    const data = yield call(getCompanyListApi, action.data);
    yield put(setCompanyList(data.data.data));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
    if (error.message === "Network Error")
      yield action.history.push("/page-error-500");
  }
}

export function* companyRegisterSaga(action) {
  try {
    const data = yield call(companyRegistrationApi, action.data);
    yield put(setSuccessCompanyCreation(true));
    yield put(setCreatedCompanyId(data.data.data._id));
    action.userData.companyId = data.data.data._id;
    yield put(addUserCompanyRequestAction(action.userData));
  } catch (error) {
    yield put(setSuccessCompanyCreation(false));
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
    if (error?.message === "Network Error")
      yield action.history.push("/page-error-500");
  }
}

export function* userRegisterSaga(action) {
  try {
    const data = yield call(userRegistrationApi, action.data);
    yield put(setRegisterUserIdAction(data?.data?.data?._id));
    if (data) {
      yield put(setIsUserVerified(false));
      yield put(setSuccessRegisterUser(true));
    }
    yield action.history.push("/companySelection");
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
    if (error?.message === "Network Error")
      yield action.history.push("/page-error-500");
  }
}
export default function* registrationSaga() {
  yield takeLatest(
    REGISTERATION_REDUCER.IS_EMAIL_ID_PRESENT_REQUEST,
    isEmailIdPresentSaga
  );
  yield takeLatest(REGISTERATION_REDUCER.GENERATE_OTP_REQUEST, generateOtpSaga);
  yield takeLatest(REGISTERATION_REDUCER.VERIFY_OTP_REQUEST, verifyOtpSaga);
  yield takeLatest(
    REGISTERATION_REDUCER.GET_COMPANY_LIST_REQUEST,
    getCompanyListSaga
  );
  yield takeLatest(
    REGISTERATION_REDUCER.COMPANY_REGISTER_REQUEST,
    companyRegisterSaga
  );
  yield takeLatest(
    REGISTERATION_REDUCER.USER_REGISTER_REQUEST,
    userRegisterSaga
  );
  yield takeLatest(
    REGISTERATION_REDUCER.ADD_USER_COMPANY_REQUEST,
    addUserCompanySaga
  );
  yield takeLatest(
    REGISTERATION_REDUCER.CHANGE_USER_REGISTRATION_STATUS,
    changeUserStatusSaga
  );
  yield takeLatest(
    REGISTERATION_REDUCER.UPDATE_USER_PROFILE_REQUEST,
    updateUserProfileSaga
  );
}
