import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { logoutRequest } from "../../../actions/Login/logout";
import { callApi } from "../../../services/apiHelper";
import { resetLoginDetails } from "../../../actions/Login/loginAction";
import { resetDashboardDetails } from "../../../actions/Dashboard/dashboardAction";
import ReactSpinner from "../Comman/ReactSpinner";

const UserBehaviorCard = (props) => {
  const {
    queryArray,
    setQueryArray,
    index,
    setDisableFilter,
    selectedTab,
    setShowCountFilter,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [openKeyValue, setOpenKeyValue] = useState(true);
  const [selectedField, setSelectedField] = useState({});
  // const [queryStatus, setQueryStatus] = useState("Has Executed");
  // const [lableName, setLable] = useState("");
  const [labelList, setLabelList] = useState([]);
  // const [timeFrameList, setTimeFrameList] = useState([]);
  const [dataType, setDataType] = useState("");
  const [isValueSelected, setIsValueSelected] = useState(false);
  const [selectOperator, setSelectOperator] = useState("atleast");
  const [selectTimeFrame, setSelectTimeFrame] = useState("atleast");

  const resetState = () => {
    setSelectedField({});
    // setQueryStatus({});
    setLabelList([]);
    // setLable("");
    // setTimeFrameList([]);
    setDataType("");
    setIsValueSelected(false);
    setSelectOperator("");
    setSelectTimeFrame("");
    setDisableFilter(true);
    setShowCountFilter(true);
  };
  useEffect(() => {
    resetState();
  }, [selectedTab]);

  useEffect(() => {
    // console.log("Open key value in User Behaivoir ", openKeyValue);
  }, [openKeyValue]);
  useEffect(() => {
    setDataType(selectedField?.objectValue?.dbProperty?.type);
    if (isValueSelected) {
      const array = Object.keys(selectedField?.objectValue?.fieldOperation);
      setLabelList([...array]);

      const frameArray = Object.keys(selectedField?.objectValue?.timeFrame);
      // setTimeFrameList([...frameArray]);
    }
  }, [selectedField, isValueSelected]);

  const fetchBehaviourList = async () => {
    const getData = await callApi(
      `/segment/getFieldsList?fieldType=UserBehavior`,
      "GET",
      {},
      {},
      {}
    ).catch((error) => {
      if (error.response.status === 401 || error.response.status === 400) {
        dispatch(resetLoginDetails());
        dispatch(resetDashboardDetails());
        logoutRequest(history);
      }
    });
    return getData;
  };

  const { data } = useQuery("fetchBehaviourList", () => fetchBehaviourList());

  const userBehaviorList = data?.data?.data;
  const array = userBehaviorList?.map((data) => {
    return {
      value: data.dbProperty.attribute,
      label: data.label,
    };
  });

  const handleOnChange = (type, value) => {
    const values = [...queryArray];
    switch (type) {
      case "keyValue":
        {
          const emptyarray = [];
          setLabelList([...emptyarray]);
          // setLable(value);
          values[index].queryData.key = "eventName";
          values[index].queryData.value = value;
          values[index].queryData.type = "event";
          values[index].queryData.occurance = "atleast";
          const fieldObject = userBehaviorList.filter((obj) => {
            if (obj.dbProperty.attribute === value) return obj;
          });
          const objectValue = fieldObject[0];
          setSelectedField({ ...selectedField, objectValue });
          setIsValueSelected(true);
          setOpenKeyValue(false);
        }
        break;
      // case "queryStatus": {
      //   values[index].queryData.queryStatus = queryStatus;
      // }
      case "operationValue":
        {
          setSelectOperator(value);
          if (type !== "keyValue") {
            values[index].queryData.occurance =
              value === "exactly" ? "exact" : value;
          }
        }
        break;
      case "timeframe":
        {
          setSelectTimeFrame(value);
          // values[index].queryData.timeFrame = value;
          values[index].queryData.timeFrame = selectTimeFrame;
        }
        break;
      default:
        return "";
    }
    setQueryArray([...values]);
  };

  const handleOnChangeKeyValue = (e) => {
    const values = [...queryArray];
    const emptyarray = [];
    setLabelList([...emptyarray]);
    // setLable(e.value);
    values[index].queryData.key = "eventName";
    values[index].queryData.value = e.value;
    values[index].queryData.attributeValu = e.label;
    values[index].queryData.type = "event";
    values[index].queryData.occurance = "atleast";
    const fieldObject = userBehaviorList.filter((obj) => {
      if (obj.dbProperty.attribute === e.value) return obj;
    });
    const objectValue = fieldObject[0];
    setSelectedField({ ...selectedField, objectValue });
    setIsValueSelected(true);
    setQueryArray([...values]);
  };

  const handleOnOperator = (e) => {
    const values = [...queryArray];
    let type = e.target.name;
    values[index].queryData.valueType = dataType;
    if (type === "operationData") {
      values[index].queryData.occuranceValue = e.target.value;
      setDisableFilter(false);
      setShowCountFilter(false);
    }
    if (type === "operationDataOne") {
      values[index].queryData.occuranceOne = e.target.value;
    }
    if (type === "operationDataTwo") {
      values[index].queryData.occuranceTwo = e.target.value;
    }
    if (type === "timeFrameData") {
      values[index].queryData.timeFramevalue = e.target.value;
      setDisableFilter(false);
      setShowCountFilter(false);
    }
    if (type === "timeFrameDataOne") {
      values[index].queryData.timeFrameDataOne = e.target.value;
    }
    if (type === "timeFrameDataTwo") {
      values[index].queryData.timeFrameDataTwo = e.target.value;
      setDisableFilter(false);
      setShowCountFilter(false);
    }
    setQueryArray([...values]);
  };
  const ifInputString = (type, i, name) => {
    switch (type) {
      case "string":
        return (
          <input
            className="mb-1 form-control"
            type="text"
            name={
              selectOperator === "isBetween" || selectTimeFrame === "inBetween"
                ? name + i
                : name
            }
            // value={queryArray[index]?.queryData?.value}
            onChange={(e) => handleOnOperator(e)}
          />
        );
      case "number":
        return (
          <input
            className="mb-1 form-control"
            type="number"
            name={
              selectOperator === "isBetween" || selectTimeFrame === "inBetween"
                ? name + i
                : name
            }
            // value={
            //   selectOperator === "isBetween"
            //     ? queryArray[index]?.queryData[`value${i}`]
            //     : queryArray[index]?.queryData?.value
            // }
            onChange={(e) => handleOnOperator(e)}
          />
        );
      default:
        <div></div>;
    }
  };
  return (
    <>
      {userBehaviorList?.length !== 0 ? (
        <>
          <Row className="segement-card">
            <Col sm={3} style={{ zIndex: "1" }}>
              <Select
                //defaultMenuIsOpen
                name="keyValue"
                options={array}
                // menuIsOpen={openKeyValue}
                value={{
                  value:
                    queryArray[index]?.queryData?.value !== undefined
                      ? queryArray[index]?.queryData?.value
                      : "Select...",
                  label:
                    queryArray[index]?.queryData?.attributeValu !== undefined
                      ? queryArray[index]?.queryData?.attributeValu
                      : "Select...",
                }}
                className="singleSelectMenu"
                onChange={(e) => handleOnChangeKeyValue(e)}
              />

              {/* <select
                className="mb-1 select-form"
                id="inputState"
                defaultValue="option-1"
                value={queryArray[index]?.queryData?.value}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                name="keyValue"
              >
                {userBehaviorList?.map((data) => (
                  <option value={data.dbProperty.attribute}>
                    {data.label}
                  </option>
                ))}
              </select> */}
            </Col>
            <Col sm={3}>
              <select
                className="select-form"
                id="opeartionValue"
                name="operationValue"
                defaultValue="option-1"
                value={queryArray[index]?.queryData?.operator}
                // value={queryArray[index]?.queryData?.operationName}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                style={{ display: labelList.length !== 0 ? "" : "none" }}
              >
                {labelList?.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </Col>
            <Col sm={selectOperator === "isBetween" ? 2 : 3}>
              {ifInputString(dataType, "One", "operationData")}
            </Col>
            {selectOperator === "isBetween" && (
              <>
                <Col sm={1}>and</Col>
                <Col sm={2}>
                  {ifInputString(dataType, "Two", "operationData")}
                </Col>
              </>
            )}
          </Row>
          {/* <Row className="segement-card">
            <Col sm={2}>
              <select
                className="form-control"
                id="inputState"
                name="timeframe"
                defaultValue="option-1"
                // value={queryArray[index]?.queryData?.operationName}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                style={{ display: labelList.length !== 0 ? "" : "none" }}
              >
                <option value="choose">choose...</option>
                {timeFrameList?.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </Col>
            <Col sm={selectTimeFrame === "inBetween" ? 2 : 3}>
              {ifInputString(dataType, "One", "timeFrameData")}
            </Col>
            {selectTimeFrame === "inBetween" && (
              <>
                <Col sm={1}>and</Col>
                <Col sm={2}>
                  {" "}
                  {ifInputString(dataType, "Two", "timeFrameData")}
                </Col>
              </>
            )}
          </Row> */}
          <Row className="segement-card"></Row>
          <Row>
            {/* <Col sm={3}>
              <Button>+ Add Atrribute</Button>
            </Col> */}
          </Row>
        </>
      ) : (
        <ReactSpinner />
      )}
    </>
  );
};

export default UserBehaviorCard;
