import { callApi } from "./apiHelper";

export const createCampaign = (data) => {
  return callApi("/campaign/create", "POST", data);
};

export const updateCampaign = (data) => {
  return callApi("/campaign/update", "POST", data);
};

export const deleteCampaign = (data) => {
  return callApi("/campaign/delete", "POST", data);
};
