import { takeLatest, call, put, delay } from "redux-saga/effects";
import { SEGMENTATION_REDUCER } from "../constants/actionConstants";
import {
  getSegmentCount,
  fetchQueryRequestData,
  getSegmentByCampaign,
  getCsvS3Url,
  deleteUserQuery,
  getUserPropertyFieldList,
} from "../services/segmentationService";
import swal from "sweetalert";
import moment from "moment";
import { logoutRequest } from "../actions/Login/logout";
import {
  setQueryCount,
  setQueryCountRequestError,
  setIsQueryLoaded,
  setRecentSegmentData,
  setFetchDataArray,
  setIsFetchDataLoaded,
  setDownlaodUrlLink,
  setSegmentListByCampaign,
  getSegmentListByCampaignRequest,
  setUserPropertyList,
} from "../actions/Segmentation/segmentationAction";
import { resetLoginDetails } from "../actions/Login/loginAction";
import { resetDashboardDetails } from "../actions/Dashboard/dashboardAction";

export function* fetchQueryRequestSaga(action) {
  try {
    const data = yield call(fetchQueryRequestData, action.data);
    yield put(setIsQueryLoaded(false));
    yield put(setFetchDataArray(data?.data?.data?.Items));
    yield put(setIsFetchDataLoaded(false));
  } catch (error) {
    yield put(setQueryCountRequestError(true));
    yield put(setIsQueryLoaded(false));
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* getUserPropertyListSaga(action) {
  try {
    const data = yield call(getUserPropertyFieldList);
    yield put(setUserPropertyList(data?.data?.data));
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* deleteQuerySaga(action) {
  try {
    const data = yield call(deleteUserQuery, action.value);
    if (data?.data?.message === "Success") {
      swal("Segment is successfully deleted!", "", "success", {
        buttons: false,
      });
    }
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* getSegmentByCampaignSaga(action) {
  try {
    const data = yield call(getSegmentByCampaign, action.data);
    const reverseArray = data?.data?.data?.associatedSegments.reverse();
    const arrayData = reverseArray?.map((e) => {
      return {
        queryTime: moment().format("hh:mm A DD MMM YYYY"),
        source: "Segmentation",
        count: e?.resultMetaData?.count,
        segmentId: e._id,
        queryData: e.countlyMatchObject,
        downlaodUrl: e.s3CsvUrl,
        dataUrl: e.s3DataUrl,
        userName: e.user.name,
        isDownloadUrl: "s3CsvUrl" in e,
        // showData: action?.value?.showData,
        // button:
        //   action.value > 0 && state.showData === true
        //     ? "Show Data"
        //     : "Fetch Data",
      };
    });

    yield put(setSegmentListByCampaign(arrayData));
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      logoutRequest(action.history);
    }
  }
}

export function* downlaodDataUrlSaga(action) {
  try {
    const data = yield call(getCsvS3Url, action.data);
    if (Object.keys(data?.data?.data).length !== 0) {
      yield put(setDownlaodUrlLink(data?.data?.data?.csvUrl));
    } else {
      yield put(setDownlaodUrlLink(""));
    }
    // yield put(setIsQueryLoaded(false));
    // yield put(setFetchDataArray(data?.data?.data?.Items));
    // yield put(setIsFetchDataLoaded(false));
  } catch (error) {
    // yield put(setQueryCountRequestError(true));
    // yield put(setIsQueryLoaded(false));
    if (error.response.status === 401 || error.response.status === 403) {
      logoutRequest(action.history);
    }
  }
}

export function* getQueryResultRequest(action) {
  try {
    const data = yield call(getSegmentCount, action.data);
    let fetchQueryData = {};
    fetchQueryData.queryConditionsArray =
      data?.data?.data?.queryConditionsArray;
    fetchQueryData.userIds = data?.data?.data?.userIds;
    yield put(
      getSegmentListByCampaignRequest({ campaignId: action.data.campaignId })
    );
    const segmentIdData = { segmentId: data?.data?.data?.segmentId };
    const fetch = yield call(fetchQueryRequestData, segmentIdData);
    yield delay(3000);
    if (fetch?.data?.data?.showData) {
      const urlData = yield call(getCsvS3Url, segmentIdData);
      if (Object.keys(urlData?.data?.data).length !== 0) {
        yield put(setDownlaodUrlLink(urlData?.data?.data?.csvUrl));
      } else {
        yield put(setDownlaodUrlLink(""));
      }
    }

    yield put(setRecentSegmentData(fetchQueryData));
    yield put(setQueryCount(data?.data?.data));
    yield put(setIsQueryLoaded(false));
  } catch (error) {
    yield put(setQueryCountRequestError(true));
    yield put(setIsQueryLoaded(false));
    if (error.response.status === 401 || error.response.status === 403) {
      logoutRequest(action.history);
    }
  }
}

export default function* segmentationSaga() {
  yield takeLatest(
    SEGMENTATION_REDUCER.GET_QUERY_COUNT_REQUEST,
    getQueryResultRequest
  );
  yield takeLatest(
    SEGMENTATION_REDUCER.FETCH_QUERY_REQUEST,
    fetchQueryRequestSaga
  );
  yield takeLatest(
    SEGMENTATION_REDUCER.DOWNLOAD_FETCH_URL,
    downlaodDataUrlSaga
  );
  yield takeLatest(
    SEGMENTATION_REDUCER.GET_SEGMENT_LIST_BY_CAMPAIGN,
    getSegmentByCampaignSaga
  );
  yield takeLatest(
    SEGMENTATION_REDUCER.DELETE_SEGMENT_REQUEST,
    deleteQuerySaga
  );
  yield takeLatest(
    SEGMENTATION_REDUCER.USER_PROPERTY_LIST_REQUEST,
    getUserPropertyListSaga
  );
}
