import React from "react";
import Select from "react-select";

const MultiSelectOption = (props) => {
  const { optionList, onChange, onFocus, className, value, disabled } = props;

  const ClearIndicator = (props) => {
    const {
      children = "clear all",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: "pointer",
    color: state.isFocused ? "blue" : "black",
    backgroundColor: state.isFocused
      ? "#f5f2f2"
      : state.isSelected
      ? "#f9e3cb"
      : null,
  });

  return (
    <Select
      className={className}
      closeMenuOnSelect={false}
      components={{ ClearIndicator }}
      styles={{ clearIndicator: ClearIndicatorStyles }}
      //   defaultValue={[colourOptions[4], colourOptions[5]]}
      isMulti
      value={value}
      options={optionList}
      onChange={onChange}
      onFocus={onFocus}
      isDisabled={disabled}
    />
  );
};

export default MultiSelectOption;
