import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { setDashboardDateRange } from "../../../actions/Dashboard/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import {
  setCitiesDateRange,
  setCountryDateRange,
  setInfluencerDateRange,
  setInfluencerUserDateRange,
  setInfluencerUserLabelRange,
  setDeviceEventDateLabel,
  setDeviceEventDateRange,
} from "../../../actions/Analyze/analyzeAction";
import {
  setSelectDateRange,
  setCitiesSelectLabelRange,
  setCountriesLabelRange,
  setInfluencerDateLabelRange,
} from "../../../actions/CommonAction/commonAction";
import { useHistory } from "react-router-dom";

const CustomDateRangePicker = (props) => {
  const dispatch = useDispatch();
  const {
    fontWidth,
    isCitiesContainer,
    isCountryContainer,
    isInfluencerContainer,
    isInfluencerDetails,
    isDeviceEventContainer,
  } = props;
  const {
    selectDateRange,
    citiesDateLabel,
    countriesDateLabel,
    influencerDateLabel,
  } = useSelector((state) => state.commonReducer);
  const {
    influencerUserDateRange,
    influencerUserDateLabel,
    deviceEventDateLabel,
  } = useSelector((state) => state.analyzeReducers);
  const [state, setState] = useState({
    start: moment().subtract(29, "days").toDate(),
    end: moment().toDate(),
  });
  const [label, setLabel] = useState("Last 7 Days");
  const handleCallback = (start, end, label) => {
    if (isCitiesContainer) {
      const dateRange = {
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      };
      dispatch(setCitiesDateRange(dateRange));
      dispatch(setCitiesSelectLabelRange(label));
      if (label === "Custom Range") {
        dispatch(
          setCitiesSelectLabelRange(
            `${moment(start).format("MMM D, YY")}  To  ${moment(end).format(
              "MMM D, YY"
            )}`
          )
        );
      }
      setState({ start, end });
    } else if (isCountryContainer) {
      const dateRange = {
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      };
      dispatch(setCountryDateRange(dateRange));
      dispatch(setCountriesLabelRange(label));
      if (label === "Custom Range") {
        dispatch(
          setCountriesLabelRange(
            `${moment(start).format("MMM D, YY")}  To  ${moment(end).format(
              "MMM D, YY"
            )}`
          )
        );
      }
      setState({ start, end });
    } else if (isDeviceEventContainer) {
      const dateRange = {
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      };
      dispatch(setDeviceEventDateRange(dateRange));
      dispatch(setDeviceEventDateLabel(label));
      if (label === "Custom Range") {
        dispatch(
          setCountriesLabelRange(
            `${moment(start).format("MMM D, YY")}  To  ${moment(end).format(
              "MMM D, YY"
            )}`
          )
        );
      }
      setState({ start, end });
    } else {
      if (isInfluencerContainer) {
        const dateRange = {
          startDate: moment(start).format("YYYY-MM-DD"),
          endDate: moment(end).format("YYYY-MM-DD"),
        };
        dispatch(setInfluencerDateRange(dateRange));
        dispatch(setInfluencerDateLabelRange(label));

        if (label === "Custom Range") {
          dispatch(
            setInfluencerDateLabelRange(
              `${moment(start).format("MMM D, YY")}  To  ${moment(end).format(
                "MMM D, YY"
              )}`
            )
          );
        }
        setState({ start, end });
      } else if (isInfluencerDetails) {
        const dateRange = {
          startDate: moment(start).format("YYYY-MM-DD"),
          endDate: moment(end).format("YYYY-MM-DD"),
        };
        dispatch(setInfluencerUserDateRange(dateRange));
        dispatch(setInfluencerUserLabelRange(label));
        // dispatch(getTotalEventCountMatrixRequest(data, history));
        if (label === "Custom Range") {
          dispatch(
            setSelectDateRange(
              `${moment(start).format("MMM D, YY")}  To  ${moment(end).format(
                "MMM D, YY"
              )}`
            )
          );
        }
        setState({ start, end });
      } else {
        const dateRange = {
          startDate: moment(start).format("YYYY-MM-DD"),
          endDate: moment(end).format("YYYY-MM-DD"),
        };
        dispatch(setDashboardDateRange(dateRange));
        dispatch(setSelectDateRange(label));
        // dispatch(getTotalEventCountMatrixRequest(data, history));
        if (label === "Custom Range") {
          dispatch(
            setSelectDateRange(
              `${moment(start).format("MMM D, YY")}  To  ${moment(end).format(
                "MMM D, YY"
              )}`
            )
          );
        }
        setState({ start, end });
      }
    }
  };

  useEffect(() => {
    console.log();
  }, [
    isCitiesContainer,
    isCountryContainer,
    selectDateRange,
    citiesDateLabel,
    countriesDateLabel,
    influencerUserDateLabel,
    influencerDateLabel,
  ]);

  return (
    <DateRangePicker
      className="datePicker"
      initialSettings={{
        startDate: state.start,
        endDate: state.end,
        ranges: {
          "Last 7 Days": [
            moment().subtract(6, "days").toDate(),
            moment().toDate(),
          ],
          "Last 14 Days": [
            moment().subtract(13, "days").toDate(),
            moment().toDate(),
          ],
          "Last 30 Days": [
            moment().subtract(29, "days").toDate(),
            moment().toDate(),
          ],
          "Last 60 Days": [
            moment().subtract(59, "days").toDate(),
            moment().toDate(),
          ],
          "Last 90 Days": [
            moment().subtract(89, "days").toDate(),
            moment().toDate(),
          ],
        },
      }}
      onCallback={handleCallback}
      value={label}
    >
      <Col
        id="reportrange"
        className="date-range mt-1 mb-2s"
        style={{
          border: "1px solid #f5f5f5",
          marginBottom: "1rem",
          fontSize: fontWidth,
          marginLeft: "12px",
          marginRight: "12px",
        }}
      >
        <div>
          <i className="mr-2 fa fa-calendar"></i>

          <span style={{ fontSize: "16px" }}>
            {isInfluencerContainer
              ? influencerDateLabel
              : isInfluencerDetails
              ? influencerUserDateLabel
              : isCitiesContainer
              ? citiesDateLabel
              : isCountryContainer
              ? countriesDateLabel
              : isDeviceEventContainer
              ? deviceEventDateLabel
              : selectDateRange}
          </span>
        </div>
        <div style={{}}>
          <i className="fa fa-caret-down"></i>
        </div>
      </Col>
    </DateRangePicker>
  );
};

export default CustomDateRangePicker;
