import React, { useEffect, useState } from "react";
import MultiSelectOption from "../Comman/MultiSelectOption";
import ReactTagInput from "@pathofdev/react-tag-input";
import { Button, Row } from "react-bootstrap";
import moment from "moment";
import swal from "sweetalert";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateCampaignRequest } from "../../../actions/Campaign/addCampaignAction";

const CampaignUpdate = (props) => {
  const { campaignById, showAddPost, updateObj, setUpdateObj } = props;

  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [startDataValue, setStartDateValue] = useState(
    moment(updateObj?.startDate).format("YYYY-MM-DD")
  );
  const [endDateValue, setEndDateValue] = useState(
    moment(updateObj?.endDate).format("YYYY-MM-DD")
  );

  const disableStyle = {
    background: "hsl(0,0%,95%)",
  };

  useEffect(() => {
    setUpdateObj({ ...updateObj });
  }, [campaignById]);

  const options = [
    { value: "Generate Qualified Leads", label: "Generate Qualified Leads" },
    { value: "Boost Traffic", label: "Boost Traffic" },
    { value: "Drive sign ups", label: "Drive sign ups" },
    { value: "Increase Revenue", label: "Increase Revenue" },
    { value: "Drive sales", label: "Drive sales" },
    { value: "Enhance video views", label: "Enhance video views" },
  ];
  const ageOptionList = [
    { value: "13-17", label: "13-17" },
    { value: "18-24", label: "18-24" },
    { value: "25-34", label: "25-34" },
    { value: "35-44", label: "35-44" },
    { value: "45-64", label: "45-64" },
    { value: "65-74", label: "65-74" },
    { value: "75-84", label: "75-84" },
  ];
  const genderOptionList = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  const intrestOptionList = [
    { value: "clothes", label: "Clothes" },
    { value: "healthylifeStyle", label: "Healthy Lifestyle" },
    { value: "pets", label: "Pets" },
    { value: "activewear", label: "Activewear" },
    { value: "photography", label: "Photography" },
    { value: "foodGrocery", label: "Food & Grocery" },
    { value: "wedding", label: "Wedding" },
    { value: "gaming", label: "Gaming" },
    { value: "friends", label: "Friends" },
    { value: "music", label: "Music" },
  ];

  const validate = (values) => {
    let errors = {};

    const urlValidation = (value) => {
      if (
        value.match(
          /(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        ) === null
      )
        return true;
      else return false;
    };

    if (!values.minBudget) {
      errors.minBudget = "Please Enter Minimun Buget";
    }
    if (!values.maxBudget) {
      errors.maxBudget = "Please Enter Maximun Buget";
    }
    if (values.minBuget > values.maxBuget) {
      errors.minBudget = "Min Budget should be less then Max budget";
      errors.maxBudget = "Max Budget should be greater then Min budget";
    }
    if (!values.startDate || !values.endDate) {
      errors.date = "Please set the Date Range";
    }
    if (!values.goals || values.goals.length === 0) {
      errors.goals = "Please Select at least one Goal";
    }
    if (!values.ageGroup || values.ageGroup.length === 0) {
      errors.ageGroup = "Please Select at least one Age group";
    }
    if (!values.interest || values.interest.length === 0) {
      errors.interest = "Please Select at least one interest";
    }
    if (!values.gender || values.gender.length === 0) {
      errors.gender = "Please Select at least one Gender group";
    }
    if (!values.platform || values.platform.length === 0) {
      errors.interest = "Please at least add one platform";
    }

    return errors;
  };

  const handleCallback = (start, end, label) => {
    setStartDateValue(moment(start).format("YYYY-MM-DD"));
    setEndDateValue(moment(end).format("YYYY-MM-DD"));
  };

  useState(() => {
    if (isSubmitClick) {
      setFormErrors(validate(updateObj));
    }
  }, [updateObj, isSubmitClick]);

  const handleOnSubmit = () => {
    setIsSubmitClick(true);
    if (moment().diff(moment(updateObj?.endDate)) > 0) {
      swal(
        "Sorry!",
        `Cannot update ${campaignById.name} because campaign is already ended.`,
        "error",
        {
          buttons: false,
        }
      );
    } else {
      setFormErrors(validate(updateObj));
      if (Object.keys(validate(updateObj)).length === 0) {
        const ageGroup = updateObj?.ageGroup.map((data) => {
          return data.value;
        });

        const goals = updateObj?.goals.map((data) => {
          return data.value;
        });
        const gender = updateObj?.gender.map((data) => {
          return data.value;
        });

        const interest = updateObj?.interest.map((data) => {
          return data.value;
        });

        const data = {
          startDate: moment(updateObj.startDate).format("YYYY-MM-DD"),
          endDate: moment(updateObj.endDate).format("YYYY-MM-DD"),
          budget: {
            min: updateObj.minBudget,
            max: updateObj.maxBudget,
          },
          platform: updateObj.platform,
          ageGroup,
          goals,
          gender,
          interest,
        };
        data.id = campaignById._id;

        if (moment().diff(moment(data.startDate)) > 0) {
          delete data.startDate;
          delete data.budget;
          delete data.goals;
          delete data.gender;
          delete data.ageGroup;
        }
        if (moment().diff(moment(data.startDate)) < 0) {
          delete data.endDate;
        }
        setIsSubmitClick(false);
        dispatch(updateCampaignRequest(data, history));
      }
    }
  };

  return (
    <>
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Start Date - End Date</label>
        <div className="col-sm-9">
          <DateRangePicker
            open={false}
            initialSettings={{
              startDate: moment().toDate(),
              endDate: moment().toDate(),
            }}
            onCallback={handleCallback}
          >
            <div class="range-picker-input">
              <span class="range-picker-input__start">
                <div class="picker-input range">
                  <input
                    class="picker-input__text"
                    type="text"
                    placeholder="Start Date"
                    disabled={moment().diff(moment(updateObj?.startDate)) > 0}
                    readOnly
                    value={moment(updateObj?.startDate).format("YYYY-MM-DD")}
                    style={{ paddingLeft: "20px", color: "#6e6e6e" }}
                  />
                </div>
              </span>
              <span class="range-picker-input__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="icon-right-arrow"
                  id="right-arrow"
                  size="16"
                  color="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                </svg>
              </span>
              <span class="range-picker-input__end">
                <div class="picker-input range">
                  <input
                    class="picker-input__text"
                    type="text"
                    disabled={moment().diff(moment(updateObj?.endDate)) > 0}
                    value={moment(updateObj?.endDate).format("YYYY-MM-DD")}
                    placeholder="End Date"
                    style={{ paddingLeft: "20px", color: "#6e6e6e" }}
                  />
                </div>
              </span>
            </div>
          </DateRangePicker>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Min Budget</label>
        <div className="col-sm-3">
          <div className={formErrors?.minBudget && "error-form-control"}>
            <input
              type="text"
              className="form-control"
              value={updateObj?.minBudget}
              disabled={moment().diff(moment(updateObj?.startDate)) > 0}
              onChange={(e) =>
                setUpdateObj({ ...updateObj, minBudget: e.target.value })
              }
              style={disableStyle}
            />
          </div>
          {formErrors?.minBudget && (
            <div className="text-danger fs-12">{formErrors?.minBudget}</div>
          )}
        </div>

        <label className="col-sm-3 col-form-label">Max Budget</label>
        <div className="col-sm-3">
          <div className={formErrors?.maxBudget && "error-form-control"}>
            <input
              type="text"
              className="form-control"
              value={updateObj?.maxBudget}
              disabled={moment().diff(moment(updateObj?.startDate)) > 0}
              onChange={(e) =>
                setUpdateObj({ ...updateObj, maxBudget: e.target.value })
              }
              style={disableStyle}
            />
          </div>
          {formErrors?.maxBudget && (
            <div className="text-danger fs-12">{formErrors?.maxBudget}</div>
          )}
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Goal</label>
        <div className="col-sm-9">
          <div className={formErrors?.goals && "error-form-control"}>
            <MultiSelectOption
              className="multi-select"
              optionList={options}
              value={updateObj?.goals}
              disabled={moment().diff(moment(updateObj?.startDate)) > 0}
              onChange={(value) => setUpdateObj({ ...updateObj, goals: value })}
            />
          </div>
          {formErrors?.goals && (
            <div className="text-danger fs-12">{formErrors?.goals}</div>
          )}
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Audience Age</label>
        <div className="col-sm-9">
          <div className={formErrors?.ageGroup && "error-form-control"}>
            <MultiSelectOption
              className="multi-select"
              optionList={ageOptionList}
              value={updateObj?.ageGroup}
              disabled={moment().diff(moment(updateObj?.startDate)) > 0}
              onChange={(value) =>
                setUpdateObj({ ...updateObj, ageGroup: value })
              }
            />
          </div>
          {formErrors?.ageGroup && (
            <div className="text-danger fs-12">{formErrors?.ageGroup}</div>
          )}
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Audience Gender</label>
        <div className="col-sm-9">
          <div className={formErrors?.gender && "error-form-control"}>
            <MultiSelectOption
              className="multi-select"
              optionList={genderOptionList}
              value={updateObj?.gender}
              disabled={moment().diff(moment(updateObj?.startDate)) > 0}
              onChange={(value) =>
                setUpdateObj({ ...updateObj, gender: value })
              }
            />
          </div>
          {formErrors?.gender && (
            <div className="text-danger fs-12">{formErrors?.gender}</div>
          )}
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Interest</label>
        <div className="col-sm-9">
          <div className={formErrors?.interest && "error-form-control"}>
            <MultiSelectOption
              className="multi-select"
              optionList={intrestOptionList}
              value={updateObj?.interest}
              onChange={(value) =>
                setUpdateObj({ ...updateObj, interest: value })
              }
            />
          </div>
          {formErrors?.interest && (
            <div className="text-danger fs-12">{formErrors?.interest}</div>
          )}
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">PlatForm</label>
        <div className="col-sm-9">
          <div className={formErrors?.platform && "error-form-control"}>
            <ReactTagInput tags={updateObj?.platform} />
          </div>
          {formErrors?.platform && (
            <div className="text-danger fs-12">{formErrors?.platform}</div>
          )}
        </div>
      </div>

      <Row>
        {/* <Col>
          <Button
            className="btn btn-sm btn-primary"
            onClick={() => setShowAddPost(true)}
            // disabled={moment().diff(moment(updateObj?.startDate)) > 0}
          >
            Add Influencer Post URL
          </Button>
        </Col> */}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          {!showAddPost && (
            <Button onClick={() => handleOnSubmit()}>Update Campaign</Button>
          )}
        </div>
      </Row>
    </>
  );
};
export default CampaignUpdate;
