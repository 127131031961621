import { DASHBOARD_REDUCER } from "../constants/actionConstants";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  getCampaignList,
  getRecentCampaign,
  getCampaignById,
  getEventMatrixInfluencerData,
  getCampaignEventMatrix,
} from "../services/dashboardService";
import {
  setCampaignList,
  setSelectedCampaign,
  setOnSuccessCampaignList,
  setOnSuccessSelectCampaign,
  setCampaignByIdData,
  setEventMatrixList,
  getCampaignByIdRequest,
  setInfluencerArray,
  setTotalEventMatrixCount,
  setCampaignEventMatrix,
  resetDashboardDetails,
} from "../actions/Dashboard/dashboardAction";
import { resetLoginDetails } from "../actions/Login/loginAction";
import { setDefaultEventMatrix } from "../actions/Analyze/analyzeAction";
import { logoutRequest } from "../actions/Login/logout";

export function* getCampaignListSaga(action) {
  try {
    const data = yield call(getCampaignList);
    yield put(setCampaignList(data?.data?.data));
    yield put(setOnSuccessCampaignList(true));
  } catch (error) {
    console.log(error?.response?.status);
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* getCampaignEventMatrixSaga(action) {
  try {
    const data = yield call(getCampaignEventMatrix, action.data);
    let count = 0;

    yield put(setCampaignEventMatrix(data?.data?.data));
    data?.data?.data?.forEach((element) => {
      count = count + element.count;
    });
    // data?.data?.data.map((obj) => {
    //   count = count + obj.count;
    // });
    yield put(setTotalEventMatrixCount(count));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* getEventMatrixInfluencerSaga(action) {
  try {
    const data = yield call(getEventMatrixInfluencerData, action.data);
    yield put(setInfluencerArray(data?.data?.data, action?.data?.eventName));

    // yield put(setEventCountArray(data?.data?.data, action?.data?.eventName));
  } catch (error) {
    if (
      (error?.response?.status === 401) | (error?.response?.status === 400) ||
      error?.response?.data?.message === "Token is invalid"
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* getRecentCampaignSaga(action) {
  try {
    const data = yield call(getRecentCampaign);
    yield put(setSelectedCampaign(data.data.data.mostRecentCampaign));
    yield put(
      getCampaignByIdRequest(
        data?.data?.data?.mostRecentCampaign._id,
        action.history
      )
    );
    yield put(setOnSuccessSelectCampaign(true));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* getCampaignByIdSaga(action) {
  try {
    const data = yield call(getCampaignById, action.campaignId);
    yield put(setCampaignByIdData(data.data.data.campaign));
    const eventMatrix = data?.data?.data?.campaign?.eventsToTrack;
    yield put(setEventMatrixList(eventMatrix));
    yield put(
      setDefaultEventMatrix({
        value: eventMatrix[0],
        label: eventMatrix[0],
      })
    );
    // yield put(setSelectedCampaign(data.data.data.mostRecentCampaign));
    yield put(setOnSuccessSelectCampaign(true));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.status === 403 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export default function* dashboardSaga() {
  yield takeLatest(
    DASHBOARD_REDUCER.CAMPAIGN_LIST_REQUEST,
    getCampaignListSaga
  );
  yield takeLatest(
    DASHBOARD_REDUCER.RECENT_CAMPAIGN_REQUEST,
    getRecentCampaignSaga
  );
  yield takeLatest(DASHBOARD_REDUCER.GET_CAMPAIGN_BY_ID, getCampaignByIdSaga);
  yield takeLatest(
    DASHBOARD_REDUCER.GET_EVENT_MATRIX_INFLUENCER_DATA,
    getEventMatrixInfluencerSaga
  );
  yield takeLatest(
    DASHBOARD_REDUCER.CAMPAIGN_EVENT_MATRIX_REQUEST,
    getCampaignEventMatrixSaga
  );
}
