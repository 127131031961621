import { applyMiddleware, compose, createStore } from "redux";

import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers/rootReducers";
import rootSaga from "../sagas/rootSaga";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "commonReducer",
    "analyzeReducers",
    "addCampaignReducers",
    "registerReducer",
    "segmentationReducer",
  ],
};
const pReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(sagaMiddleware);
export const store = createStore(pReducer, composeEnhancers(middleware));
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
