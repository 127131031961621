import React from "react";
import { Nav, Card, Tab, Row, Col, CloseButton } from "react-bootstrap";
import UserPropertiesCard from "./UserPropertiesCard";
import UserBehaviorCard from "./UserBehaviorCard";

const QueryCard = (props) => {
  const {
    queryArray,
    setQueryArray,
    index,
    setDisableFilter,
    setShowCountFilter,
    setSelectedTab,
    selectedTab,
    userPropertyFieldList,
  } = props;

  const style = {
    marginBottom: "0px",
    border: "1px solid #ededed",
  };

  const cancleStyle = {
    fontSize: "24px",
  };
  const handleOnTabSelect = (e) => {
    const values = [...queryArray];
    values[index] = {
      queryNumber: index + 1,
      type: e,
      queryData: {},
      opeartion: "",
    };
    setQueryArray([...values]);
    setSelectedTab(e);
  };
  const removeQueryCard = (index) => {
    let values;
    if (queryArray?.length === 1) {
      values = [
        {
          queryNumber: 1,
          propertyName: "UserProperty",
          queryData: {},
          opeartion: "",
        },
      ];
      setQueryArray([...values]);
      setDisableFilter(false);
      setShowCountFilter(false);
    } else {
      values = [...queryArray];
      values.splice(index, 1);
      setQueryArray([...values]);
      setDisableFilter(false);
      setShowCountFilter(false);
    }
  };
  return (
    <>
      <Tab.Container
        defaultActiveKey="UserProperty"
        onSelect={(e) => handleOnTabSelect(e)}
      >
        <Card
          width="100%"
          style={{ marginBottom: "0px", border: "1px solid #ededed" }}
        >
          <Row>
            <Col sm={12} lg={12}>
              <Card.Body>
                <Col>
                  <CloseButton
                    className="close-button"
                    style={cancleStyle}
                    onClick={() => removeQueryCard(index)}
                  />
                </Col>
                <Nav as="div" variant="pills" className="flex-row ms-2 ">
                  <Nav.Item as="a" style={style}>
                    <Nav.Link eventKey="UserProperty">User Property</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="a" style={style}>
                    <Nav.Link eventKey="UserBehavior">User Behavior</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item as="a" style={style}>
                    <Nav.Link eventKey="CustomSegment">Custom segment</Nav.Link>
                  </Nav.Item> */}
                </Nav>

                <Tab.Content className="pt-4">
                  <Tab.Pane eventKey="UserProperty">
                    <UserPropertiesCard
                      queryArray={queryArray}
                      setQueryArray={setQueryArray}
                      index={index}
                      setDisableFilter={setDisableFilter}
                      setShowCountFilter={setShowCountFilter}
                      selectedTab={selectedTab}
                      userPropertyFieldList={userPropertyFieldList}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="UserBehavior">
                    <UserBehaviorCard
                      setDisableFilter={setDisableFilter}
                      setShowCountFilter={setShowCountFilter}
                      setQueryArray={setQueryArray}
                      index={index}
                      queryArray={queryArray}
                      selectedTab={selectedTab}
                    />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="CustomSegment">
                    <CustomSegmentCard setDisableFilter={setDisableFilter} />
                  </Tab.Pane> */}
                </Tab.Content>
              </Card.Body>
            </Col>

            {/* <Col sm={1} lg={1}>
              <Button
                variant=""
                style={cancleStyle}
                className="btn-close"
                onClick={(e) => removeQueryCard(index)}
              ></Button>
            </Col> */}
          </Row>
        </Card>
      </Tab.Container>
    </>
  );
};

export default QueryCard;
