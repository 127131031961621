import { takeLatest, call, put } from "redux-saga/effects";
import { ANALYZE_REDUCER } from "../constants/actionConstants";
import {
  getInfluencerCities,
  getInfluencerEventMatrix,
  getAllInfluencerEventMatrix,
  getEventDetailsByDevicePlatform,
} from "../services/analyzeService";
import {
  setCitiesData,
  setCountriesData,
  setInfluencerEventData,
  setAllInfluencerEventData,
  setTotalInflueEventMatrix,
  setDeviceEventDetailsData,
  setTotalDeviceEventDetailsCount,
} from "../actions/Analyze/analyzeAction";
import { resetDashboardDetails } from "../actions/Dashboard/dashboardAction";
import { resetLoginDetails } from "../actions/Login/loginAction";
import { logoutRequest } from "../actions/Login/logout";

export function* getInfluencerCitiesSaga(action) {
  try {
    const data = yield call(getInfluencerCities, action.value);
    const citiesArray = data?.data?.data.map((obj) => {
      return { value: obj.count, name: obj.city };
    });
    yield put(setCitiesData(citiesArray));
  } catch (error) {
    // if (error?.response?.status === 401 || error?.response?.status === 400) {
    //   logoutRequest(action.history);
    // }
  }
}

export function* getInfluencerCountriesSaga(action) {
  try {
    const data = yield call(getInfluencerCities, action.value);
    const countriesArray = data?.data?.data.map((obj) => {
      return { value: obj.count, name: obj.country };
    });
    yield put(setCountriesData(countriesArray));
  } catch (error) {
    // if (error?.response?.status === 401 || error?.response?.status === 400) {
    //   logoutRequest(action.history);
    // }
  }
}

export function* getInfluencerEventSaga(action) {
  try {
    const data = yield call(getInfluencerEventMatrix, action.value);

    yield put(setInfluencerEventData(data?.data?.data, action?.eventName));
  } catch (error) {}
}

export function* getAllInfluencerEventSaga(action) {
  try {
    const data = yield call(getAllInfluencerEventMatrix, action.value);
    let count = 0;
    yield put(setAllInfluencerEventData(data?.data?.data));
    data?.data?.data.forEach((element) => {
      count = count + element.count;
    });
    yield put(setTotalInflueEventMatrix(count));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* getDeviceEventDetailsSaga(action) {
  try {
    const data = yield call(getEventDetailsByDevicePlatform, action.value);
    const deviceArray = data?.data?.data.map((obj) => {
      return {
        value: obj.eventCount,
        name: obj.devicePlatform,
        list: obj.list,
      };
    });
    yield put(setDeviceEventDetailsData(deviceArray));
    let count = 0;
    data?.data?.data.forEach((element) => {
      count = count + element.count;
    });
    yield put(setTotalDeviceEventDetailsCount(count));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export default function* analyzeSaga() {
  yield takeLatest(
    ANALYZE_REDUCER.GET_CITIES_DATE_REQUEST,
    getInfluencerCitiesSaga
  );
  yield takeLatest(
    ANALYZE_REDUCER.GET_COUNTRIES_DATE_REQUEST,
    getInfluencerCountriesSaga
  );
  yield takeLatest(
    ANALYZE_REDUCER.GET_INFLU_EVENT_MATRIX_REQUEST,
    getInfluencerEventSaga
  );
  yield takeLatest(
    ANALYZE_REDUCER.GET_ALL_INFLUENCER_EVENT_REQUEST,
    getAllInfluencerEventSaga
  );
  yield takeLatest(
    ANALYZE_REDUCER.GET_DEVICE_EVENT_DETAILS_REQUEST,
    getDeviceEventDetailsSaga
  );
}
