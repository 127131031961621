import { ADMIN_USER_REDUCER } from "../constants/actionConstants";

export const initialState = {
  userRequestedList: [],
  userDetails: {},
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_USER_REDUCER.SET_REQUEST_USER_LIST:
      return { ...state, userRequestedList: action.value };
    case ADMIN_USER_REDUCER.SET_USER_PROFILE_DETAILS:
      return { ...state, userDetails: action.value };
    default:
      return state;
  }
};
