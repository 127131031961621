import { loginReducer } from "../reducers/loginReducers";
import { addCampaignReducers } from "../reducers/addCampaignReducers";
import { dashboardReducers } from "./dashboardReducers";
import { analyzeReducers } from "./analyzeReducers";
import { combineReducers } from "redux";
import { registerReducer } from "./registerReducer";
import { adminReducer } from "./adminSettingReducer";
import { segmentationReducer } from "./segmentationReducer";
import { commonReducer } from "./commonActionReducer";

const rootReducer = combineReducers({
  loginReducer,
  addCampaignReducers,
  dashboardReducers,
  analyzeReducers,
  registerReducer,
  adminReducer,
  segmentationReducer,
  commonReducer,
});

export default rootReducer;
