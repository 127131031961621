import React from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../Comman/ButtonComponent";
import { setIsUpdateCampaignClick } from "../../../actions/Campaign/addCampaignAction";

const Campaigns = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const buttonArray = [
    {
      buttonName: "Add Campaign",
      buttonLink: "/addCampaign",
    },
  ];

  const handleOnClick = (link) => {
    if (link === "/addCampaign") {
      dispatch(setIsUpdateCampaignClick(false));
    }
    history.push(link);
  };
  return (
    <>
      <Row className="d-flex justify-content-end">
        {buttonArray?.map((data, i) => (
          <Col xl="2" sm="3">
            <ButtonComponent
              key={i}
              buttonName={data.buttonName}
              buttonLink={() => handleOnClick(data.buttonLink)}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Campaigns;
