import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSpinner from "../Comman/ReactSpinner";
import { ThemeContext } from "../../../context/ThemeContext";
import EventCardList from "./EventCardList";
import CampaignSummary from "../Manage/CampaignSummary";
import { setIsCampaignSummary } from "../../../actions/Dashboard/dashboardAction";
import { setEditCampaignForm } from "../../../actions/Campaign/addCampaignAction";

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [isEventListDisplay, setIsEventListDispaly] = useState(false);

  const {
    campaignList,
    eventMatrixList,
    campaignById,
    successCampaignRequest,
    campaignListPermission,
    isCampaignSummary,
    dateRange,
    totalCampaignEventCount,
  } = useSelector((state) => state.dashboardReducers);

  const dispatch = useDispatch();

  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
    dispatch(setIsCampaignSummary(true));
    dispatch(setEditCampaignForm(false));
  }, [campaignById, totalCampaignEventCount]);

  useEffect(() => {
    if (eventMatrixList.length !== 0) {
      setIsEventListDispaly(true);
    }
  }, [
    eventMatrixList,
    successCampaignRequest,
    campaignList,
    dateRange,
    campaignById,
  ]);

  return (
    <>
      {Object.keys(campaignById).length === 0 ? (
        <div>Add Campaign</div>
      ) : (
        <>
          {campaignListPermission ? (
            <div>Please add Campaigns</div>
          ) : successCampaignRequest && isEventListDisplay ? (
            <div>
              <EventCardList
                eventMatrixList={eventMatrixList}
                campaignById={campaignById}
                dateRange={dateRange}
                showToggle={false}
                totalCount={totalCampaignEventCount}
              />

              <div className="row">{/* <CampaignStatus /> */}</div>
            </div>
          ) : (
            <ReactSpinner />
          )}
          <CampaignSummary
            toShowInfluencer={isCampaignSummary}
            campaignById={campaignById}
          />
        </>
      )}
    </>
  );
};
export default Home;
