import { ADMIN_USER_REDUCER } from "../../constants/actionConstants";

export const setRequestUserList = (data) => {
  return {
    type: ADMIN_USER_REDUCER.SET_REQUEST_USER_LIST,
    value: data,
  };
};

export const getUserRequestedListAction = (history) => {
  return {
    type: ADMIN_USER_REDUCER.GET_REQUESTED_USER_LIST_REQUEST,
    history,
  };
};

export const setUserProfileDetails = (data) => {
  return {
    type: ADMIN_USER_REDUCER.SET_USER_PROFILE_DETAILS,
    value: data,
  };
};

export const getUserProfileDetailsRequest = (data, history) => {
  return {
    type: ADMIN_USER_REDUCER.GET_USER_PROFILE_DETAILS,
    param: data,
    history,
  };
};

export const updateRegistrationStatusRequestAction = (data, history) => {
  return {
    type: ADMIN_USER_REDUCER.UPDATE_REGISTRATION_STATUS_REQUEST,
    data,
    history,
  };
};
