import React, { useEffect, useState } from "react";
import DynamicCountriesFields from "../../Comman/DynamicCountriesFields";
import ReactTagInput from "@pathofdev/react-tag-input";
import { Button } from "react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import DynamicInfluencerDetailFields from "../../Comman/DynamicInfluencerDetailFields";

const StepThreeInfluencerDetails = (props) => {
  const {
    nextStep,
    prevStep,
    isUpdateCampaignClick,
    setFormTitle,
    influArray,
    hashTags,
    setStepThreeDetails,
    stepThreeDetails,
    countriesArray,
    setInfluArray,
    setHashTags,
    setCountriesArray,
  } = props;

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({});
  const [disable, setDisable] = useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);

  useEffect(() => {
    setFormErrors(validate({ influArray, hashTags, countriesArray }));
  }, [influArray, hashTags, countriesArray, formValues]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
      setDisable(false);
    }
  }, [formErrors]);

  useEffect(() => {
    setFormTitle("Add Influencer Details");
    setFormErrors(validate({ influArray, hashTags, countriesArray }));
  }, []);

  const urlValidation = (value) => {
    if (
      value.match(
        /(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      ) === null
    )
      return true;
    else return false;
  };

  const handleValidation = (value, platform) => {
    switch (platform) {
      case "Youtube": {
        if (value.startsWith("https://") || value.startsWith("http://")) {
          if (urlValidation(value)) {
            return true;
          } else {
            return false;
          }
        } else return false;
      }
    }
  };

  const validate = (values) => {
    let errors = {};
    if (values?.influArray?.length !== 0) {
      values?.influArray?.map((infobj, i) => {
        if (!infobj.name) {
          errors["name" + i] = i;
          errors.name = "Please Enter Influerncer Name";
        }
        if (/\d/.test(infobj.name)) {
          errors["name" + i] = i;
          errors.name = "Influencer Name should only contains Alphabets";
        }
        if (!infobj.webpageTotarget) {
          errors.webpageTotarget = "Please Enter Webpage Target Link";
        }
        if (
          infobj.webpageTotarget.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
          ) === null
        ) {
          errors["webpage" + i] = i;
          errors.webpageTotarget = "Please Enter Valid URL Format";
        }
        if (infobj?.handle?.length !== 0) {
          infobj.handle.map((handlerObj, handleI) => {
            if (!handlerObj.platform) {
              errors.platform = "Please Enter Platform";
            }

            if (
              !handlerObj.platformHandler &&
              handlerObj.platform === "Youtube"
            ) {
              errors["handlerIndex" + i + handleI] = i + handleI;
              errors["platformHandler" + i + handleI] =
                "Please add Channel Link or Channel Name";
            }

            if (
              !handlerObj.platformHandler &&
              handlerObj.platform !== "Youtube"
            ) {
              errors["handlerIndex" + i + handleI] = i + handleI;
              errors["platformHandler" + i + handleI] = "Please Enter Username";
            }
            if (
              handlerObj.platformHandler !== "" &&
              handleValidation(handlerObj.platformHandler, handlerObj.platform)
            ) {
              errors["handlerIndex" + i + handleI] = i + handleI;
              errors["platformHandler" + i + handleI] = "Invalid handler";
            }
          });
        }
      });

      if (values?.hashTags?.length === 0) {
        errors.hashTags = "Please add at least one Hash Tags";
      }
      if (values?.countriesArray?.length !== 0) {
        values?.countriesArray?.map((countryobj) => {
          if (!countryobj?.country || countryobj?.country?.length === 0) {
            errors.country = "Please Select Country";
          }
          if (!countryobj?.cities || countryobj?.cities?.length === 0) {
            errors.cities = "Please Select at least one City";
          }
        });
      }
    }
    return errors;
  };

  const onSubmit = () => {
    setIsSubmitClick(true);
    setFormErrors({ ...validate({ influArray, hashTags, countriesArray }) });
    if (Object.keys(formErrors).length !== 0) {
      setDisable(true);
    } else {
      nextStep();
    }
  };

  return (
    <section>
      <div className="row">
        <div className="form-group mb-3">
          <label className="text-label">
            <h4>Add Influencer Details*</h4>
          </label>
          <DynamicInfluencerDetailFields
            isSubmitClick={isSubmitClick}
            influArray={influArray}
            setInfluArray={setInfluArray}
            stepThreeDetails={stepThreeDetails}
            setStepThreeDetails={setStepThreeDetails}
            formErrors={formErrors}
          />
        </div>
        <div className="col-lg-12 mb-3">
          <div className="form-group mb-3">
            <label className="text-label">
              <h4>Hash Tag*</h4>
            </label>
            <div
              className={
                isSubmitClick && formErrors.hashTags && "error-form-control"
              }
            >
              <ReactTagInput
                tags={hashTags}
                onChange={(newTags) => setHashTags(newTags)}
              />
            </div>
            {isSubmitClick && formErrors.hashTags && (
              <div className="text-danger fs-12">{formErrors.hashTags}</div>
            )}
          </div>
        </div>
        <div className="form-group mb-3">
          <label className="text-label">
            <h4>Area of Impact</h4>
          </label>
          <DynamicCountriesFields
            isSubmitClick={isSubmitClick}
            countriesArray={countriesArray}
            setCountriesArray={setCountriesArray}
            formErrors={isSubmitClick && formErrors}
            isUpdateCampaignClick={isUpdateCampaignClick}
          />
        </div>
      </div>
      <div className="d-inline">
        <button className="multip-prev-button" onClick={prevStep}>
          Prev
        </button>
        <Button
          className="multip-form-button"
          variant="primary"
          onClick={onSubmit}
          disabled={disable}
        >
          Next
        </Button>
      </div>
    </section>
  );
};

export default StepThreeInfluencerDetails;
