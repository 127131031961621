import React, { useEffect } from "react";
import Campaigns from "../../components/Manage/Campaigns";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowAddCampaignForm,
  setEditCampaignForm,
} from "../../../actions/Campaign/addCampaignAction";
import { ToastContainer, toast } from "react-toastify";

import CampaignSummary from "../../components/Manage/CampaignSummary";

const CampaignsContainer = () => {
  const dispatch = useDispatch();
  const {
    showAddCampaignForm,
    showInfluencerDetails,
    successCampaignResponse,
  } = useSelector((state) => state.addCampaignReducers);
  const { campaignById } = useSelector((state) => state.dashboardReducers);

  const notifyTopFullWidth = (message) => {
    toast.success(`✔️ ${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    // console.log("LOACTION", window.location);
    // if (successCampaignResponse) {
    //   notifyTopFullWidth("Successfully created Campaign");
    //   dispatch(setCampaignSuccessResponse(false));
    // }

    // notifyTopFullWidtsre3h("Successfully created Campaign");
    // console.log(showInfluencerDetails);
    dispatch(setEditCampaignForm(true));
  }, [showInfluencerDetails, successCampaignResponse, dispatch]);

  const handleAddCampaignClick = () => {
    dispatch(setShowAddCampaignForm(true));
  };
  return (
    <>
      {Object.keys(campaignById).length === 0 ? (
        <div>Add Campaign</div>
      ) : (
        <>
          <div className="mb-3">
            <Campaigns
              onAddCampaignClick={handleAddCampaignClick}
              showCampaignForm={showAddCampaignForm}
            />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgsressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          {/* {showInfluencerDetails && <InfluencerDetails />} */}
          {/* {!campaignListPermission && <CampaignProgress />} */}
          <CampaignSummary
            toShowInfluencer={true}
            campaignById={campaignById}
          />
        </>
      )}
    </>
  );
};

export default CampaignsContainer;
