import React from "react";
import MultiSelectOption from "./MultiSelectOption";
import ReactTagInput from "@pathofdev/react-tag-input";
import Select from "react-select";
import { Badge } from "react-bootstrap";

const disableStyle = {
  background: "hsl(0,0%,95%)",
};

const valueStyle = {
  fontWeight: "500",
  color: "black",
  fontSize: "15px",
};
export const InputField = (props) => {
  const { labelName, value } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">{labelName}</label>
      <div className="col-sm-9">
        <input
          type="text"
          className="form-control"
          value={value}
          readOnly
          style={disableStyle}
        />
      </div>
    </div>
  );
};

export const InputFieldInSegment = (props) => {
  const { labelName, value } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-5 col-form-label">{labelName}</label>
      <div className="col-sm-7">
        <input
          type="text"
          className="form-control"
          value={value}
          readOnly
          style={disableStyle}
        />
      </div>
    </div>
  );
};

export const ValueInputField = (props) => {
  const { labelName, value } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-4 col-form-label" style={{ fontSize: "14px" }}>
        {labelName} :
      </label>
      <div className="col-sm-8" style={{ paddingTop: "6px" }}>
        <span style={valueStyle}>{value}</span>
      </div>
    </div>
  );
};

export const BadgeValue = (props) => {
  const { labelName, valueList, tag } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">{labelName} :</label>
      <div className="col-sm-9">
        <div className="bootstrap-badge">
          {valueList?.map((data) => (
            <Badge variant="dark badge-lg light ml-2 m-1">
              {tag ? data : data.label}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

export const SingleBadgeValue = (props) => {
  const { labelName, value } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">{labelName} :</label>
      <div className="col-sm-9">
        <div className="bootstrap-badge">
          <Badge variant="dark badge-lg light ml-2">{value}</Badge>
        </div>
      </div>
    </div>
  );
};

export const MultiSelectInputField = (props) => {
  const { valueList, disabled, labelName } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">{labelName}</label>
      <div className="col-sm-9">
        <MultiSelectOption value={valueList} disabled={disabled} />
      </div>
    </div>
  );
};

export const ReactTagInputField = (props) => {
  const { tagList, labelName } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">{labelName}</label>
      <div className="col-sm-9">
        <ReactTagInput tags={tagList} readOnly={true} />
      </div>
    </div>
  );
};

export const DoubleValueInputField = (props) => {
  const { labelNameOne, labelNameTwo, valueOne, valueTwo } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">{labelNameOne} :</label>
      <div className="col-sm-3">
        {/* <span style={valueStyle}>{valueOne}</span> */}
        <Badge variant="dark badge-lg light ml-2">{valueOne}</Badge>
      </div>

      <label className="col-sm-3 col-form-label">{labelNameTwo} :</label>
      <div className="col-sm-3">
        {/* <span style={valueStyle}>{valueTwo}</span> */}
        <Badge variant="dark badge-lg light ml-2">{valueTwo}</Badge>
      </div>
    </div>
  );
};

export const DoubleInputField = (props) => {
  const { labelNameOne, labelNameTwo, valueOne, valueTwo } = props;
  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">{labelNameOne}</label>
      <div className="col-sm-3">
        <input
          type="text"
          className="form-control"
          value={valueOne}
          readOnly
          style={disableStyle}
        />
      </div>

      <label className="col-sm-3 col-form-label">{labelNameTwo}</label>
      <div className="col-sm-3">
        <input
          type="text"
          className="form-control"
          value={valueTwo}
          readOnly
          style={disableStyle}
        />
      </div>
    </div>
  );
};
export const InfluenerDetails = (props) => {
  const { name, webpageTotarget, handlerList } = props;
  return (
    <>
      {/* <label>
        <h5>{name}</h5>
      </label> */}
      <DoubleInputField
        labelNameOne="Influencer Name"
        valueOne={name}
        labelNameTwo="Webpage to target"
        valueTwo={webpageTotarget}
      />
      {handlerList &&
        handlerList.map((data, index) => (
          <DoubleInputField
            labelNameOne="Platform"
            valueOne={data.platform}
            labelNameTwo="Platform Handler"
            valueTwo={data.platformHandler}
          />
        ))}
    </>
  );
};

export const CountriesDetails = (props) => {
  const { country, citiesList, disabled } = props;
  const cities = [];
  const countryObj = { value: country, label: country };
  citiesList.map((data, i) => {
    cities.push({ value: data, label: data });
  });
  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">Country</label>
      <div className="col-sm-3">
        <Select value={countryObj} isDisabled={disabled} />
      </div>
      <label className="col-sm-3 col-form-label">Cities</label>
      <div className="col-sm-3">
        <MultiSelectOption value={cities} disabled={disabled} />
      </div>
    </div>
  );
};
