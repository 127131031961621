import { callApi } from "./apiHelper";

export const getUserPropertyFieldList = () => {
  return callApi(
    "/segment/getFieldsList?fieldType=UserProperty",
    "GET",
    {},
    {},
    {}
  );
};

export const getSegmentCount = (data) => {
  return callApi("/segment/query", "POST", data, {}, {});
};

export const fetchQueryRequestData = (data) => {
  return callApi("/segment/fetchQueryData", "POST", data, {}, {});
};

export const deleteUserQuery = (data) => {
  return callApi("/segment/delete", "POST", data, {}, {});
};

export const getCsvS3Url = (data) => {
  return callApi("/segment/getCsvS3Url", "POST", data, {}, {});
};

export const getSegmentByCampaign = (data) => {
  return callApi("/segment/getSegmentByUser", "GET", data, {}, {});
};
