import axios from "axios";
import { ACCEPT_TYPE } from "../constants/appConstants";

export const callApi = (
  path,
  method,
  data = {},
  apiHeadres = {},
  params = {}
) => {
  const headers = {
    Accept: ACCEPT_TYPE,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
  };
  if (localStorage.getItem("accessToken")) {
    headers["Authorization"] = getAccessToken();
  }
  return axios({
    method: method,
    url: `${process.env.REACT_APP_BACKEND_SERVER_API_URL}${path}`,
    data,
    params,
    headers: headers,
  });
};

export const getAccessToken = () => {
  var accessToken;
  accessToken = localStorage.getItem("accessToken");
  return accessToken;
};

export const setAccessTokenFromLocal = (accessToken) => {
  if (accessToken !== null) localStorage.setItem("accessToken", accessToken);
};
