import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import MultiSelectOption from "../../Comman/MultiSelectOption";

const StepOneCampaignDetails = (props) => {
  const {
    nextStep,
    currentStep,
    setStepOneDetails,
    stepOneDetails,
    setFormTitle,
  } = props;
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({});
  const [disable, setDisable] = useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);

  useEffect(() => {
    setFormErrors(validate(stepOneDetails));
  }, [stepOneDetails, currentStep, formValues]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
      setDisable(false);
    }
  }, [formErrors]);

  useEffect(() => {
    setFormTitle("Add Campaign Details");
    setFormValues(stepOneDetails);
  }, [stepOneDetails, formErrors]);

  const validate = (values) => {
    let errors = {};
    if (!values.nameCamp) {
      errors.nameCamp = "Please Enter Campaign Name";
    }

    if (values.nameCamp.length > 20 || values.nameCamp.length < 4) {
      errors.nameCamp =
        "Please enter atleast 4 to 20 charater for Campaign Name";
    }
    if (!values.minBuget) {
      errors.minBuget = "Please Enter Minimun Buget";
    }
    if (!values.maxBuget) {
      errors.maxBuget = "Please Enter Maximun Buget";
    }
    if (values.minBuget > values.maxBuget) {
      errors.minBuget = "Min Budget should be less then Max budget";
      errors.maxBuget = "Max Budget should be greater then Min budget";
    }
    if (!values.startDate || !values.endDate) {
      errors.date = "Please set the Date Range";
    }
    if (!values.goalList || values.goalList.length === 0) {
      errors.goalList = "Please Select at least one Goal";
    }
    if (!values.eventList || values.eventList.length === 0) {
      errors.eventList = "Please Select at least one Event ";
    }
    return errors;
  };

  const options = [
    { value: "Generate Qualified Leads", label: "Generate Qualified Leads" },
    { value: "Boost Traffic", label: "Boost Traffic" },
    { value: "Drive sign ups", label: "Drive sign ups" },
    { value: "Increase Revenue", label: "Increase Revenue" },
    { value: "Drive sales", label: "Drive sales" },
    { value: "Enhance video views", label: "Enhance video views" },
  ];

  const eventOptions = [
    { value: "search", label: "Search" },
    { value: "productViewed", label: "View Product" },
    { value: "productAddedToCart", label: "Add To Cart" },
    { value: "productAddedToWishlist", label: "Add To Wishlist" },
    { value: "cartViewed", label: "Cart Viewed" },
    { value: "orderPlaced", label: "Order Placed" },
    { value: "checkoutStarted", label: "Checkout Started" },
    { value: "productRemovedFromCart", label: "Removed from Cart" },
    { value: "register", label: "Register" },
    { value: "login", label: "Login" },
  ];
  const onSubmit = () => {
    setIsSubmitClick(true);
    setFormErrors({ ...validate(stepOneDetails) });

    if (Object.keys(formErrors).length !== 0) {
      setDisable(true);
    } else {
      nextStep();
    }
  };

  const handleCallback = (start, end, label) => {
    setStepOneDetails({
      ...stepOneDetails,
      startDate: moment(start).format("YYYY-MM-DD"),
      endDate: moment(end).format("YYYY-MM-DD"),
    });
    // setStartDateValue(moment(start).format("YYYY-MM-DD"));
    // setEndDateValue(moment(end).format("YYYY-MM-DD"));
  };

  return (
    <section>
      <form id="step-form-horizontal" className="step-form-horizontal">
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Campaign Name*</label>
              <div
                className={
                  isSubmitClick && formErrors?.nameCamp && "error-form-control"
                }
              >
                <input
                  type="text"
                  // style={style}
                  name="nameCamp"
                  className="form-control"
                  placeholder="Campaign Name"
                  required
                  value={stepOneDetails?.nameCamp}
                  onChange={(e) => {
                    setStepOneDetails({
                      ...stepOneDetails,
                      nameCamp: e.target.value,
                    });
                  }}
                />
              </div>
              {isSubmitClick && formErrors?.nameCamp && (
                <div className="text-danger fs-12">{formErrors?.nameCamp}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label" htmlFor="val-currency">
                Budget*
              </label>
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div
                    className={
                      isSubmitClick &&
                      formErrors.minBuget &&
                      "error-form-control"
                    }
                  >
                    <input
                      // style={style}
                      className="form-control"
                      type="number"
                      name="minBuget"
                      id="minBuget"
                      value={stepOneDetails.minBuget}
                      placeholder="Min: ₹ 30000"
                      onChange={(e) => {
                        setStepOneDetails({
                          ...stepOneDetails,
                          minBuget: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {isSubmitClick && formErrors.minBuget && (
                    <div className="text-danger fs-12">
                      {formErrors.minBuget}
                    </div>
                  )}
                </div>

                <div className="col-lg-6 mb-2">
                  <div
                    className={
                      isSubmitClick &&
                      formErrors.maxBuget &&
                      "error-form-control"
                    }
                  >
                    <input
                      // style={style}
                      className="form-control"
                      type="number"
                      name="maxBuget"
                      id="maxBuget"
                      value={stepOneDetails.maxBuget}
                      placeholder="Max: ₹ 70000"
                      onChange={(e) => {
                        setStepOneDetails({
                          ...stepOneDetails,
                          maxBuget: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {isSubmitClick && formErrors.maxBuget && (
                    <div className="text-danger fs-12">
                      {formErrors.maxBuget}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <p className="mb-1">Campaign Start - End Date*</p>
              <div
                className={
                  isSubmitClick && formErrors.date && "error-form-control"
                }
              >
                {/* <RangeDatePicker
                  startText="start"
                  endText="end"
                  startPlaceholder="Start Date"
                  endPlaceholder="End Date"
                  onChange={(start, end) => {
                    setStepOneDetails({
                      ...stepOneDetails,
                      startDate: start,
                      endDate: end,
                    });
                  }}
                /> */}
                <DateRangePicker
                  open={false}
                  // initialSettings={{
                  //   startDate: "2022-01-09",
                  //   endDate: moment().format("YYYY-MM-DD"),
                  // }}
                  onCallback={handleCallback}
                >
                  <div class="range-picker-input">
                    <span class="range-picker-input__start">
                      <div class="picker-input range">
                        <input
                          class="picker-input__text"
                          type="text"
                          placeholder="Start Date"
                          value={
                            stepOneDetails?.startDate !== ""
                              ? moment(stepOneDetails?.startDate).format(
                                  "YYYY-MM-DD"
                                )
                              : moment().format("YYYY-MM-DD")
                          }
                          // style={{ paddingLeft: "20px", color: "#6e6e6e" }}
                        />
                      </div>
                    </span>
                    <span class="range-picker-input__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="icon-right-arrow"
                        id="right-arrow"
                        size="16"
                        color="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                      </svg>
                    </span>
                    <span class="range-picker-input__end">
                      <div class="picker-input range">
                        <input
                          class="picker-input__text"
                          type="text"
                          value={
                            stepOneDetails?.endDate !== ""
                              ? moment(stepOneDetails?.endDate).format(
                                  "YYYY-MM-DD"
                                )
                              : moment().format("YYYY-MM-DD")
                          }
                          placeholder="End Date"
                          // style={{ paddingLeft: "20px", color: "#6e6e6e" }}
                        />
                      </div>
                    </span>
                  </div>
                </DateRangePicker>
              </div>
              {isSubmitClick && formErrors.date && (
                <div className="text-danger fs-12">{formErrors.date}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Goal*</label>
              <div
                className={
                  isSubmitClick && formErrors.goalList && "error-form-control"
                }
              >
                <MultiSelectOption
                  className="multi-select"
                  optionList={options}
                  value={stepOneDetails.goalList}
                  onChange={(value) =>
                    setStepOneDetails({ ...stepOneDetails, goalList: value })
                  }
                />
              </div>
              {isSubmitClick && formErrors.goalList && (
                <div className="text-danger fs-12">{formErrors.goalList}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group mb-3">
              <label className="text-label" htmlFor="val-website">
                Events to Track*
              </label>
              <div
                className={
                  isSubmitClick && formErrors.eventList && "error-form-control"
                }
              >
                <MultiSelectOption
                  className="multi-select"
                  optionList={eventOptions}
                  value={stepOneDetails.eventList}
                  onChange={(value) =>
                    setStepOneDetails({ ...stepOneDetails, eventList: value })
                  }
                />
              </div>
              {isSubmitClick && formErrors.eventList && (
                <div className="text-danger fs-12">{formErrors.eventList}</div>
              )}
            </div>
          </div>
        </div>
      </form>
      <Button
        className="multip-form-button"
        variant="primary"
        type="submit"
        onClick={onSubmit}
        disabled={disable}
      >
        Next
      </Button>
    </section>
  );
};

export default StepOneCampaignDetails;
