import React, { Fragment } from "react";
import { Button, Row, Card, Col } from "react-bootstrap";

const DynamicInfluencerPostFields = (props) => {
  const { influArray, setInfluencerArray } = props;

  const handleAddFields = (harray, hindex, infIndex) => {
    console.log("HARRAY", harray);
    const values = [...harray];
    values[hindex].postUrl.push("");
    influArray[infIndex].handle = values;
    console.log("Influencer ARRAY", influArray);
    setInfluencerArray([...influArray]);
  };

  // const handleAddInfluFields = () => {
  //   const values = [...influArray];
  //   values.push({
  //     name: "",
  //     webpageTotarget: "",
  //     handle: [{ platform: "Youtube", platformHandler: "" }],
  //   });
  //   // setInfluArray([...values]);
  // };

  // const handleRemoveFields = (harray, infIndex, index) => {
  //   const values = [...harray];
  //   values.splice(index, 1);
  //   influArray[infIndex].handle = values;

  //   // setInfluArray([...influArray]);
  // };

  // const handleRemoveInfluFields = (infIndex) => {
  //   const values = [...influArray];
  //   values.splice(infIndex, 1);
  //   // setInfluArray([...values]);
  // };

  const handleInputChange = (index, e, hIndex, handle) => {
    const values = [...influArray];

    // console.log(value )
    // values[index].handle
    if (e.target.name === "name") {
      values[index].name = e.target.value;
    } else if (e.target.name === "webpageTotarget") {
      values[index].webpageTotarget = e.target.value;
    } else {
      const handlerValue = [...handle];
      // if (e.target.name === "platform") {
      //   handlerValue[hIndex].platform = e.target.value;
      // } else {
      handlerValue[hIndex].platformHandler = e.target.value;
      // }
      values[index].handle = handlerValue;
    }
    // setInfluArray([...values]);
  };

  return (
    <section>
      <div className="row">
        {influArray &&
          influArray.map((data, i) => (
            <Fragment key={`${data}~${i}`}>
              <Card className="shadow p-3 mb-5 bg-white">
                <Card.Body style={{ padding: "0px", paddingTop: "18px" }}>
                  <Row>
                    <Col
                      xl={3}
                      lg={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "16px",
                      }}
                    >
                      {data.name}
                    </Col>
                  </Row>
                  <Row>
                    {data &&
                      data.handle.map((inputField, index) => {
                        return (
                          <div className="col-12">
                            <Fragment key={`${inputField}~${index}`}>
                              <div className="row">
                                <Col xl={12} lg={12}>
                                  <div className="mb-3 mt-2 ml-2 row">
                                    <label
                                      className="col-sm-2 col-form-label"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Add Post Link:
                                    </label>
                                    <label className="col-sm-3 col-form-label">
                                      <span
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {inputField?.platform}
                                      </span>
                                    </label>

                                    <div className="col-sm-6">
                                      {inputField &&
                                        inputField.postUrl.map(
                                          (postValue, postUrlIndex) => {
                                            return (
                                              <Row className="mt-2">
                                                <input
                                                  type="text"
                                                  className="form-control col"
                                                  name="post"
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      i,
                                                      e,
                                                      index,
                                                      data.handle
                                                    )
                                                  }
                                                />
                                                <Col sm={3}>
                                                  <div className="row">
                                                    <Button
                                                      variant="danger tp-btn-light"
                                                      className="col me-2"
                                                      //   disabled={
                                                      //     handleObj?.postUrl
                                                      //       ?.length === 1
                                                      //   }
                                                      //   onClick={() =>
                                                      //     handleAddFields(
                                                      //       data.handle,
                                                      //       i
                                                      //     )
                                                      //   }
                                                    >
                                                      <span className="">
                                                        <i className="fa fa-minus color-danger" />
                                                      </span>
                                                    </Button>
                                                    <Button
                                                      variant="danger tp-btn-light"
                                                      className="col me-1"
                                                      //   onClick={() =>
                                                      //     handleAddInfluFields(
                                                      //       influencerId,
                                                      //       handlerId,
                                                      //       postUrlIndex
                                                      //     )
                                                      //   }
                                                      onClick={() =>
                                                        handleAddFields(
                                                          data.handle,
                                                          index,
                                                          i
                                                        )
                                                      }
                                                    >
                                                      <span className="">
                                                        <i className="fa fa-plus" />
                                                      </span>
                                                    </Button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </Fragment>
                          </div>
                        );
                      })}
                  </Row>
                </Card.Body>
              </Card>
            </Fragment>
          ))}
      </div>
    </section>
  );
};

export default DynamicInfluencerPostFields;
