import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
// import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { logoutRequest } from "../../../actions/Login/logout";
import { useDispatch } from "react-redux";
import { resetLoginDetails } from "../../../actions/Login/loginAction";
import { resetDashboardDetails } from "../../../actions/Dashboard/dashboardAction";
import { callApi } from "../../../services/apiHelper";

const UserPropertiesCard = (props) => {
  const {
    queryArray,
    setQueryArray,
    index,
    setDisableFilter,
    selectedTab,
    setShowCountFilter,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [openKeyValue, setOpenKeyValue] = useState(true);
  const [selectedField, setSelectedField] = useState({});
  const [lableName, setLable] = useState("");
  const [labelList, setLabelList] = useState([]);
  const [labelListSelect, setLabelListSelect] = useState([]);
  const [dataType, setDataType] = useState("");
  const [isValueSelected, setIsValueSelected] = useState(false);
  const [selectOperator, setSelectOperator] = useState("");

  // useEffect(() => {
  //   // dispatch();
  //   console.log("userPropertyFieldList--->", userPropertyFieldList);
  //   const dataOne = {
  //     value: userPropertyFieldList?.[0]?.dbProperty?.attribute,
  //     label: userPropertyFieldList?.[0]?.label,
  //   };

  //   setDefaultProperty({ ...dataOne });
  // }, [userPropertyFieldList]);

  const resetProperty = () => {
    setSelectedField({});
    setLable("");
    setLabelList([]);
    setDataType("");
    setIsValueSelected(false);
    setSelectOperator("");
    setDisableFilter(true);
    setShowCountFilter(false);
  };

  useEffect(() => {
    resetProperty();
    setOpenKeyValue(true);
  }, [selectedTab]);

  useEffect(() => {
    // console.log("Open Key Value in UserProperties", openKeyValue);
  }, [openKeyValue]);

  const fetchPropertyList = async () => {
    const getData = await callApi(
      "/segment/getFieldsList?fieldType=UserProperty",
      "GET",
      {},
      {},
      {}
    ).catch((error) => {
      if (error.response.status === 401 || error.response.status === 400) {
        dispatch(resetLoginDetails());
        dispatch(resetDashboardDetails());
        logoutRequest(history);
      }
    });
    return getData;
  };

  const { data } = useQuery("fetchPropertyList", () => fetchPropertyList());
  const userPropertyList = data?.data?.data;

  const userPropertyArray = userPropertyList?.map((data) => {
    return {
      value: data.dbProperty.attribute,
      label: data.label,
    };
  });

  useEffect(() => {
    setDataType(selectedField?.objectValue?.dbProperty?.type);

    if (isValueSelected) {
      const array = Object.keys(selectedField?.objectValue?.fieldOperation);

      const arrayaData = array.map((data, i) => {
        return { value: data, label: data };
      });
      setLabelListSelect([...arrayaData]);
      setLabelList([...array]);
    }
  }, [selectedField, isValueSelected, queryArray]);

  // useEffect(() => {
  //   const values = [...queryArray];

  //   // if (userPropertyList?.length !== 0) {
  //   //   const keyValue = userPropertyList[0];
  //   //   setSelectedField({ ...selectedField, keyValue });
  //   //   const values = [...queryArray];
  //   //   values[index].queryData.key = userPropertyList[0]?.dbProperty?.attribute;
  //   //   setIsValueSelected(true);
  //   //   setQueryArray([...values]);
  //   // }
  // }, [selectedField, labelList, userPropertyList]);

  const handleOnSelect = (e) => {
    const values = [...queryArray];
    // if (e.target.name === "keyValue") {
    const emptyarray = [];
    setLabelList([...emptyarray]);
    setLable(e.value);

    // values[index].queryData.key = `eventMetaData.${e.target.value}`;
    values[index].queryData.key = `userData.${e.value}`;
    values[index].queryData.type = "non-event";
    setOpenKeyValue(false);
    values[index].queryData.keyAttribute = e.label;
    const fieldObject = userPropertyList.filter((obj) => {
      if (obj.dbProperty.attribute === e.value) return obj;
    });

    const objectValue = fieldObject[0];
    setSelectedField({ ...selectedField, objectValue });
    setIsValueSelected(true);
    // }

    // else if (e.target.name === "caseSensitive") {
    //   console.log(e.target);
    // }
    setQueryArray([...values]);
  };

  const handleOnOperator = (e) => {
    const values = [...queryArray];
    if (selectOperator === "") {
      const opValue = Object.keys(userPropertyList[0].fieldOperation)[0];
      setSelectOperator(opValue);
      let operator = opValue.replace(/([A-Z])/g, " $1").trim();
      values[index].queryData.operator = operator.toLowerCase();
    }
    if (e.target.name === "operationValue") {
      setSelectOperator(e.target.value);
      let operator = e.target.value.replace(/([A-Z])/g, " $1").trim();
      values[index].queryData.operator = operator.toLowerCase();
    } else {
      if (selectOperator !== "isBetween") {
        values[index].queryData.value = e.target.value;
        setDisableFilter(false);
        setShowCountFilter(false);
      } else {
        values[index].queryData[e.target.name] = e.target.value;
        if (e.target.name === "valueTwo") {
          setDisableFilter(false);
          setShowCountFilter(false);
        }
      }
    }
    values[index].queryData.valueType = dataType;
    setQueryArray([...values]);
  };

  const ifInputString = (type, i) => {
    switch (type) {
      case "string":
        return (
          <input
            className="mb-1 form-control"
            type="text"
            name="value"
            value={queryArray[index]?.queryData?.value}
            onChange={(e) => handleOnOperator(e)}
          />
        );
      case "number":
        return (
          <input
            className="mb-1 form-control"
            type="number"
            name={`value${i}`}
            value={
              selectOperator === "isBetween"
                ? queryArray[index]?.queryData[`value${i}`]
                : queryArray[index]?.queryData?.value
            }
            onChange={(e) => handleOnOperator(e)}
          />
        );
      default:
        <div></div>;
    }
  };

  return (
    <>
      {userPropertyList?.length !== 0 && (
        <Row>
          <Col sm={3} style={{ zIndex: "1" }}>
            <Select
              // defaultMenuIsOpen
              options={userPropertyArray}
              placeholder={<div>Type to search</div>}
              value={{
                value:
                  queryArray[index]?.queryData?.value !== undefined
                    ? queryArray[index]?.queryData?.value
                    : "Select...",
                label:
                  queryArray[index]?.queryData?.keyAttribute !== undefined
                    ? queryArray[index]?.queryData?.keyAttribute
                    : "Select...",
              }}
              className="singleSelectMenu"
              onChange={(e) => handleOnSelect(e)}
            />

            {/* <select
              className="mb-1 selectMenu"
              id="inputState"
              style={{ cursor: "pointer" }}
              defaultValue="option-1"
              value={queryArray[index]?.queryData?.key}
              onChange={(e) => handleOnSelect(e)}
              name="keyValue"
            >
              {userPropertyList?.map((data) => (
                <option value={data.dbProperty.attribute}>{data.label}</option>
              ))}
            </select> */}
          </Col>
          <Col sm={3}>
            <div style={{ display: labelList.length !== 0 ? "" : "none" }}>
              {/* <Select
                // defaultMenuIsOpen
                
                options={labelListSelect}
                placeholder={<div>Type to search</div>}
                // value={{
                //   value:
                //     queryArray[index]?.queryData?.value !== undefined
                //       ? queryArray[index]?.queryData?.value
                //       : "Select...",
                //   label:
                //     queryArray[index]?.queryData?.keyAttribute !== undefined
                //       ? queryArray[index]?.queryData?.keyAttribute
                //       : "Select...",
                // }}
                className="singleSelectMenu"
                // display="none"

                // onChange={(e) => handleOnSelect(e)}
                // styles={colourStyles}
              /> */}
            </div>
            <select
              className="mb-1 form-control"
              id="inputState"
              name="operationValue"
              defaultValue="option-1"
              value={queryArray[index]?.queryData?.operator}
              onChange={(e) => handleOnOperator(e)}
              style={{ display: labelList.length !== 0 ? "" : "none" }}
            >
              {/* <option value="choose">choose...</option> */}

              {labelList?.map((data) => (
                <option
                  value={data
                    .replace(/([A-Z])/g, " $1")
                    .trim()
                    .toLowerCase()}
                >
                  {data}
                </option>
              ))}
            </select>
          </Col>
          <Col sm={selectOperator === "isBetween" ? 2 : 3}>
            {ifInputString(dataType, "One")}
          </Col>
          {selectOperator === "isBetween" && (
            <>
              <Col sm={1}>and</Col>
              <Col sm={2}> {ifInputString(dataType, "Two")}</Col>
            </>
          )}
          {/* <Col sm={3}>
            <Form.Check
              style={{ display: dataType === "string" ? "" : "none" }}
              type="checkbox"
              id="defaylt"
              label="Case Sensitive"
              name="caseSensitive"
              onClick={(e) => handleOnSelect(e)}
            />
          </Col> */}
        </Row>
      )}
    </>
  );
};

export default UserPropertiesCard;
