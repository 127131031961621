import { ANALYZE_REDUCER } from "../../constants/actionConstants";

export const setShowAnalyseDetails = (data) => {
  return {
    type: ANALYZE_REDUCER.SHOW_INFLUENCER_DETAILS,
    value: data,
  };
};

export const setCitiesDateRange = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_CITIES_DATE_RANGE,
    value: data,
  };
};

export const setCountryDateRange = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_COUNTRY_DATE_RANGE,
    value: data,
  };
};

export const setInfluencerDateRange = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_INFLUENCER_DATE_RANGE,
    value: data,
  };
};

export const getAllInfluencerEventRequest = (data) => {
  return {
    type: ANALYZE_REDUCER.GET_ALL_INFLUENCER_EVENT_REQUEST,
    value: data,
  };
};

export const setOnInfluencerDetailsCLick = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_INFLUENCER_DETAILS_ON_CLICK,
    value: data,
  };
};

export const setOnClickDonutGraph = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_ON_CLICK_DONUT_GRAPH,
    value: data,
  };
};

export const setEventMatrixValue = (data) => {
  return {
    type: ANALYZE_REDUCER.SELECTED_MATRIX_VALUE,
    value: data,
  };
};

export const setTotalInflueEventMatrix = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_TOTAL_INFLUE_EVENT_MATRIX,
    value: data,
  };
};

export const setAllInfluencerEventData = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_ALL_INFLUENCER_EVENT_DATA,
    value: data,
  };
};

export const setDefaultEventMatrix = (data) => {
  return {
    type: ANALYZE_REDUCER.DEFAULT_EVENT_VALUE,
    value: data,
  };
};

export const setEventMatrixError = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_SELECT_EVENT_MATRIX_ERROR,
    value: data,
  };
};

export const setCitiesData = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_CITIES_DATA,
    value: data,
  };
};

export const setCountriesData = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_COUNTRIES_DATA,
    value: data,
  };
};
export const getCitiesDataRequest = (data, history) => {
  return {
    type: ANALYZE_REDUCER.GET_CITIES_DATE_REQUEST,
    value: data,
    history,
  };
};

export const setInfluencerUserDateRange = (data, history) => {
  return {
    type: ANALYZE_REDUCER.SET_INFLUENCER_USER_DATE_RANGE,
    value: data,
  };
};

export const setDeviceEventDateRange = (data, history) => {
  return {
    type: ANALYZE_REDUCER.SET_DEVICE_EVENT_DATE_RANGE,
    value: data,
  };
};

export const setDeviceEventDateLabel = (data, history) => {
  return {
    type: ANALYZE_REDUCER.SET_DEVICE_EVENT_DATE_LABEL,
    value: data,
  };
};

export const setInfluencerUserLabelRange = (data, history) => {
  return {
    type: ANALYZE_REDUCER.SET_INFLUENCER_USER_DATE_LABEL,
    value: data,
  };
};

export const setDeviceEventDetailsData = (data, history) => {
  return {
    type: ANALYZE_REDUCER.SET_DEVICE_EVENT_DETAILS_DATA,
    value: data,
  };
};

export const setTotalDeviceEventDetailsCount = (data, history) => {
  return {
    type: ANALYZE_REDUCER.SET_TOTAL_DEVICE_EVENT_COUNT,
    value: data,
  };
};

export const getCountriesDataRequest = (data, history) => {
  return {
    type: ANALYZE_REDUCER.GET_COUNTRIES_DATE_REQUEST,
    value: data,
    history,
  };
};

export const getInfluencerEventRequest = (data, history) => {
  return {
    type: ANALYZE_REDUCER.GET_INFLU_EVENT_MATRIX_REQUEST,
    value: data,
    history,
  };
};

export const getDeviceEventDataRequest = (data, history) => {
  return {
    type: ANALYZE_REDUCER.GET_DEVICE_EVENT_DETAILS_REQUEST,
    value: data,
    history,
  };
};

export const setInflTotalCount = (data) => {
  return {
    type: ANALYZE_REDUCER.SET_INFL_TOTAL_COUNT,
    value: data,
  };
};

export const setInfluencerEventData = (data, eventName) => {
  return {
    type: ANALYZE_REDUCER.SET_INFLUENCER_EVENT_ARRAY,
    value: data,
    eventName,
  };
};
