import { REGISTERATION_REDUCER } from "../../constants/actionConstants";

export const updateUserProfileRequest = (data, history) => {
  return {
    type: REGISTERATION_REDUCER.UPDATE_USER_PROFILE_REQUEST,
    data,
    history,
  };
};
export const setIfEmailExist = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_IF_EMAIL_EXIST,
    value: data,
  };
};
export const setRegisterEmailId = (data) => {
  return {
    type: REGISTERATION_REDUCER.REGISTER_EMAIL_ID,
    value: data,
  };
};

export const setRegisterUsername = (data) => {
  return {
    type: REGISTERATION_REDUCER.REGISTER_USER_NAME,
    value: data,
  };
};

export const setIsOtpSend = (data) => {
  return {
    type: REGISTERATION_REDUCER.IS_OTP_SEND,
    value: data,
  };
};

export const setUserPassword = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_USER_PASSWORD,
    value: data,
  };
};

export const setIsOtpInvalid = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_IS_OTP_INVALID,
    value: data,
  };
};

export const setIsCompanyRegisterSuccess = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_IS_COMPANY_REGISTER_SUCCESS,
    value: data,
  };
};

export const setIsUserVerified = (data) => {
  return {
    type: REGISTERATION_REDUCER.IS_USER_VERIFIED,
    value: data,
  };
};

export const setSelectedCompany = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_SELECTED_COMPANY,
    value: data,
  };
};

export const setRegisterStatus = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_REGISTRATION_STATUS,
    value: data,
  };
};

export const setCreatedCompanyId = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_CREATED_COMPANY_ID,
    value: data,
  };
};

export const setSuccessRegisterUser = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_SUCCESS_REGISTER_USER,
    value: data,
  };
};

export const setCompanyList = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_COMPANY_LIST,
    value: data,
  };
};

export const setSuccessCompanyCreation = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_SUCCESS_COMPANY_CREATION,
    value: data,
  };
};

export const setIsJoinButtonClick = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_IS_JOIN_BUTTON_CLICK,
    value: data,
  };
};

export const setRegisterPhone = (data) => {
  return {
    type: REGISTERATION_REDUCER.REGISTER_PHONE_NUMBER,
    value: data,
  };
};

export const setSendOtp = (data) => {
  return {
    type: REGISTERATION_REDUCER.SEND_OTP_VALUE,
    value: data,
  };
};

export const isEmailIdPresentRequest = (email, history) => {
  return {
    type: REGISTERATION_REDUCER.IS_EMAIL_ID_PRESENT_REQUEST,
    email,
    history,
  };
};

export const setIsSignUpClick = (data) => {
  return {
    type: REGISTERATION_REDUCER.IS_SIGN_UP_CLICK,
    value: data,
  };
};

export const generateOTPRequestAction = (data, history) => {
  return {
    type: REGISTERATION_REDUCER.GENERATE_OTP_REQUEST,
    data,
    history,
  };
};

export const addUserCompanyRequestAction = (data, history) => {
  return {
    type: REGISTERATION_REDUCER.ADD_USER_COMPANY_REQUEST,
    data,
    history,
  };
};

export const verifyOTPRequestAction = (data, history) => {
  return {
    type: REGISTERATION_REDUCER.VERIFY_OTP_REQUEST,
    data,
    history,
  };
};

export const getCompanyListRequestAction = (history) => {
  return {
    type: REGISTERATION_REDUCER.GET_COMPANY_LIST_REQUEST,
    history,
  };
};

export const companyRegisterRequestAction = (data, userData, history) => {
  return {
    type: REGISTERATION_REDUCER.COMPANY_REGISTER_REQUEST,
    data,
    userData,
    history,
  };
};

export const userRegisterRequestAction = (data, history) => {
  return {
    type: REGISTERATION_REDUCER.USER_REGISTER_REQUEST,
    data,
    history,
  };
};

export const changeUserRegistrationStatusRequest = (data, history) => {
  return {
    type: REGISTERATION_REDUCER.CHANGE_USER_REGISTRATION_STATUS,
    data,
    history,
  };
};

export const setRegisterUserIdAction = (data) => {
  return {
    type: REGISTERATION_REDUCER.SET_REGISTER_USER_ID,
    value: data,
  };
};

// export const signInCheckRequestAction = (data, history) => {
//   return {
//     type: REGISTERATION_REDUCER.GENERATE_OTP_REQUEST,
//     data,
//     history,
//   };
// };
