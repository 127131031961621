import { takeLatest, call, put } from "redux-saga/effects";
import { ADMIN_USER_REDUCER } from "../constants/actionConstants";
import {
  getUserRequestList,
  updateRegistrationStatus,
  getUserDetails,
} from "../services/adminUserService";
import { logoutRequest } from "../actions/Login/logout";
import {
  setRequestUserList,
  setUserProfileDetails,
} from "../actions/AdminSetting/adminSetting";
import { resetDashboardDetails } from "../actions/Dashboard/dashboardAction";
import { resetLoginDetails } from "../actions/Login/loginAction";

export function* getRequestedUserListSaga(action) {
  try {
    const data = yield call(getUserRequestList);
    yield put(setRequestUserList(data?.data?.data));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}
export function* updateRegistartionStatusSaga(action) {
  try {
    yield call(updateRegistrationStatus, action.value);
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* getUserDetailSaga(action) {
  try {
    const data = yield call(getUserDetails, action.param);
    yield put(setUserProfileDetails(data?.data?.data));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export default function* adminUserSaga() {
  yield takeLatest(
    ADMIN_USER_REDUCER.GET_REQUESTED_USER_LIST_REQUEST,
    getRequestedUserListSaga
  );
  yield takeLatest(
    ADMIN_USER_REDUCER.UPDATE_REGISTRATION_STATUS_REQUEST,
    updateRegistartionStatusSaga
  );
  yield takeLatest(
    ADMIN_USER_REDUCER.GET_USER_PROFILE_DETAILS,
    getUserDetailSaga
  );
}
