import React, { createContext, useEffect } from "react";
import { useSelector } from "react-redux";

export const CampaignContext = createContext();

const CampaignContextProvider = (props) => {
  const { campaignById } = useSelector((state) => state.dashboardReducers);

  useEffect(() => {
    // setCampaignId(selectedCampaign)
  }, [campaignById]);
  return (
    <CampaignContext.Provider value={campaignById}>
      {props.children}
    </CampaignContext.Provider>
  );
};

export default CampaignContextProvider;
