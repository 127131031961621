import { callApi } from "./apiHelper";

export const isEmailIdPresent = (email) => {
  return callApi("/user/isEmailPresent", "GET", {}, {}, { email });
};

export const generateOTPApi = (data) => {
  return callApi("/user/sendEmailOtp", "POST", data);
};

export const changeUserStatus = (data) => {
  return callApi("/private/user/updateUserRegistrationStatus", "PUT", data);
};

export const getCompanyListApi = () => {
  return callApi("/company/getList", "GET");
};

export const addUserCompanyApi = (data) => {
  return callApi("/user/addUserCompany", "POST", data, {}, {});
};

export const verifyOTPApi = (data) => {
  return callApi(`/user/verifyEmailOtp`, "POST", data, {}, {});
};

export const companyRegistrationApi = (data) => {
  return callApi("/company/register", "POST", data);
};

export const userRegistrationApi = (data) => {
  return callApi("/user/register", "POST", data);
};

export const userUpdateData = (data) => {
  return callApi("/private/user/update", "PATCH", data);
};
