import { ANALYZE_REDUCER } from "../constants/actionConstants";
import moment from "moment";

export const initialState = {
  showInfluencerDetails: false,
  onInfluencerDetailClick: {},
  onClickDonutGraph: false,
  selectedEventMatrixValue: null,
  selectEventError: "",
  defaultEventMatrixValue: {},
  influencerCitiesDateRange: {},
  citiesArray: [],
  countriesArray: [],
  influTotalCount: 0,
  influencerEventArray: [],
  influencerDateRange: {
    startDate: moment(moment().subtract(6, "days")).format("YYYY-MM-DD"),
    endDate: moment(moment()).format("YYYY-MM-DD"),
  },
  citiesDateRange: {
    startDate: moment(moment().subtract(6, "days")).format("YYYY-MM-DD"),
    endDate: moment(moment()).format("YYYY-MM-DD"),
  },
  countryDateRange: {
    startDate: moment(moment().subtract(6, "days")).format("YYYY-MM-DD"),
    endDate: moment(moment()).format("YYYY-MM-DD"),
  },
  influencerUserDateRange: {
    startDate: moment(moment().subtract(6, "days")).format("YYYY-MM-DD"),
    endDate: moment(moment()).format("YYYY-MM-DD"),
  },
  deviceEventDateRange: {
    startDate: moment(moment().subtract(6, "days")).format("YYYY-MM-DD"),
    endDate: moment(moment()).format("YYYY-MM-DD"),
  },
  deviceEventDateLabel: "Last 7 Days",
  influencerUserDateLabel: "Last 7 Days",
  allInfluencerEventData: [],
  totalInfluencerEventCount: 0,
  deviceEventDetailsData: [],
  totalDeviceEventCount: 0,
};
export const analyzeReducers = (state = initialState, action) => {
  switch (action.type) {
    case ANALYZE_REDUCER.SHOW_INFLUENCER_DETAILS:
      return { ...state, showInfluencerDetails: action.value };
    case ANALYZE_REDUCER.SET_INFLUENCER_DETAILS_ON_CLICK:
      return { ...state, onInfluencerDetailClick: action.value };
    case ANALYZE_REDUCER.SET_CITIES_DATA:
      return { ...state, citiesArray: action.value };
    case ANALYZE_REDUCER.SET_COUNTRIES_DATA:
      return { ...state, countriesArray: action.value };
    case ANALYZE_REDUCER.SET_ON_CLICK_DONUT_GRAPH:
      return { ...state, onClickDonutGraph: action.value };
    case ANALYZE_REDUCER.SET_DEVICE_EVENT_DETAILS_DATA:
      return { ...state, deviceEventDetailsData: action.value };
    case ANALYZE_REDUCER.SET_TOTAL_DEVICE_EVENT_COUNT:
      return { ...state, totalDeviceEventCount: action.value };
    case ANALYZE_REDUCER.SELECTED_MATRIX_VALUE:
      return { ...state, selectedEventMatrixValue: action.value };
    case ANALYZE_REDUCER.SET_CITIES_DATE_RANGE:
      return { ...state, citiesDateRange: action.value };
    case ANALYZE_REDUCER.SET_COUNTRY_DATE_RANGE:
      return { ...state, countryDateRange: action.value };
    case ANALYZE_REDUCER.SET_INFLUENCER_DATE_RANGE:
      return { ...state, influencerDateRange: action.value };
    case ANALYZE_REDUCER.SET_DEVICE_EVENT_DATE_RANGE:
      return { ...state, deviceEventDateRange: action.value };
    case ANALYZE_REDUCER.SET_DEVICE_EVENT_DATE_LABEL:
      return { ...state, deviceEventDateLabel: action.value };
    case ANALYZE_REDUCER.SET_SELECT_EVENT_MATRIX_ERROR:
      return { ...state, selectEventError: action.value };
    case ANALYZE_REDUCER.SET_INFL_TOTAL_COUNT:
      return { ...state, influTotalCount: action.value };
    case ANALYZE_REDUCER.DEFAULT_EVENT_VALUE:
      return { ...state, defaultEventMatrixValue: action.value };
    case ANALYZE_REDUCER.SET_INFLUENCER_USER_DATE_LABEL:
      return { ...state, influencerUserDateLabel: action.value };
    case ANALYZE_REDUCER.SET_INFLUENCER_USER_DATE_RANGE:
      return { ...state, influencerUserDateRange: action.value };
    case ANALYZE_REDUCER.SET_TOTAL_INFLUE_EVENT_MATRIX:
      return { ...state, totalInfluencerEventCount: action.value };
    case ANALYZE_REDUCER.SET_ALL_INFLUENCER_EVENT_DATA:
      return { ...state, allInfluencerEventData: action.value };
    case ANALYZE_REDUCER.SET_INFLUENCER_EVENT_ARRAY: {
      if (action.value.length === 0) {
        return { ...state, influencerEventArray: [] };
      }
      return {
        ...state,
        influencerEventArray: [
          {
            influencerDetails: action?.value?.influencer,
            value: action?.value?.count,
            name: action?.value?.influencer?.name,
            eventName: action?.eventName,
          },
          ...state.influencerEventArray,
        ],
      };
    }

    default:
      return state;
  }
};
