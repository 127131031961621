import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserRequestedListAction } from "../../../actions/AdminSetting/adminSetting";
import AdminPanel from "./AdminPanel";

const AdminSetting = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserRequestedListAction());
  }, [dispatch]);

  return (
    <>
      <AdminPanel />
    </>
  );
};

export default AdminSetting;
