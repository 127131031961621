import { COMMON_ACTION_REDUCER } from "../constants/actionConstants";

export const initialState = {
  selectDateRange: "Last 7 Days",
  influencerDateLabel: "Last 7 Days",
  citiesDateLabel: "Last 7 Days",
  countriesDateLabel: "Last 7 Days",
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_ACTION_REDUCER.SET_SELECT_DATE_RANGE:
      return { ...state, selectDateRange: action.value };
    case COMMON_ACTION_REDUCER.SET_CITIES_LABEL_DATE_RANGE:
      return { ...state, citiesDateLabel: action.value };
    case COMMON_ACTION_REDUCER.SET_COUNTRIES_LABEL_DATE_RANGE:
      return { ...state, countriesDateLabel: action.value };
    case COMMON_ACTION_REDUCER.SET_INFLUENCER_LABEL_DATE_RANGE:
      return { ...state, influencerDateLabel: action.value };
    default:
      return state;
  }
};
