import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { useSelector } from "react-redux";
import ovonts from "../../../images/ovonts.png";
import oLogo from "../../../images/oLogo.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const ref = useRef();
  const { campaignById } = useSelector((state) => state.dashboardReducers);
  const [selectedValue, setSelectedValue] = useState({});
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);

  useEffect(() => {
    setSelectedValue({
      value: campaignById._id,
      label: campaignById.name,
    });
  }, [campaignById]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (toggle && ref.current && !ref.current.contains(e.target)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [toggle]);

  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            {/* <svg
              className="logo-abbr"
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5 0C12.3122 0 0 12.3122 0 27.5C0 42.6878 12.3122 55 27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0ZM28.0092 46H19L19.0001 34.9784L19 27.5803V24.4779C19 14.3752 24.0922 10 35.3733 10V17.5571C29.8894 17.5571 28.0092 19.4663 28.0092 24.4779V27.5803H36V34.9784H28.0092V46Z"
                fill="url(#paint0_linear)"
              />
              <defs></defs>
            </svg> */}
            <img width="55" height="55" src={ovonts} alt="oLogo" />
            <div className="brand-title">
              <h2 className="">Ovonts.</h2>
              {/* <span className="brand-sub-title">
                {userDetails?.companyName}
              </span> */}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <img
              width="65"
              height="45"
              src={oLogo}
              alt="oLogo"
              className="logo-abbr"
            />

            <div className="row brand-title">
              <img src={ovonts} alt="ovontsLogo" />
            </div>
          </Fragment>
        )}
      </Link>
      {/* <div>
          <span className={toggle ? "toogleHeaderDropDownList" : ""}>
            <h4>Campaign Name</h4>
          </span>
          <Select
            defaultValue={options[1]}
            options={options}
            // value={selectedValue}
            style={{
              lineHeight: "40px",
              color: "#7e7e7e",
              paddingLeft: " 15px",
              position: "relative",
              zIndex: "1000",
            }}
            onChange={(e) => handleSelectChange(e)}
          />
        </div> */}
      {/* </div> */}
      <div
        ref={ref}
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
