import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { ValueInputField } from "../../components/Comman/ReadOnlyInputField";
import { getUserProfileDetailsRequest } from "../../../actions/AdminSetting/adminSetting";
import { useDispatch, useSelector } from "react-redux";
// import { updateUserProfileRequest } from "../../../actions/Register/registerAction";
import { useHistory } from "react-router-dom";

const UserDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [images, setImages] = useState(
  //   "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
  // );
  const { campaignById } = useSelector((state) => state.dashboardReducers);
  const { email } = useSelector((state) => state.loginReducer);
  const { userDetails } = useSelector((state) => state.adminReducer);

  useEffect(() => {
    dispatch(getUserProfileDetailsRequest({ email }, history));
  }, [dispatch, email, history]);

  useEffect(() => {
    // const profileArray = userDetails?.profileImg?.data;
    // const base64String = btoa(
    //   String.fromCharCode(...new Uint8Array(profileArray))
    // );
    // const base64String = new Buffer.from(profileArray).toString("base64");
    // console.log("SET IMAGES--->", `data:image/png;base64,${base64String}`);
    // console.log(
    //   "DTATTAA-->",
    //   `data:${userDetails?.profileImg?.type};base64, ${Buffer.from(
    //     profileArray
    //   ).toString("base64")}`
    // );
    // const stringURL = `data:${
    //   userDetails?.profileImg?.type
    // };base64, ${Buffer.from(profileArray).toString("base64")}`;
    // setImages(stringURL);
  }, [userDetails]);

  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  // const onImageChange = async (e) => {
  //   const file = e.target.files[0];
  //   const base64 = await convertBase64(file);

  //   console.log("BASE64", base64);
  //   if (e.target.files && e.target.files[0]) {
  //     setImages(URL.createObjectURL(e.target.files[0]));
  //   }
  //   const data = {
  //     name: userName,
  //     profileImg: base64,
  //   };
  //   dispatch(updateUserProfileRequest(data));
  // };

  return (
    <>
      {Object.keys(campaignById).length === 0 ? (
        <div>Add Campaign</div>
      ) : (
        <Card>
          <Card.Body>
            <Row>
              <Col className="d-flex justify-content-center">
                <div className="row mb-5">
                  <div className="small-12 medium-2 large-2 columns">
                    {/* <div>
                      <div class="circle">
                        <img class="profile-pic" src={images} />
                      </div>
                      <div class="p-image">
                        <label htmlFor="icon-button-file">
                          <i class="fa fa-camera upload-button"></i>
                        </label>
                        <input
                          id="icon-button-file"
                          class="file-upload"
                          type="file"
                          accept="image/*"
                          onChange={onImageChange}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </Col>

              <Col style={{ marginLeft: "20px" }} lg={12}>
                <Row>
                  <Col>
                    <ValueInputField
                      labelName="Name"
                      value={userDetails?.name}
                    />
                  </Col>
                  <Col>
                    <ValueInputField labelName="Role" value="Admin" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ValueInputField
                      labelName="Email"
                      value={userDetails?.email}
                    />
                  </Col>
                  <Col>
                    <ValueInputField
                      labelName="Campaign Name"
                      value={userDetails?.companyId?.name}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default UserDetails;
