import React from "react";
import { Spinner } from "react-bootstrap";

const ReactSpinner = () => {
  return (
    <div className="spinner">
      <Spinner animation="grow" style={{ marginRight: "8px" }} />
      <Spinner animation="grow" style={{ marginRight: "8px" }} />
      <Spinner animation="grow" style={{ marginRight: "8px" }} />
    </div>
  );
};

export default ReactSpinner;
