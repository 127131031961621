import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import CustomDateRangePicker from "../Comman/CustomDateRangePicker";
import EventCard from "./EventCard";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getTotalEventCountMatrixRequest } from "../../../actions/Dashboard/dashboardAction";
import { getAllInfluencerEventRequest } from "../../../actions/Analyze/analyzeAction";

const EventCardList = (props) => {
  const {
    campaignById,
    dateRange,
    onInfluencerDetailClick,
    showToggle,
    totalCount,
    isInfluencerDetails,
  } = props;

  const [graphData, setGraphData] = useState([]);
  const { campaignEventMatrix } = useSelector(
    (state) => state.dashboardReducers
  );

  const { allInfluencerEventData, influencerUserDateRange } = useSelector(
    (state) => state.analyzeReducers
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    if (isInfluencerDetails) {
      const data = {
        campaignId: campaignById._id,
        startDate: influencerUserDateRange.startDate,
        endDate: influencerUserDateRange.endDate,
        influencerId: onInfluencerDetailClick._id,
      };
      dispatch(getAllInfluencerEventRequest(data));
    } else {
      const data = {
        campaignId: campaignById._id,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };
      dispatch(getTotalEventCountMatrixRequest(data, history));
    }
  }, [campaignById, dateRange, influencerUserDateRange]);

  useEffect(() => {
    if (isInfluencerDetails) {
    } else {
      const data = {
        campaignId: campaignById._id,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };
      const timer = setTimeout(
        () => dispatch(getTotalEventCountMatrixRequest(data, history)),
        5000
      );
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    setGraphData(campaignEventMatrix);
  }, [campaignEventMatrix]);

  useEffect(() => {
    setGraphData(allInfluencerEventData);
  }, [allInfluencerEventData]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const style = {
    display: "flex",
    justifyContent: "end",
    marginTop: "-1rem",
  };
  return (
    <div className="row d-flex ">
      {showToggle && (
        <Switch
          {...label}
          defaultChecked
          color="default"
          onChange={handleChange}
        />
      )}

      <Row className="d-flex justify-content-start">
        <div style={style}>
          <Col sm={4} xl={3} style={{ padding: "0px" }}>
            <CustomDateRangePicker
              campaignById={campaignById}
              isInfluencerDetails={isInfluencerDetails}
            />
          </Col>
        </div>
        <Col lg={3} xl={3} sm={3} style={{ marginBottom: "1rem" }}>
          <div className="total-count">
            <span className="fs-16 font-w400">Total Count</span>
            <h2 className="fs-24 font-w600">{totalCount}</h2>
          </div>
        </Col>
      </Row>
      {graphData?.map((obj) => (
        <EventCard
          labelName={obj?.eventName}
          count={obj?.count}
          campaignById={campaignById}
        />
      ))}
    </div>
  );
};
export default EventCardList;
