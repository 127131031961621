import React, { Fragment } from "react";
import { Row, Col, Card } from "react-bootstrap";
import PageBreadcrumbsTitle from "../Comman/PageBreadcrumbsTitle";

const AdsManagers = () => {
  return (
    <Fragment>
      <div className="mt-1">
        <PageBreadcrumbsTitle motherMenu={"Publish"} />
        <Card>
          <Card.Body>
            <Row>
              <Col lg={3} xl={3}>
                <label>
                  <h5>Facebook Ad Maanger</h5>
                </label>
              </Col>
              <Col>
                <a
                  className="a-button"
                  href="https://www.facebook.com/index.php?next=https%3A%2F%2Fwww.facebook.com%2Fadsmanager"
                  target="_blank"
                  rel="noreferrer"
                  style={{ width: "30%" }}
                >
                  <span
                    style={{
                      color: "white",
                      fontWeight: "400",
                      fontSize: "18px",
                    }}
                  >
                    Publish
                  </span>
                </a>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
};

export default AdsManagers;
