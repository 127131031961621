import { LOGIN_REDUCER } from "../../constants/actionConstants";

export const setEmail = (data) => {
  return {
    type: LOGIN_REDUCER.SET_EMAIL,
    value: data,
  };
};

export const setPassword = (data) => {
  return {
    type: LOGIN_REDUCER.SET_PASSWORD,
    value: data,
  };
};

export const setResetError = (type) => {
  return {
    type,
    value: "",
  };
};

export const setUserDetails = (data) => {
  return {
    type: LOGIN_REDUCER.SET_USER_DETAILS,
    value: data,
  };
};

export const setAuthToken = (data) => {
  return {
    type: LOGIN_REDUCER.SET_AUTH_TOKEN,
    value: data,
  };
};

export const setLoginMessage = (data) => {
  return {
    type: LOGIN_REDUCER.LOGIN_MESSAGE,
    value: data,
  };
};

export const loginRequest = (data, history) => {
  return {
    type: LOGIN_REDUCER.LOGIN_REQUEST,
    value: data,
    history,
  };
};

export const loginRequestFail = (data) => {
  return {
    type: LOGIN_REDUCER.LOGIN_REQUEST_FAILED,
    value: data,
  };
};

export const setEmailError = (data) => {
  return {
    type: LOGIN_REDUCER.SET_EMAIL_ERROR,
    value: data,
  };
};

export const setPasswordError = (data) => {
  return {
    type: LOGIN_REDUCER.SET_PASSWORD_ERROR,
    value: data,
  };
};

export const resetLoginDetails = () => ({
  type: LOGIN_REDUCER.RESET_LOGIN_DETAILS,
  payload: {
    type: LOGIN_REDUCER.RESET_LOGIN_DETAILS,
  },
});

export const setUserCompanyId = (data) => {
  return {
    type: LOGIN_REDUCER.SET_COMPANY_ID,
    value: data,
  };
};

export const setUserName = (data) => {
  return {
    type: LOGIN_REDUCER.SET_USER_NAME,
    value: data,
  };
};

export const setUserPermissions = (data) => {
  return {
    type: LOGIN_REDUCER.SET_USER_PERMISSIONS,
    value: data,
  };
};
