import { COMMON_ACTION_REDUCER } from "../../constants/actionConstants";

export const setSelectDateRange = (data) => {
  return {
    type: COMMON_ACTION_REDUCER.SET_SELECT_DATE_RANGE,
    value: data,
  };
};

export const setInfluencerDateLabelRange = (data) => {
  return {
    type: COMMON_ACTION_REDUCER.SET_INFLUENCER_LABEL_DATE_RANGE,
    value: data,
  };
};

export const setCitiesSelectLabelRange = (data) => {
  return {
    type: COMMON_ACTION_REDUCER.SET_CITIES_LABEL_DATE_RANGE,
    value: data,
  };
};

export const setCountriesLabelRange = (data) => {
  return {
    type: COMMON_ACTION_REDUCER.SET_COUNTRIES_LABEL_DATE_RANGE,
    value: data,
  };
};
