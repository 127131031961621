import React, { Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  InputField,
  MultiSelectInputField,
  ReactTagInputField,
  InfluenerDetails,
  CountriesDetails,
  DoubleInputField,
} from "../../Comman/ReadOnlyInputField";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import {
  setCampaignName,
  setCampaignBuget,
  setCampaignEndDate,
  setCampaignStartDate,
  setCampaignGoal,
  setCampaignEvents,
  setAudienceAge,
  setAudienceGender,
  setAudienceIntrest,
  setAudiencePlatform,
  setHashTag,
  setInfluencerDetails,
  setAreaOfImpact,
  createCampaignRequest,
  updateCampaignRequest,
} from "../../../../actions/Campaign/addCampaignAction";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignListRequest } from "../../../../actions/Dashboard/dashboardAction";

const StepFourDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    prevStep,
    isUpdateCampaignClick,
    campaignId,
    setFormTitle,
    stepOneDetails,
    stepTwoDetails,
    setCurrentStep,
    influArray,
    hashTags,
    countriesArray,
  } = props;
  const { campaignList } = useSelector((state) => state.dashboardReducers);
  const { isCampaignNamePresent } = useSelector(
    (state) => state.addCampaignReducers
  );

  useEffect(() => {
    setCurrentStep(4);
  });

  useEffect(() => {
    setFormTitle("Click on Submit aftering verifying");
  }, [campaignList, isUpdateCampaignClick, campaignId]);

  const handleOnSubmit = () => {
    dispatch(setCampaignName(stepOneDetails.nameCamp));
    dispatch(
      setCampaignBuget({
        min: stepOneDetails.minBuget,
        max: stepOneDetails.maxBuget,
      })
    );
    dispatch(
      setCampaignStartDate(
        Moment(stepOneDetails.startDate).format("YYYY-MM-dd")
      )
    );
    dispatch(
      setCampaignEndDate(Moment(stepOneDetails.endDate).format("YYYY-MM-dd"))
    );
    dispatch(setCampaignGoal(stepOneDetails.goalList));
    dispatch(setCampaignEvents(stepOneDetails.eventList));
    // dispatch(setCampaignTargetLink(stepOneDetails.weblink));
    dispatch(setAudienceAge(stepTwoDetails.audienceAge));
    dispatch(setAudienceGender(stepTwoDetails.audienceGender));
    dispatch(setAudienceIntrest(stepTwoDetails.audienceIntrest));
    dispatch(setAudiencePlatform(stepTwoDetails.platformTags));
    dispatch(setInfluencerDetails(influArray));
    dispatch(setHashTag(hashTags));
    dispatch(setAreaOfImpact(countriesArray));

    const ageGroup = stepTwoDetails.audienceAge.map((data) => {
      return data.value;
    });
    const goals = stepOneDetails.goalList.map((data) => {
      return data.value;
    });
    const gender = stepTwoDetails.audienceGender.map((data) => {
      return data.value;
    });
    const eventsToTrack = stepOneDetails.eventList.map((data) => {
      return data.value;
    });
    const data = {
      name: stepOneDetails.nameCamp,
      startDate: Moment(stepOneDetails.startDate).format("YYYY-MM-DD"),
      endDate: Moment(stepOneDetails.endDate).format("YYYY-MM-DD"),
      hashTags: hashTags,
      budget: {
        min: stepOneDetails.minBuget,
        max: stepOneDetails.maxBuget,
      },
      platform: stepTwoDetails.platformTags,
      ageGroup,
      goals,
      geoAreaOfImpact: countriesArray,
      gender,
      influencers: influArray,
      eventsToTrack,
    };

    if (isUpdateCampaignClick) {
      data.id = campaignId;

      if (Moment().diff(Moment(data.startDate)) > 0) {
        delete data.startDate;
        delete data.budget;
        delete data.hashTags;
        delete data.platform;
        delete data.goals;
        delete data.geoAreaOfImpact;
        delete data.eventsToTrack;
        delete data.gender;
        delete data.influencers;
        delete data.ageGroup;
      }
      // console.log("CAMPAIGNDATA", data);
      if (Moment().diff(Moment(data.startDate)) < 0) {
        delete data.endDate;
      }
      dispatch(updateCampaignRequest(data, history));
    } else {
      dispatch(createCampaignRequest(data, history));
    }
    if (!isCampaignNamePresent) {
      // if (successCampaignResponse) {
      //   notifyTopFullWidth("Successfully create Campaign");
      //   dispatch(getCampaignListRequest(history));
      //   setCurrentStep(1);
      //   dispatch(setCampaignSuccessResponse(false));
      //   history.push("/manageCampaign");
      // } else {
      //notifyTopFullWidthError("Unable to create Campaign");
      dispatch(getCampaignListRequest(history));
      setCurrentStep(1);
      history.push("/manageCampaign");
      // }
    }
  };
  return (
    <Fragment>
      <div className="row">
        <label className="text-label">
          <h1>Campaign Details</h1>
        </label>
        <div className="mb-3 row">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgsressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <InputField
            labelName="Campaign Name"
            value={stepOneDetails.nameCamp}
          />
          <DoubleInputField
            labelNameOne="Min Buget"
            valueOne={stepOneDetails.minBuget}
            labelNameTwo="Max Buget"
            valueTwo={stepOneDetails.maxBuget}
          />
          <MultiSelectInputField
            valueList={stepOneDetails.goalList}
            disabled={true}
            labelName="Goals"
          />
          {/* <InputField
            labelName="Webpage to Target"
            value={stepOneDetails.weblink}
          /> */}
          <MultiSelectInputField
            valueList={stepOneDetails.eventList}
            disabled={true}
            labelName="Events"
          />
        </div>

        <label className="text-label">
          <h1>Audience Details</h1>
        </label>
        <MultiSelectInputField
          valueList={stepTwoDetails.audienceAge}
          disabled={true}
          labelName="Age Group"
        />
        <MultiSelectInputField
          valueList={stepTwoDetails.audienceGender}
          disabled={true}
          labelName="Gender Group"
        />
        <MultiSelectInputField
          valueList={stepTwoDetails.audienceIntrest}
          disabled={true}
          labelName="Intrest"
        />
        <ReactTagInputField
          tagList={stepTwoDetails.platformTags}
          disabled={true}
          labelName="Platform"
        />
        <label className="text-label">
          <h1>Influencer Details</h1>
        </label>
        <ReactTagInputField
          tagList={hashTags}
          disabled={true}
          labelName="Hash Tags"
        />
        {influArray &&
          influArray.map((data, index) => (
            <InfluenerDetails
              name={data.name}
              webpageTotarget={data.webpageTotarget}
              handlerList={data.handle}
            />
          ))}
        <label>
          <h5>Area of Impact</h5>
        </label>
        {countriesArray &&
          countriesArray.map((data, index) => (
            <CountriesDetails
              country={data.country}
              citiesList={data.cities}
              disabled={true}
            />
          ))}
      </div>

      <div
        className="d-inline"
        style={{ textAlign: "right", margin: "1rem", marginTop: "1rem" }}
      >
        <button className="multip-prev-button" onClick={prevStep}>
          Prev
        </button>
        <Button
          className="submit-multi-button"
          type="submit"
          onClick={handleOnSubmit}
        >
          Submit
        </Button>
      </div>
    </Fragment>
  );
};

export default StepFourDetails;
