import { callApi } from "./apiHelper";

export const updateRegistrationStatus = (data) => {
  return callApi(
    "/private/user/updateUserRegistrationStatus",
    "PUT",
    data,
    {},
    {}
  );
};

export const getUserRequestList = (data) => {
  return callApi("/private/user/getRequestedUsersToCompany", "GET", {}, {}, {});
};

export const getUserDetails = (param) => {
  return callApi("/private/user/getUserDetails", "GET", {}, {}, param);
};
