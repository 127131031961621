import React, { Fragment, useEffect } from "react";
import { Row, Card, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import PageBreadcrumbsTitle from "../Comman/PageBreadcrumbsTitle";
import EventCardList from "../Dashboard/EventCardList";
import PageTitle from "../../layouts/PageTitle";
import { setOnClickDonutGraph } from "../../../actions/Analyze/analyzeAction";

const InfluencerDetails = (props) => {
  const {
    onInfluencerDetailClick,
    selectedEventMatrixValue,
    totalInfluencerEventCount,
  } = useSelector((state) => state.analyzeReducers);
  const dispatch = useDispatch();
  const { campaignById, eventMatrixList } = useSelector(
    (state) => state.dashboardReducers
  );

  useEffect(() => {
    dispatch(setOnClickDonutGraph(false));
  }, [dispatch, selectedEventMatrixValue]);

  return (
    <Fragment>
      <PageTitle
        activeMenu={onInfluencerDetailClick?.name}
        motherMenu="Analyze"
      />

      {/* <Button
        variant="Light"
        onClick={() => {
          history.push("/influencerMix");
        }}
      >
        Go Back
      </Button> */}
      <Row className="d-flex flex-wrap">
        <Col xl={4} lg={6} sm={12}>
          <Card>
            <Card.Body>
              <div>
                <div className="profile-photo mb-4">
                  <div className="text-center p-3  ">
                    <h3 className="mt-3 mb-1">
                      {onInfluencerDetailClick && onInfluencerDetailClick.name}
                    </h3>
                    <p className="mb-0">Influencer</p>
                  </div>
                </div>
                <>
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      Web Page To Target
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={onInfluencerDetailClick?.webpageTotarget}
                      readOnly
                      name="Author"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      UTM Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={onInfluencerDetailClick?.utmLink}
                      readOnly
                      name="Author"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      Bitly Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={onInfluencerDetailClick?.shortLink}
                      readOnly
                      name="Author"
                    />
                  </div>

                  {/* <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      Influencer Post Link
                    </label>
                    {onInfluencerDetailClick?.handle?.map((data) => (
                      <input
                        type="text"
                        className="form-control mb-1"
                        defaultValue="Author"
                        readOnly
                        name="Author"
                        placeholder="Author"
                        id="author"
                      />
                    ))}
                  </div> */}
                </>
              </div>
              <Row className="">
                <Col sm={4}>
                  <Button className="button-style">
                    <span className="mb-0">
                      <i
                        className="fab fa-youtube"
                        style={{ color: "red", fontSize: "44px" }}
                      />
                    </span>
                  </Button>
                </Col>
                <Col sm={4}>
                  <Button className="button-style">
                    <span className="mb-0">
                      <i className="fab fa-instagram instaLogo" />
                    </span>
                  </Button>
                </Col>
                <Col sm={3}>
                  <Button className="button-style">
                    <span className="mb-0">
                      <i
                        className="fab fa-pinterest-square"
                        style={{ color: "red", fontSize: "44px" }}
                      />
                    </span>
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <div className="col">
          <Row className="d-flex flex-wrap mt-3">
            <PageBreadcrumbsTitle motherMenu="Commercial statistics" />
            <div style={{ marginTop: "3rem" }}>
              <EventCardList
                eventMatrixList={eventMatrixList}
                campaignById={campaignById}
                influencerValue={true}
                onInfluencerDetailClick={onInfluencerDetailClick}
                showToggle={false}
                totalCount={totalInfluencerEventCount}
                isInfluencerDetails={true}
              />
            </div>
          </Row>
        </div>
      </Row>
    </Fragment>
  );
};

export default InfluencerDetails;
