import { LOGIN_REDUCER } from "../constants/actionConstants";
import { takeLatest, call, put } from "redux-saga/effects";
import login from "../services/loginService";
import { isEmailIdPresent } from "../services/registerService";
import { setAccessTokenFromLocal } from "../services/apiHelper";
import {
  setAuthToken,
  setEmail,
  setUserDetails,
  setPasswordError,
  setEmailError,
  setUserName,
  setUserCompanyId,
  setUserPermissions,
  setLoginMessage,
} from "../actions/Login/loginAction";
import {
  getCampaignListRequest,
  getRecentCampaignRequest,
  setPermissionCampaignList,
} from "../actions/Dashboard/dashboardAction";
import { logoutRequest } from "../actions/Login/logout";
import { resetDashboardDetails } from "../actions/Dashboard/dashboardAction";
import { resetLoginDetails } from "../actions/Login/loginAction";

export function* loginRequestSaga(action) {
  try {
    const isEmailExist = yield call(isEmailIdPresent, action.value.email);
    const data = isEmailExist?.data?.data;
    if (Object.keys(data).length !== 1) {
      if (
        data.isPresent &&
        data.registrationStatus === "COMPANY_SELECTED_FOR_APPROVAL"
      ) {
        yield put(
          setLoginMessage(
            "Your are not allowed to login until Company Accept or Reject your Request!"
          )
        );
        yield action.history.push("/");
      } else if (
        data.isPresent &&
        data.registrationStatus === "COMPANY_REJECTED"
      ) {
        yield put(
          setLoginMessage(
            "Your are not allowed to login, Company Admin Reject Your Request!"
          )
        );
        yield action.history.push("/");
      } else {
        const data = yield call(login, action.value);
        setAccessTokenFromLocal(data.data.data.token);
        yield put(setEmail(data.data.data.user.email));
        yield put(setUserName(data.data.data.user.name));
        yield put(setUserCompanyId(data.data.data.user.companyId));
        const permissions = data.data.data.user.permissions;
        yield put(setUserPermissions(permissions));
        yield put(setUserDetails(data.data.data.user));
        yield put(setAuthToken(data.data.data.token));
        if (Object.keys(permissions.campaigns).length !== 0) {
          yield put(getCampaignListRequest(action.history));
          yield put(getRecentCampaignRequest(action.history));
        } else {
          yield put(setPermissionCampaignList(true));
        }
      }
    } else {
      if (data.isPresent) {
        const data = yield call(login, action.value);
        setAccessTokenFromLocal(data.data.data.token);
        yield put(setEmail(data.data.data.user.email));
        yield put(setUserName(data.data.data.user.name));
        yield put(setUserCompanyId(data.data.data.user.companyId));
        const permissions = data.data.data.user.permissions;
        yield put(setUserPermissions(permissions));
        yield put(setUserDetails(data.data.data.user));
        yield put(setAuthToken(data.data.data.token));
        if (Object.keys(permissions.campaigns).length !== 0) {
          yield put(getCampaignListRequest(action.history));
          yield put(getRecentCampaignRequest(action.history));
        } else {
          yield put(setPermissionCampaignList(true));
        }
      }
    }
  } catch (error) {
    if (error.message === "Network Error")
      yield action.history.push("/page-error-500");
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
    if (error.response) {
      console.log(error.response);
      if (error.response.data.message === "User doesn't exist") {
        yield put(setEmailError(error.response.data.message));
      } else if (error.response.data.message === "Password doesn't match") {
        yield put(setPasswordError(error.response.data.message));
      } else {
        yield action.history.push("/page-error-500");
      }
      yield action.history.push("/");
    }
  }
}

export default function* loginSaga() {
  yield takeLatest(LOGIN_REDUCER.LOGIN_REQUEST, loginRequestSaga);
}
