import { CAMPAIGN_DETAILS } from "../constants/actionConstants";

export const initialState = {
  showAddCampaignForm: false,
  campaignName: null,
  campaignBuget: {
    min: null,
    max: null,
  },
  campaignStartDate: null,
  campaignEndDate: null,
  campaignGoal: [],
  campaignEvents: [],
  campaignTargetLink: null,
  audienceAge: [],
  audienceGender: [],
  audienceIntrest: [],
  audiencePlatform: [],
  influencerDetails: [],
  hashTag: [],
  areaOfImpact: [],
  isUpdateCampaignClick: false,
  showInfluencerDetails: false,
  isCampaignNamePresent: false,
  showEditCampaignForm: false,
  successCampaignResponse: false,
  successDeleteResponse: false,
};

export const addCampaignReducers = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_DETAILS.SHOW_ADD_CAMPAIGN_FORM:
      return { ...state, email: action.value };
    case CAMPAIGN_DETAILS.ADD_CAMPAIGN_NAME:
      return { ...state, campaignName: action.value };
    case CAMPAIGN_DETAILS.ADD_CAMPAIGN_BUGET:
      return { ...state, campaignBuget: action.value };
    case CAMPAIGN_DETAILS.ADD_CAMPAIGN_START_DATE:
      return { ...state, campaignStartDate: action.value };
    case CAMPAIGN_DETAILS.ADD_CAMPAIGN_END_DATE:
      return { ...state, campaignEndDate: action.value };
    case CAMPAIGN_DETAILS.ADD_CAMPAIGN_GOAL:
      return { ...state, campaignGoal: action.value };
    case CAMPAIGN_DETAILS.ADD_CAMPAIGN_TARGET_LINK:
      return { ...state, campaignTargetLink: action.value };
    case CAMPAIGN_DETAILS.IS_CAMPAIGN_NAME_PRESENT:
      return { ...state, isCampaignNamePresent: action.value };
    case CAMPAIGN_DETAILS.ADD_CAMPAIGN_EVENT:
      return { ...state, campaignEvents: action.value };
    case CAMPAIGN_DETAILS.ADD_AUDIENCE_AGE:
      return { ...state, audienceAge: action.value };
    case CAMPAIGN_DETAILS.ADD_AUDIENCE_GENDER:
      return { ...state, audienceGender: action.value };
    case CAMPAIGN_DETAILS.ADD_AUDIENCE_INTREST:
      return { ...state, audienceIntrest: action.value };
    case CAMPAIGN_DETAILS.ADD_AUDIENCE_PLATFORM:
      return { ...state, audiencePlatform: action.value };
    case CAMPAIGN_DETAILS.ADD_INFLUENCER_DETAILS:
      return { ...state, influencerDetails: action.value };
    case CAMPAIGN_DETAILS.SET_EDIT_CAMPAIGN_FORM:
      return { ...state, showEditCampaignForm: action.value };
    case CAMPAIGN_DETAILS.ADD_HASH_TAG:
      return { ...state, hashTag: action.value };
    case CAMPAIGN_DETAILS.SHOW_INFLUENCER_DETAILS:
      return { ...state, showInfluencerDetails: action.value };
    case CAMPAIGN_DETAILS.ADD_AREA_OF_IMPACT:
      return { ...state, areaOfImpact: action.value };
    case CAMPAIGN_DETAILS.IS_UPDATE_CAMPAIGN_CLICK:
      return { ...state, isUpdateCampaignClick: action.value };
    case CAMPAIGN_DETAILS.SUCCESS_DELETE_RESPONSE:
      return { ...state, successDeleteResponse: action.value };
    case CAMPAIGN_DETAILS.SUCCESS_CAMPAIGN_RESPONSE:
      return { ...state, successCampaignResponse: action.value };
    case CAMPAIGN_DETAILS.RESET_CAMPAIGN_DETAILS:
      return { ...initialState };

    default:
      return state;
  }
};
