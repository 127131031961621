import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Card, Tab } from "react-bootstrap";
import UserListTab from "./UserListTab";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { resetLoginDetails } from "../../../actions/Login/loginAction";
import { resetDashboardDetails } from "../../../actions/Dashboard/dashboardAction";
import { callApi } from "../../../services/apiHelper";
import { logoutRequest } from "../../../actions/Login/logout";
import { getUserRequestedListAction } from "../../../actions/AdminSetting/adminSetting";

const AdminPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { userRequestedList } = useSelector((state) => state.adminReducer);
  const [refreshData, setRefreshData] = useState(false);
  const [listData, setListData] = useState();

  useEffect(() => {
    dispatch(getUserRequestedListAction());
  }, [refreshData, dispatch]);

  useEffect(() => {
    setListData([...userRequestedList]);
  }, [userRequestedList]);

  const fetchUserRequestList = async () => {
    const getData = await callApi(
      "/private/user/getRequestedUsersToCompany",
      "GET",
      {},
      {},
      {}
    )
      .then((data) => {
        if (data) {
          setIsDataLoaded(true);
          setListData(data.data.data);
        }
      })
      .catch((error) => {
        if (
          error.response.status === 401 ||
          error.response.data.message.contains("Token is invalid")
        ) {
          dispatch(resetLoginDetails());
          dispatch(resetDashboardDetails());
          logoutRequest(history);
        }
      });
    return getData;
  };

  useQuery("fetchUserRequestList", () => fetchUserRequestList());
  return (
    <Fragment>
      <Row>
        <Col className="col-lg-12">
          <Card>
            <Card.Body>
              <Row>
                <Tab.Container
                  defaultActiveKey="manageAccess"
                  // onSelect={(e) => handleOnTabSelect(e)
                  // }
                >
                  {/* <Col sm={11}> */}
                  <Card.Body>
                    <Row>
                      {/* <Col sm={11}> */}
                      {/* <Nav as="div" variant="pills" className="flex-column"> */}
                      {/* <Nav.Item as="a" style={style}>
                              <Nav.Link eventKey="requestUser">
                                Request User
                              </Nav.Link>
                            </Nav.Item> */}
                      {/* <Nav.Item as="a" style={style}>
                              <Nav.Link eventKey="manageAccess">
                                Manage Access
                              </Nav.Link>
                            </Nav.Item> */}
                      {/* <Nav.Item as="a" style={style}>
                              <Nav.Link eventKey="CustomSegment">
                                Custom segment
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="a" style={style}>
                              <Nav.Link eventKey="CustomSegment1">
                                Custom segment 1
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="a" style={style}>
                              <Nav.Link eventKey="CustomSegment2">
                                Custom segment 2
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="a">
                              <Nav.Link eventKey="CustomSegment3" style={style}>
                                Custom segment 3
                              </Nav.Link>
                            </Nav.Item> */}
                      {/* </Nav> */}
                      {/* </Col> */}
                      <Col sm={12}>
                        <UserListTab
                          listData={listData}
                          isDataLoaded={isDataLoaded}
                          setListData={setListData}
                          setRefreshData={setRefreshData}
                          refreshData={refreshData}
                        />
                        {/* <Tab.Content className="">
                            <Tab.Pane eventKey="manageAccess">
                              <UserListTab
                                listData={listData}
                                isDataLoaded={isDataLoaded}
                                setListData={setListData}
                                setRefreshData={setRefreshData}
                                refreshData={refreshData}
                              />
                            </Tab.Pane> */}
                        {/* <Tab.Pane eventKey="manageAccess"></Tab.Pane>
                            <Tab.Pane eventKey="CustomSegment">
                              <CustomSegmentCard
                                setDisableFilter={setDisableFilter}
                              />
                            </Tab.Pane> */}
                        {/* </Tab.Content> */}
                      </Col>
                    </Row>
                  </Card.Body>
                </Tab.Container>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AdminPanel;
