import React, { useEffect } from "react";
const EventCard = (props) => {
  const { labelName, count, size, campaignById } = props;
  useEffect(() => {}, [labelName, count, campaignById]);

  return (
    <div className={size ? `col-lg-6 col-sm-6` : "col-lg-3 col-sm-3"}>
      <div className="card">
        <div className="card-body d-flex px-4  justify-content-between">
          <div>
            <div className="">
              <span className="fs-18 font-w400">
                {(labelName?.charAt(0).toUpperCase() + labelName?.slice(1))
                  .replace(/([A-Z])/g, " $1")
                  .trim()}
              </span>

              <h2 className="fs-32 font-w600 mt-5 mr-2">{count}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
