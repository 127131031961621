import React, { useState } from "react";
import { Table, Row, Col, Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CampaignDetailsTable = (props) => {
  const { influencers } = props;
  const [isCopied, setIsCopied] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => this.setState({ message: "" }), 3000);
  // }, [isCopied]);
  return (
    <>
      <Table responsive striped>
        <thead>
          <tr>
            {/* <th className="width80">
              <strong>#</strong>
            </th> */}
            <th>
              <strong>Name</strong>
            </th>
            <th>
              <strong>UTM Link</strong>
            </th>
            <th>
              <strong>Web page to Target</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {influencers?.map((data) => (
            <tr>
              <td>
                <strong>{data.name}</strong>
              </td>
              <td>
                <Row>
                  <Col style={{ paddingTop: "6px" }}> {data.shortLink}</Col>
                  <Col>
                    <CopyToClipboard
                      text={data.shortLink}
                      onCopy={() => setIsCopied(!isCopied)}
                    >
                      <Button className="btn-xs" variant="light">
                        Copy
                      </Button>
                      {/* <span style={{ color: "#4BB543" }}>{message}</span> */}
                    </CopyToClipboard>
                  </Col>
                </Row>
              </td>
              <td>{data.webpageTotarget}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default CampaignDetailsTable;
