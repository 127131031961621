import { LOGIN_REDUCER } from "../constants/actionConstants";

export const initialState = {
  email: "",
  password: "",
  emailErrorText: "",
  passwordErrorText: "",
  errorMessage: "",
  authToken: null,
  userDetails: {},
  userName: "",
  userPermissions: {},
  companyId: "",
  loginMessage: "",
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REDUCER.SET_EMAIL:
      return { ...state, email: action.value };
    case LOGIN_REDUCER.SET_PASSWORD:
      return { ...state, password: action.value };
    case LOGIN_REDUCER.SET_EMAIL_ERROR:
      return { ...state, emailErrorText: action.value };
    case LOGIN_REDUCER.SET_PASSWORD_ERROR:
      return { ...state, passwordErrorText: action.value };
    case LOGIN_REDUCER.LOGIN_REQUEST_FAILED:
      return { ...state, errorMessage: action.value };
    case LOGIN_REDUCER.SET_USER_DETAILS:
      return { ...state, userDetails: action.value };
    case LOGIN_REDUCER.SET_AUTH_TOKEN:
      return { ...state, authToken: action.value };
    case LOGIN_REDUCER.SET_COMPANY_ID:
      return { ...state, companyId: action.value };
    case LOGIN_REDUCER.SET_USER_NAME:
      return { ...state, userName: action.value };
    case LOGIN_REDUCER.SET_USER_PERMISSIONS:
      return { ...state, userPermissions: action.value };
    case LOGIN_REDUCER.LOGIN_MESSAGE:
      return { ...state, loginMessage: action.value };
    case LOGIN_REDUCER.RESET_LOGIN_DETAILS:
      return { ...initialState };
    default:
      return state;
  }
};
