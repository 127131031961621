import React from "react";
import PageBreadcrumbsTitle from "../Comman/PageBreadcrumbsTitle";
import AdminSetting from "../AdminSetting";
import UserDetails from "./UserDetails";

const AccountDetails = () => {
  return (
    <>
      <PageBreadcrumbsTitle motherMenu={"User Profile"} />
      <UserDetails />
      <PageBreadcrumbsTitle motherMenu={"Manage Access"} />
      <AdminSetting />
    </>
  );
};
export default AccountDetails;
