import { CAMPAIGN_DETAILS } from "../../constants/actionConstants";

export const setShowAddCampaignForm = (data) => {
  return {
    type: CAMPAIGN_DETAILS.SHOW_ADD_CAMPAIGN_FORM,
    value: data,
  };
};

export const setShowInfluencerDetails = (data) => {
  return {
    type: CAMPAIGN_DETAILS.SHOW_INFLUENCER_DETAILS,
    value: data,
  };
};

export const setEditCampaignForm = (data) => {
  return {
    type: CAMPAIGN_DETAILS.SET_EDIT_CAMPAIGN_FORM,
    value: data,
  };
};

export const setCampaignName = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_CAMPAIGN_NAME,
    value: data,
  };
};

export const setCampaignBuget = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_CAMPAIGN_BUGET,
    value: data,
  };
};

export const setCampaignStartDate = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_CAMPAIGN_START_DATE,
    value: data,
  };
};

export const setCampaignEndDate = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_CAMPAIGN_END_DATE,
    value: data,
  };
};

export const setCampaignGoal = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_CAMPAIGN_GOAL,
    value: data,
  };
};

export const setCampaignEvents = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_CAMPAIGN_EVENT,
    value: data,
  };
};

export const setCampaignTargetLink = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_CAMPAIGN_TARGET_LINK,
    value: data,
  };
};

export const setAudienceAge = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_AUDIENCE_AGE,
    value: data,
  };
};

export const setAudienceGender = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_AUDIENCE_GENDER,
    value: data,
  };
};

export const setAudienceIntrest = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_AUDIENCE_INTREST,
    value: data,
  };
};

export const setAudiencePlatform = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_AUDIENCE_PLATFORM,
    value: data,
  };
};

export const setIsCampaignNamePresent = (data) => {
  return {
    type: CAMPAIGN_DETAILS.IS_CAMPAIGN_NAME_PRESENT,
    value: data,
  };
};

export const setInfluencerDetails = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_INFLUENCER_DETAILS,
    value: data,
  };
};

export const setHashTag = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_HASH_TAG,
    value: data,
  };
};

export const setAreaOfImpact = (data) => {
  return {
    type: CAMPAIGN_DETAILS.ADD_AREA_OF_IMPACT,
    value: data,
  };
};

export const createCampaignRequest = (data, history) => {
  return {
    type: CAMPAIGN_DETAILS.CREATE_CAMPAIGN_REQUEST,
    value: data,
    history,
  };
};

export const setIsUpdateCampaignClick = (data) => {
  return {
    type: CAMPAIGN_DETAILS.IS_UPDATE_CAMPAIGN_CLICK,
    value: data,
  };
};

export const setCampaignSuccessResponse = (data) => {
  return {
    type: CAMPAIGN_DETAILS.SUCCESS_CAMPAIGN_RESPONSE,
    value: data,
  };
};

export const updateCampaignRequest = (data, history) => {
  return {
    type: CAMPAIGN_DETAILS.UPDATE_CAMPAIGN_REQUEST,
    value: data,
    history,
  };
};

export const deleteCampaignRequest = (data, history) => {
  return {
    type: CAMPAIGN_DETAILS.DELETE_CAMPAIGN_REQUEST,
    value: data,
    history,
  };
};

export const setSuccessDeleteResponse = (data, history) => {
  return {
    type: CAMPAIGN_DETAILS.SUCCESS_DELETE_RESPONSE,
    value: data,
  };
};
