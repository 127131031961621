import { lazy, Suspense } from "react";
/// Components
import Index from "./jsx";
import { useSelector } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
// action
import Loader from "./jsx/components/Comman/Loader";
import PublicRoutes from "./routes/PublicRoutes";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

const SignUp = lazy(() => import("./jsx/components/Registration"));
const UserRegistrationDetail = lazy(() =>
  import("./jsx/components/Registration/UserRegistrationDetails")
);
const CompanyListDetails = lazy(() =>
  import("./jsx/components/Registration/CompanyListDetails")
);
const JoinCompanyCard = lazy(() =>
  import("./jsx/components/Registration/JoinCompanyCard")
);
const RegistrationComplete = lazy(() =>
  import("./jsx/components/Registration/RegistrationCompleteCard")
);
const CompanyCreationCard = lazy(() =>
  import("./jsx/components/Registration/CompanyCreationCard")
);
const Error500 = lazy(() => import("./jsx/pages/Error500"));
const Error400 = lazy(() => import("./jsx/pages/Error400"));
const ForgotPassword = lazy(() =>
  import("./jsx/components/Registration/ForgotPassword")
);
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("./jsx/containers/Login/LoginContainer")),
      500
    );
  });
});

const App = (props) => {
  const authTokenAccess = localStorage.getItem("accessToken");
  const { authToken } = useSelector((state) => state.loginReducer);

  // useEffect(() => {
  //   checkAutoLogin(dispatch, props.history);
  //   console.log("Path---->", props.history);
  //   // if (authToken && window.location.pathname === "/react/demo/login") {
  //   //   props.history.goBack();
  //   // }
  // }, [dispatch, props.history]);

  // let routes = (
  //   <Switch>
  //     <Route path="/login" component={Login} />
  //     <Route path="/page-register" component={SignUp} />
  //     <Route path="/userRegisterDetails" component={UserRegistrationDetail} />
  //     <Route path="/page-forgot-password" component={ForgotPassword} />
  //     <Route path="/companySelection" component={CompanyListDetails} />
  //     <Route path="/joinCompany" component={JoinCompanyCard} />
  //     <Route path="/registrationComplete" component={RegistrationComplete} />
  //     <Route path="/companyCreation" component={CompanyCreationCard} />
  //   </Switch>
  // );

  let routes = (
    <Switch>
      <PublicRoutes exact path="/" isAuthenticated={authToken !== null}>
        <Login />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/page-register"
        isAuthenticated={authTokenAccess !== null}
      >
        <SignUp />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/userRegisterDetails"
        isAuthenticated={authTokenAccess !== null}
      >
        <UserRegistrationDetail />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/page-forgot-password"
        isAuthenticated={authTokenAccess !== null}
      >
        <ForgotPassword />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/joinCompany"
        isAuthenticated={authTokenAccess !== null}
      >
        <JoinCompanyCard />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/companySelection"
        isAuthenticated={authTokenAccess !== null}
      >
        <CompanyListDetails />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/registrationComplete"
        isAuthenticated={authToken !== null}
      >
        <RegistrationComplete />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/companyCreation"
        isAuthenticated={authTokenAccess !== null}
      >
        <CompanyCreationCard />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/page-error-500"
        isAuthenticated={authTokenAccess !== null}
      >
        <Error500 />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/page-error-400"
        isAuthenticated={authTokenAccess !== null}
      >
        <Error400 />
      </PublicRoutes>
      <PublicRoutes
        exact
        path="/forgotPassword"
        isAuthenticated={authTokenAccess !== null}
      >
        <ForgotPassword />
      </PublicRoutes>
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );

  if (authToken) {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={<Loader />}>{routes}</Suspense>
      </div>
    );
  }
};

export default withRouter(App);
