import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import { PersistGate } from "redux-persist/integration/react";
import ThemeContext from "./context/ThemeContext";
import CampaignContextProvider from "./context/CampaignContext";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SimpleReactLightbox>
          <BrowserRouter>
            <ThemeContext>
              <CampaignContextProvider>
                <QueryClientProvider client={queryClient} contextSharing={true}>
                  {/* <ReactQueryCacheProvider queryCache={queryCache}> */}
                  <App />
                  {/* </ReactQueryCacheProvider> */}
                </QueryClientProvider>
              </CampaignContextProvider>
            </ThemeContext>
          </BrowserRouter>
        </SimpleReactLightbox>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
