import React from "react";
import { Button, Row } from "react-bootstrap";

const ButtonComponent = (props) => {
  const { buttonName, buttonLink, key } = props;
  const style = {
    width: "100%",
    margin: "5px",
    color: "white",
    backgroundColor: "#ED3B5B",
    fontSize: "13px",
  };
  return (
    <Button
      style={style}
      key={key}
      onClick={buttonLink}
      // variant="primary tp-btn-light"
    >
      {buttonName}
    </Button>
  );
};

export default ButtonComponent;
