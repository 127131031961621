import React, { Fragment } from "react";
import { Button, Row, Card, CloseButton, Col } from "react-bootstrap";
import Select from "react-select";

const DynamicInputHandleFields = (props) => {
  const { influArray, setInfluArray, formErrors, isSubmitClick } = props;

  const addButtonStyle = {
    fontSize: "15px",
    marginTop: "1.6rem",
  };

  const platfromName = [
    {
      value: "Youtube",
      label: "Youtube",
    },
    {
      value: "Instagram",
      label: "Instagram",
    },
    {
      value: "TikTok",
      label: "TikTok",
    },
    {
      value: "Twitter",
      label: "Twitter",
    },
  ];

  const handleAddFields = (harray, infIndex) => {
    const values = [...harray];
    values.push({ platform: "", platformHandler: "" });
    influArray[infIndex].handle = values;
    setInfluArray([...influArray]);
  };

  const handleAddInfluFields = () => {
    const values = [...influArray];
    values.push({
      name: "",
      webpageTotarget: "",
      handle: [{ platform: "Youtube", platformHandler: "" }],
    });
    setInfluArray([...values]);
  };

  const handleRemoveFields = (harray, infIndex, index) => {
    const values = [...harray];
    values.splice(index, 1);
    influArray[infIndex].handle = values;
    setInfluArray([...influArray]);
  };

  const handleRemoveInfluFields = (infIndex) => {
    const values = [...influArray];
    values.splice(infIndex, 1);
    setInfluArray([...values]);
  };

  const handleInputChange = (index, e, hIndex, handle) => {
    const values = [...influArray];
    if (e.target.name === "name") {
      values[index].name = e.target.value;
    } else if (e.target.name === "webpageTotarget") {
      values[index].webpageTotarget = e.target.value;
    } else {
      const handlerValue = [...handle];
      // if (e.target.name === "platform") {
      //   handlerValue[hIndex].platform = e.target.value;
      // } else {
      handlerValue[hIndex].platformHandler = e.target.value;
      // }
      values[index].handle = handlerValue;
    }
    setInfluArray([...values]);
  };

  const handlePlatform = (index, e, hIndex, handle) => {
    const values = [...influArray];
    const handlerValue = [...handle];
    handlerValue[hIndex].platform = e.value;
    values[index].handle = handlerValue;
    setInfluArray([...values]);
  };

  return (
    <section>
      <div className="row">
        {influArray &&
          influArray.map((data, i) => (
            <Fragment key={`${data}~${i}`}>
              <Card className="shadow p-3 mb-5 bg-white">
                <Card.Body style={{ padding: "0px", paddingTop: "18px" }}>
                  <Col>
                    <CloseButton
                      className="close-button"
                      disabled={influArray.length === 1}
                      onClick={() => handleRemoveInfluFields(i)}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="text-label">Influencer Name*</label>
                          <div
                            className={
                              isSubmitClick &&
                              formErrors["name" + i] === i &&
                              formErrors.name &&
                              "error-form-control"
                            }
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Influencer Name"
                              value={data.name}
                              onChange={(e) => handleInputChange(i, e)}
                            />
                          </div>
                          {isSubmitClick &&
                            formErrors["name" + i] === i &&
                            formErrors.name && (
                              <div className="text-danger fs-12">
                                {formErrors.name}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="text-label">
                            Webpage To Target*
                          </label>
                          <div
                            className={
                              isSubmitClick &&
                              formErrors["webpage" + i] === i &&
                              // !data.webpageTotarget &&
                              formErrors.webpageTotarget &&
                              "error-form-control"
                            }
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="webpageTotarget"
                              name="webpageTotarget"
                              placeholder="http://example.com"
                              value={data.webpageTotarget}
                              onChange={(e) => handleInputChange(i, e)}
                            />
                          </div>
                          {isSubmitClick &&
                            formErrors["webpage" + i] === i &&
                            formErrors.webpageTotarget && (
                              <div className="text-danger fs-12">
                                {formErrors.webpageTotarget}
                              </div>
                            )}
                        </div>
                      </div>
                    </Row>
                    <Row>
                      {data &&
                        data.handle.map((inputField, index) => {
                          return (
                            <div className="col-12">
                              <Fragment key={`${inputField}~${index}`}>
                                <div className="row">
                                  <div className="col-5 col-sm-4">
                                    <div className="form-group mb-3">
                                      <label htmlFor="platform">
                                        Platform Name
                                      </label>
                                      <div
                                        className={
                                          isSubmitClick &&
                                          !inputField.platform &&
                                          formErrors.platform &&
                                          "error-form-control"
                                        }
                                      >
                                        <Select
                                          defaultValue={{
                                            value: "Youtube",
                                            label: "Youtube",
                                          }}
                                          options={platfromName}
                                          className="singleSelectMenu"
                                          id="platform"
                                          name="platform"
                                          value={{
                                            value: inputField.platform,
                                            label: inputField.platform,
                                          }}
                                          style={{
                                            lineHeight: "40px",
                                            color: "#7e7e7e",
                                            position: "relative",
                                            zIndex: "1000",
                                          }}
                                          onChange={(e) =>
                                            handlePlatform(
                                              i,
                                              e,
                                              index,
                                              data.handle
                                            )
                                          }
                                        />
                                      </div>
                                      {isSubmitClick &&
                                        !inputField.platform &&
                                        formErrors.platform && (
                                          <div className="text-danger fs-12">
                                            {formErrors.platform}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-4 col-sm-5">
                                    <div className="form-group mb-3">
                                      <label htmlFor="platformHandler">
                                        Platform Handle
                                      </label>

                                      <div
                                        className={
                                          isSubmitClick &&
                                          formErrors[
                                            "handlerIndex" + i + index
                                          ] ===
                                            i + index &&
                                          "error-form-control"
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="platformHandler"
                                          name="platformHandler"
                                          placeholder="http://example.com or @username"
                                          value={inputField.platformHandler}
                                          onChange={(e) =>
                                            handleInputChange(
                                              i,
                                              e,
                                              index,
                                              data.handle
                                            )
                                          }
                                        />
                                      </div>
                                      {isSubmitClick &&
                                        formErrors[
                                          "handlerIndex" + i + index
                                        ] ===
                                          i + index && (
                                          // formErrors.platformHandler &&
                                          <div className="text-danger fs-12">
                                            {
                                              formErrors[
                                                "platformHandler" + i + index
                                              ]
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-sm-4 col-lg-3 mb-2">
                                    <Button
                                      style={addButtonStyle}
                                      variant="danger tp-btn-light"
                                      className="me-2"
                                      disabled={data.handle.length === 1}
                                      onClick={() =>
                                        handleRemoveFields(
                                          data.handle,
                                          i,
                                          index
                                        )
                                      }
                                    >
                                      <span className="">
                                        <i className="fa fa-minus color-danger" />
                                      </span>
                                    </Button>
                                    <Button
                                      style={addButtonStyle}
                                      variant="danger tp-btn-light"
                                      className="me-1"
                                      onClick={() =>
                                        handleAddFields(data.handle, i)
                                      }
                                    >
                                      <span className="">
                                        <i className="fa fa-plus" />
                                      </span>
                                    </Button>
                                  </div>
                                </div>
                              </Fragment>
                            </div>
                          );
                        })}
                      {/* 
                    <div className="position-absolute top-0 end-0">
                      <div
                        className="position-absolute top-0 end-0"
                        style={{ marginTop: "-18px", marginRight: "21px" }}
                      >
                        <Button
                          className="me-2"
                          // style={addButtonStyle}
                          variant="danger btn-sm"
                          disabled={influArray.length === 1}
                          onClick={() => handleRemoveInfluFields(i)}
                        >
                          <span className="btn-icon-start text-danger">
                            <i className="fa fa-minus color-danger" />
                          </span>
                          Remove
                        </Button>
                        <Button
                          className="me-2"
                          // style={addButtonStyle}
                          variant="success btn-sm"
                          onClick={() => handleAddInfluFields()}
                        >
                          <span className="btn-icon-start text-success">
                            <i className="fa fa-plus color-success" />
                          </span>
                          Add
                        </Button>
                      </div>
                    </div> */}
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
            </Fragment>
          ))}
      </div>
      <div className="close-button">
        <div className="" style={{ marginTop: "-18px", marginRight: "21px" }}>
          <Button
            className="me-2"
            style={addButtonStyle}
            onClick={() => handleAddInfluFields()}
          >
            + Add
          </Button>
        </div>

        {/* this above button is for testing purpose  */}
        {/* <Button className="me-2" variant="primary" onClick={handleSubmit}>
          Save
        </Button> */}
        {/* <Button className="me-2" variant="warning" onClick={resetForm}>
          Reset Values
        </Button> */}
      </div>
    </section>
  );
};

export default DynamicInputHandleFields;
