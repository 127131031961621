import { DASHBOARD_REDUCER } from "../../constants/actionConstants";

export const setSelectedCampaign = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_SELECTED_CAMPAIGN,
    value: data,
  };
};

export const setDashboardLoading = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_DASHBOARD_LOADING,
    value: data,
  };
};

export const setCampaignList = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_CAMPAIGN_LIST,
    value: data,
  };
};

export const setIsCampaignSummary = (data) => {
  return {
    type: DASHBOARD_REDUCER.IS_CAMPAIGN_SUMMARY,
    value: data,
  };
};

export const setDashboardDateRange = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_DASHBOARD_DATE_RANGE,
    value: data,
  };
};

export const setPermissionCampaignList = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_CAMPAIGN_LIST_PERMISSION,
    value: data,
  };
};

export const setOnSuccessCampaignList = (data) => {
  return {
    type: DASHBOARD_REDUCER.SUCCESS_CAMPAIGN_LIST,
    value: data,
  };
};

export const setOnSuccessSelectCampaign = (data) => {
  return {
    type: DASHBOARD_REDUCER.SUCCESS_SELECTED_CAMPAIGN,
    value: data,
  };
};

export const getCampaignListRequest = (history) => {
  return {
    type: DASHBOARD_REDUCER.CAMPAIGN_LIST_REQUEST,
    history,
  };
};
export const getRecentCampaignRequest = (history) => {
  return {
    type: DASHBOARD_REDUCER.RECENT_CAMPAIGN_REQUEST,
    history,
  };
};
export const getCampaignByIdRequest = (campaignId, history) => {
  return {
    type: DASHBOARD_REDUCER.GET_CAMPAIGN_BY_ID,
    campaignId,
    history,
  };
};

export const setInfluencerArray = (data, eventName) => {
  return {
    type: DASHBOARD_REDUCER.SET_INFLUENCER_ARRAY,
    value: data,
    eventName,
  };
};

export const setEventCountArray = (data, eventName) => {
  return {
    type: DASHBOARD_REDUCER.SET_EVENT_MATRIX_ARRAY,
    value: data,
    eventName,
  };
};

export const getEventMartixInfluencerRequest = (data, history) => {
  return {
    type: DASHBOARD_REDUCER.GET_EVENT_MATRIX_INFLUENCER_DATA,
    data,
    history,
  };
};

export const getInfluencerCitiesRequest = (data, history) => {
  return {
    type: DASHBOARD_REDUCER.GET_INFLUENCER_CITIES_DETAILS,
    data,
    history,
  };
};

export const setCampaignByIdData = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_CAMPAIGN_BY_ID,
    value: data,
  };
};
export const setEventMatrixList = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_EVENT_MATRIX_LIST,
    value: data,
  };
};

export const resetDashboardDetails = () => ({
  type: DASHBOARD_REDUCER.RESET_DASHBOARD_DETAILS,
  payload: {
    type: DASHBOARD_REDUCER.RESET_DASHBOARD_DETAILS,
  },
});

export const setCampaignEventMatrix = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_CAMPAIGN_EVENT_MATRIX,
    value: data,
  };
};

export const setTotalEventMatrixCount = (data) => {
  return {
    type: DASHBOARD_REDUCER.SET_TOTAL_EVENT_MATRIX_COUNT,
    value: data,
  };
};

export const getTotalEventCountMatrixRequest = (data, history) => {
  return {
    type: DASHBOARD_REDUCER.CAMPAIGN_EVENT_MATRIX_REQUEST,
    data,
    history,
  };
};
