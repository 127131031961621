import React from "react";
import { Link } from "react-router-dom";

const PageBreadcrumbsTitle = ({ motherMenu }) => {
  return (
    <div className="row page-titles mx-0">
      <ol className="breadcrumb">
        <li className="breadcrumb-item active">
          <Link to="#">{motherMenu}</Link>
        </li>
        {/* <li className="breadcrumb-item">
          <Link to={onActiveMenuClick}>{activeMenu}</Link>
        </li> */}
      </ol>
    </div>
  );
};

export default PageBreadcrumbsTitle;
