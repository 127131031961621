import { SEGMENTATION_REDUCER } from "../../constants/actionConstants";

export const setQueryCount = (data) => {
  return {
    type: SEGMENTATION_REDUCER.SET_QUERY_COUNT_NUMBER,
    value: data,
  };
};

export const setDownlaodUrlLink = (data) => {
  return {
    type: SEGMENTATION_REDUCER.SET_DOWNLOAD_URL_LINK,
    value: data,
  };
};

export const getSegmentListByCampaignRequest = (data) => {
  return {
    type: SEGMENTATION_REDUCER.GET_SEGMENT_LIST_BY_CAMPAIGN,
    data,
  };
};

export const setRecentSegmentData = (data) => {
  return {
    type: SEGMENTATION_REDUCER.SET_RECENT_SEGMENT_DATA,
    value: data,
  };
};
export const setFetchDataArray = (data) => {
  return {
    type: SEGMENTATION_REDUCER.SET_FETCH_DATA_ARRAY,
    value: data,
  };
};

export const getQueryCountRequestAction = (data) => {
  return {
    type: SEGMENTATION_REDUCER.GET_QUERY_COUNT_REQUEST,
    data,
  };
};

export const setIsFetchDataLoaded = (data) => {
  return {
    type: SEGMENTATION_REDUCER.IS_FETCH_DATA_LOADED,
    data,
  };
};

export const fetchQueryRequestDataAction = (data) => {
  return {
    type: SEGMENTATION_REDUCER.FETCH_QUERY_REQUEST,
    data,
  };
};

export const downloadUrlRequest = (data) => {
  return {
    type: SEGMENTATION_REDUCER.DOWNLOAD_FETCH_URL,
    data,
  };
};

export const setIsQueryLoaded = (value) => {
  return {
    type: SEGMENTATION_REDUCER.SET_IS_QUERY_LOADED,
    value,
  };
};

export const setQueryCountRequestError = (value) => {
  return {
    type: SEGMENTATION_REDUCER.SET_QUERY_COUNT_REQUEST_ERROR,
    value,
  };
};

export const setSegmentListByCampaign = (value) => {
  return {
    type: SEGMENTATION_REDUCER.SET_SEGMENT_LIST_BY_CAMPAIGN,
    value,
  };
};

export const setUserPropertyList = (value) => {
  return {
    type: SEGMENTATION_REDUCER.SET_USER_PROPERTY_FIELD_LIST,
    value,
  };
};

export const setShowSegmentData = (value) => {
  return {
    type: SEGMENTATION_REDUCER.SET_SHOW_SEGMENT_DATA,
    value,
  };
};

export const deleteSegmentQuery = (data, history) => {
  return {
    type: SEGMENTATION_REDUCER.DELETE_SEGMENT_REQUEST,
    value: data,
    history,
  };
};

export const userPropertyRequest = (data, history) => {
  return {
    type: SEGMENTATION_REDUCER.USER_PROPERTY_LIST_REQUEST,
    data,
    history,
  };
};
