import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import MultiSelectOption from "./MultiSelectOption";

import { Country, City } from "country-state-city";

const DynamicMultiSelectorFields = (props) => {
  const {
    countriesArray,
    setCountriesArray,
    formErrors,
    isUpdateCampaignClick,
  } = props;
  const countryOption = [
    { value: "Algeria", label: "Algeria" },
    { value: "Japan", label: "Japan" },
    { value: "Australia", label: "Australia" },
    { value: "India", label: "India" },
  ];

  const [country, setCountry] = useState({});
  const [resetClick, setResetClick] = useState(false);
  const [countryList, setCountryList] = useState(countryOption);
  // const [inputFields, setInputFields] = useState([{ country: "", cities: [] }]);
  const addButtonStyle = {
    marginTop: "26px",
    fontSize: "15px",
    display: countriesArray.length === countryOption.length ? "none" : "",
  };

  useEffect(() => {
    let array = Country.getAllCountries();
    const countryArray = array.map((data) => {
      return { value: data.name, label: data.name, code: data.isoCode };
    });
    setCountryList(countryArray);
  }, [country]);
  // useEffect(() => {
  //   const arrayList = countriesArray?.cities?.map((data) => {
  //     return { value: data, label: data };
  //   });
  //   setCitiesList([...citiesList, arrayList]);
  // }, [countriesArray]);
  const handleAddFields = () => {
    const values = [...countriesArray];
    values.push({ country: "", cities: [] });
    setCountriesArray([...values]);
  };

  const handleRemoveFields = (index) => {
    const values = [...countriesArray];
    values.splice(index, 1);
    setCountriesArray([...values]);
  };

  const handleInputChange = (index, event, eventType) => {
    const values = [...countriesArray];
    if (eventType === "country") {
      setCountry({ ...event });
      // let cities = City.getCitiesOfCountry(event.code);
      // console.log("Cities", cities);
      // const citiesArray = cities.map((data) => {
      //   return { value: data.name, label: data.name };
      // });
      // console.log("CIRI", citiesArray);
      // setCitiesList([...citiesArray]);
      countryList.map((data, i) => {
        if (data.value === event.value) countryList.splice(i, 1);
      });
      setCountryList([...countryList]);

      values[index].country = event.value;
    } else {
      if (event !== null) {
        // var valueArr = values[index].cities.map(function (item) {
        //   return item.value;
        // });
        // var isDuplicate = valueArr.some(function (item, idx) {
        //   return valueArr.indexOf(item) != idx;
        // });
        // if (!isDuplicate) {
        //   values[index].cities.push(event);
        // }
        event.map((data, i) => {
          if (!values[index].cities.includes(data.value))
            values[index].cities.push(data.value);
        });
      } else {
        values[index].cities = [];
      }
    }
    setCountriesArray([...values]);
  };

  const citiesOptionList = (country) => {
    let cities = City.getCitiesOfCountry(country?.code);
    const citiesArray = cities.map((data) => {
      return { value: data.name, label: data.name };
    });
    return citiesArray;
  };

  const resetForm = (e) => {
    setResetClick(true);
    setCountryList(countryOption);
    setCountriesArray([{ country: "", cities: [] }]);
  };

  return (
    <section>
      <div className="row">
        {countriesArray &&
          countriesArray.map((data, index) => (
            <Fragment key={`${data}~${index}`}>
              <div className="row">
                <div className="col-6 col-sm-4 mb-2">
                  <div className="form-group mb-3">
                    <label htmlFor="country">Country</label>
                    <div
                      className={
                        !data.country &&
                        formErrors.country &&
                        "error-form-control"
                      }
                    >
                      <Select
                        className="singleSelectMenu"
                        name="country"
                        maxMenuHeight={100}
                        value={{
                          value: data?.country,
                          label: data?.country,
                        }}
                        onChange={(e) => handleInputChange(index, e, "country")}
                        options={countryList}
                        style={{
                          lineHeight: "40px",
                          color: "#7e7e7e",
                          paddingLeft: " 15px",
                        }}
                      />
                    </div>
                    {!data.country && formErrors.country && (
                      <div className="text-danger fs-12">
                        {formErrors.country}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-6 col-sm-5 mb-2">
                  {isUpdateCampaignClick && (
                    <div className="form-group mb-3">
                      <label htmlFor="cities">Cities</label>
                      <div>
                        <MultiSelectOption
                          className="multi-select"
                          maxMenuHeight={100}
                          optionList={citiesOptionList(country)}
                          onChange={(e) =>
                            handleInputChange(index, e, "cities")
                          }
                        />
                      </div>
                    </div>
                  )}

                  {!isUpdateCampaignClick && (
                    <div className="form-group mb-3">
                      <label htmlFor="cities">Cities</label>
                      <div
                        className={
                          data?.cities?.length === 0 &&
                          formErrors.cities &&
                          "error-form-control"
                        }
                      >
                        <MultiSelectOption
                          className="multi-select"
                          optionList={citiesOptionList(country)}
                          maxMenuHeight={100}
                          style={{ display: "none" }}
                          // disabled={
                          //   citiesOptionList(country).length !== 0
                          //     ? false
                          //     : true
                          // }
                          // value={() =>
                          //   data?.cities?.map((dataObj) => {

                          //     return { value: dataObj, label: dataObj };
                          //   })
                          // }
                          // value={citiesList}
                          // value={
                          //   data.cities &&
                          //   data.cities.length !== 0 &&
                          //   data.cities[index].map((item) => {
                          //     return { value: item, label: item };
                          //   })
                          // }
                          // onFocus={(e) => handleOnFocusChanges(index, e)}
                          onChange={(e) =>
                            handleInputChange(index, e, "cities")
                          }
                        />
                      </div>
                      {data.cities.length === 0 && formErrors.cities && (
                        <div className="text-danger fs-12">
                          {formErrors.cities}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-sm-3 mb-2">
                  <Button
                    className="me-2"
                    style={addButtonStyle}
                    variant="danger tp-btn-light"
                    disabled={countriesArray.length === 1}
                    onClick={() => handleRemoveFields(index)}
                  >
                    <span className="">
                      <i className="fa fa-minus" />
                    </span>
                  </Button>
                  <Button
                    className="me-2"
                    style={addButtonStyle}
                    variant="danger tp-btn-light"
                    onClick={() => handleAddFields()}
                  >
                    <span className="">
                      <i className="fa fa-plus" />
                    </span>
                  </Button>
                </div>
              </div>
            </Fragment>
          ))}
      </div>
      <div className="submit-button">
        {/* <Button className="me-2" variant="primary" onClick={handleSubmit}>
          Save
        </Button> */}
        {/* <Button className="me-2" variant="warning" onClick={resetForm}>
          Reset Values
        </Button> */}
      </div>
    </section>
  );
};

export default DynamicMultiSelectorFields;
