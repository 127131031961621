import { DASHBOARD_REDUCER } from "../constants/actionConstants";
import moment from "moment";

export const initialState = {
  isdashboardLoading: false,
  selectedCampaign: {},
  campaignList: [],
  successCampaignRequest: false,
  successRecentCampaignRequest: false,
  campaignById: {},
  eventMatrixList: [],
  campaignListPermission: false,
  isCampaignSummary: false,
  influencerArray: [],
  eventMatrixArray: [],
  dateRange: {
    startDate: moment(moment().subtract(6, "days")).format("YYYY-MM-DD"),
    endDate: moment(moment()).format("YYYY-MM-DD"),
  },
  campaignEventMatrix: [],
  totalCampaignEventCount: 0,
};

export const dashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_REDUCER.SET_SELECTED_CAMPAIGN:
      return { ...state, selectedCampaign: action.value };
    case DASHBOARD_REDUCER.SET_DASHBOARD_LOADING:
      return { ...state, isdashboardLoading: action.value };
    case DASHBOARD_REDUCER.SET_CAMPAIGN_LIST:
      return { ...state, campaignList: action.value };
    case DASHBOARD_REDUCER.SET_DATE_RANGE:
      return { ...state, dateRange: action.value };
    case DASHBOARD_REDUCER.SUCCESS_CAMPAIGN_LIST:
      return { ...state, successCampaignRequest: action.value };
    case DASHBOARD_REDUCER.SET_DASHBOARD_DATE_RANGE:
      return { ...state, dateRange: action.value };
    case DASHBOARD_REDUCER.SUCCESS_SELECTED_CAMPAIGN:
      return { ...state, successRecentCampaignRequest: action.value };
    case DASHBOARD_REDUCER.SET_CAMPAIGN_BY_ID:
      return { ...state, campaignById: action.value };
    case DASHBOARD_REDUCER.SET_EVENT_MATRIX_LIST:
      return { ...state, eventMatrixList: action.value };
    case DASHBOARD_REDUCER.SET_CAMPAIGN_LIST_PERMISSION:
      return { ...state, campaignListPermission: action.value };
    case DASHBOARD_REDUCER.SET_CAMPAIGN_EVENT_MATRIX:
      return { ...state, campaignEventMatrix: action.value };
    case DASHBOARD_REDUCER.SET_TOTAL_EVENT_MATRIX_COUNT:
      return { ...state, totalCampaignEventCount: action.value };
    case DASHBOARD_REDUCER.SET_INFLUENCER_ARRAY: {
      if (action.value === undefined) {
        return { ...state, influencerArray: [] };
      }
      return {
        ...state,
        influencerArray: [
          {
            influencerDetails: action?.value?.influencer,
            value: action?.value?.count,
            name: action?.value?.influencer?.name,
            eventName: action?.eventName,
          },
          ...state.influencerArray,
        ],
      };
    }
    case DASHBOARD_REDUCER.SET_EVENT_MATRIX_ARRAY: {
      if (action.value === undefined) {
        return { ...state, eventMatrixArray: [] };
      }
      return {
        ...state,
        eventMatrixArray: [
          {
            value: action?.value?.count,
            eventName: action?.eventName,
          },
          ...state.eventMatrixArray,
        ],
      };
    }
    case DASHBOARD_REDUCER.RESET_DASHBOARD_DETAILS:
      return { ...initialState };
    default:
      return state;
  }
};
