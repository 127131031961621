import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactSpinner from "../Comman/ReactSpinner";
import UserDetailsCard from "./UserDetailsCards";

const UserListTab = (props) => {
  const { listData, isDataLoaded, setListData, setRefreshData, refreshData } =
    props;

  const [activeToggle, setActiveToggle] = useState("toDoList");

  useEffect(() => {}, [listData]);
  return (
    <div className="profile-tab">
      <div className="custom-tab-1">
        <ul className="nav nav-tabs">
          <li className="nav-item" onClick={() => setActiveToggle("toDoList")}>
            <Link
              to="#my-posts"
              data-toggle="tab"
              className={`nav-link ${
                activeToggle === "toDoList" ? "active show" : ""
              }`}
            >
              Requested
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActiveToggle("accepted")}>
            <Link
              to="#about-me"
              data-toggle="tab"
              className={`nav-link ${
                activeToggle === "accepted" ? "active show" : ""
              }`}
            >
              Accepted
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="#profile-settings"
              data-toggle="tab"
              onClick={() => setActiveToggle("rejected")}
              className={`nav-link ${
                activeToggle === "rejected" ? "active show" : ""
              }`}
            >
              Rejected
            </Link>
          </li>
        </ul>
        {isDataLoaded ? (
          <div className="tab-content">
            <div
              id="my-posts"
              className={`tab-pane fade ${
                activeToggle === "toDoList" ? "active show" : ""
              }`}
            >
              <div className="my-post-content pt-3">
                {listData?.filter(function (obj) {
                  if (
                    obj.registrationStatus === "COMPANY_SELECTED_FOR_APPROVAL"
                  )
                    return obj;

                  return [];
                })?.length === 0 && (
                  <div
                    className="not-data"
                    style={{ marginTop: "3rem", fontSize: "16px" }}
                  >
                    No data available
                  </div>
                )}
                {listData
                  ?.filter((obj) => {
                    if (
                      obj.registrationStatus === "COMPANY_SELECTED_FOR_APPROVAL"
                    )
                      return obj;

                    return [];
                  })
                  ?.map((data, i) => (
                    <UserDetailsCard
                      userDetails={data}
                      key={i}
                      listData={listData}
                      setListData={setListData}
                      status="requested"
                      activeToggle={activeToggle}
                      setRefreshData={setRefreshData}
                      refreshData={refreshData}
                    />
                  ))}
              </div>
            </div>
            <div
              id="about-me"
              className={`tab-pane fade ${
                activeToggle === "accepted" ? "active show" : ""
              }`}
            >
              <div className="profile-about-me pt-3">
                {listData
                  ?.filter((obj) => {
                    if (obj.registrationStatus === "COMPANY_APPROVED")
                      return obj;

                    return [];
                  })
                  ?.map((data, i) => (
                    <UserDetailsCard
                      userDetails={data}
                      key={i}
                      listData={listData}
                      setListData={setListData}
                      activeToggle={activeToggle}
                      setRefreshData={setRefreshData}
                      refreshData={refreshData}
                      status="approved"
                    />
                  ))}
              </div>
            </div>
            <div
              id="profile-settings"
              className={`tab-pane fade ${
                activeToggle === "rejected" ? "active show" : ""
              }`}
            >
              <div className="profile-about-me pt-3">
                {listData
                  ?.filter((obj) => {
                    if (obj.registrationStatus === "COMPANY_REJECTED")
                      return obj;

                    return [];
                  })
                  ?.map((data, i) => (
                    <UserDetailsCard
                      userDetails={data}
                      key={i}
                      listData={listData}
                      setListData={setListData}
                      activeToggle={activeToggle}
                      setRefreshData={setRefreshData}
                      refreshData={refreshData}
                      status="rejected"
                    />
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <ReactSpinner />
        )}
      </div>
    </div>
  );
};

export default UserListTab;
