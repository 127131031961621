import React, { useEffect } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getUserRequestedListAction } from "../../../actions/AdminSetting/adminSetting";
import { changeUserRegistrationStatusRequest } from "../../../actions/Register/registerAction";

const UserDetailsCard = (props) => {
  const {
    userDetails,
    listData,
    activeToggle,
    setRefreshData,
    refreshData,
    status,
  } = props;
  const fontSizeStyle = { fontSize: "14px" };
  const padding = { width: "10rem" };
  const dispatch = useDispatch();

  // const { data, status } = useQuery("fetchUserRequestList", () =>
  //   fetchUserRequestList()
  // );

  useEffect(() => {}, [listData]);

  const handleChange = (e) => {
    if (e.target.name === "reject") {
      dispatch(
        changeUserRegistrationStatusRequest({
          email: userDetails.email,
          registrationStatus: "COMPANY_REJECTED",
        })
      );
      dispatch(getUserRequestedListAction());
      setRefreshData(!refreshData);
    } else {
      dispatch(
        changeUserRegistrationStatusRequest({
          email: userDetails.email,
          registrationStatus: "COMPANY_APPROVED",
        })
      );
      dispatch(getUserRequestedListAction());
      setRefreshData(!refreshData);
    }
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={8}>
            <div className="form-check custom-radio mb-1" style={fontSizeStyle}>
              <label
                className="form-check-label"
                htmlFor="credit"
                style={padding}
              >
                Email-Id:
              </label>
              <strong>{userDetails.email}</strong>
            </div>
            <div className="form-check custom-radio mb-1" style={fontSizeStyle}>
              <label
                className="form-check-label"
                htmlFor="credit"
                style={padding}
              >
                Id:
              </label>
              <strong>{userDetails._id}</strong>
            </div>

            <div className="form-check custom-radio mb-1" style={fontSizeStyle}>
              <label
                className="form-check-label"
                htmlFor="credit"
                style={padding}
              >
                Status:
              </label>
              <strong>{userDetails.registrationStatus}</strong>
            </div>
          </Col>

          <Col sm={4}>
            {(status === "requested" || status === "rejected") && (
              <Button
                className="me-2"
                variant="danger tp-btn-light btn-md"
                name="reject"
                style={{ fontSize: "18px" }}
                onClick={(e) => handleChange(e)}
                disabled={activeToggle === "rejected"}
              >
                {activeToggle === "toDoList" ? "Reject" : "Revoke"}
              </Button>
            )}
            {status === "requested" && (
              <Button
                className="me-2"
                variant="success tp-btn btn-md"
                name="accept"
                style={{ fontSize: "18px" }}
                onClick={(e) => handleChange(e)}
                disabled={activeToggle === "accepted"}
              >
                Accept
              </Button>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default UserDetailsCard;
