import { all } from "redux-saga/effects";
import loginSaga from "./loginSaga";
import dashboardSaga from "./dashboardSaga";
import createCampaignSaga from "./createCampaignSaga";
import registrationSaga from "./registrationSaga";
import adminUserSaga from "./adminUserSaga";
import segmentationSaga from "./segmentationSaga";
import analyzeSaga from "./analyzeSaga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    dashboardSaga(),
    createCampaignSaga(),
    registrationSaga(),
    adminUserSaga(),
    segmentationSaga(),
    analyzeSaga(),
  ]);
}
