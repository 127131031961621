import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { setEventMatrixValue } from "../../../actions/Analyze/analyzeAction";
import InfluencerMix from "./InfluencerMix";
import {
  getCitiesDataRequest,
  getCountriesDataRequest,
} from "../../../actions/Analyze/analyzeAction";

const Analyze = () => {
  const dispatch = useDispatch();
  const { campaignById, eventMatrixList, campaignList, dateRange } =
    useSelector((state) => state.dashboardReducers);
  const [eventInfluencer, setEventInfluencer] = useState([]);
  const {
    showInfluencerDetails,
    onInfluencerDetailClick,
    defaultEventMatrixValue,
    citiesArray,
    deviceEventDetailsData,
    countriesArray,
    citiesDateRange,
    countryDateRange,
  } = useSelector((state) => state.analyzeReducers);
  const [optionEvent, setOptionEvent] = useState([]);
  const [citiesDate, setCitiesDate] = useState("");
  const [selectedEvent, setSelectedEvent] = useState({
    value: eventMatrixList[0],
    label: (
      eventMatrixList[0].charAt(0).toUpperCase() + eventMatrixList[0].slice(1)
    )
      .replace(/([A-Z])/g, " $1")
      .trim(),
  });

  useEffect(() => {
    const options = eventMatrixList?.map((data) => {
      return {
        value: data,
        label: (data.charAt(0).toUpperCase() + data.slice(1))
          .replace(/([A-Z])/g, " $1")
          .trim(),
      };
    });
    setOptionEvent([...options]);

    const inf = campaignById?.influencers.map((obj) => {
      return obj._id;
    });

    setEventInfluencer([...inf]);

    // const citiesData = {
    //   eventName: selectedEvent?.value,
    //   campaignId: campaignById?._id,
    //   type: "eventCount",
    //   startDate: citiesDateRange?.startDate,
    //   endDate: citiesDateRange?.endDate,
    // };
    // dispatch(getCitiesDataRequest(citiesData));
    // const countryData = {
    //   eventName: selectedEvent?.value,
    //   campaignId: campaignById?._id,
    //   type: "eventCount",
    //   startDate: countryDateRange?.startDate,
    //   endDate: countryDateRange?.endDate,
    //   byCountry: "true",
    // };
    // dispatch(getCountriesDataRequest(countryData));
  }, [
    dispatch,
    showInfluencerDetails,
    campaignById,
    eventMatrixList,
    onInfluencerDetailClick,
    campaignList,
  ]);

  // useEffect(() => {
  //   eventInfluencer?.map(async (obj) => {
  //     console.log("In UseeEffect EVENT NAME", selectedEventMatrixValue);
  //     let getData = await callApi(
  //       "/resource/eventsMatrix/getEventDetails",
  //       "POST",
  //       {
  //         eventName: selectedEventMatrixValue,
  //         campaignId: campaignById?._id,
  //         startDate: "2022-05-16",
  //         influencerId: obj,
  //         endDate: "2022-05-22",
  //       },
  //       {},
  //       {}
  //     ).then((data) => {
  //       const datavalue = data?.data?.data;
  //       dispatch(setInfluencerEventData(datavalue, selectedEventMatrixValue));
  //     });
  //   });
  // }, [eventInfluencer, selectedEventMatrixValue]);

  useEffect(() => {
    setSelectedEvent({ value: eventMatrixList[0], label: eventMatrixList[0] });
    dispatch(setEventMatrixValue(eventMatrixList[0]));
  }, [dispatch, optionEvent, eventMatrixList, campaignById, dateRange]);

  useEffect(() => {}, [defaultEventMatrixValue]);

  const style = {
    marginTop: "1rem",
  };

  return (
    <>
      <Fragment>
        {Object.keys(campaignById).length === 0 ? (
          <div>Please Add Campaign</div>
        ) : (
          <>
            <InfluencerMix
              eventMatrixList={eventMatrixList}
              campaignById={campaignById}
              breadCrumbsName="Influencer Mix"
              cardHeader="Influencer Analyse"
              optionEvent={optionEvent}
              selectedEvent={selectedEvent}
              dateRange={dateRange}
              isCitiesContainer={false}
              isCountryContainer={false}
              isInfluencerContainer={true}
              eventInfluencer={eventInfluencer}
            />
            <InfluencerMix
              style={style}
              eventMatrixList={eventMatrixList}
              campaignById={campaignById}
              breadCrumbsName="Countries"
              cardHeader="Event Count for Countries"
              optionEvent={optionEvent}
              selectedEvent={selectedEvent}
              citiesArray={citiesArray}
              dateRange={dateRange}
              countriesArray={countriesArray}
              isCountryContainer={true}
              isCitiesContainer={false}
              // setAnalyseDate={setAnalyseDate}
            />
            <InfluencerMix
              style={style}
              eventMatrixList={eventMatrixList}
              campaignById={campaignById}
              breadCrumbsName="Cities"
              cardHeader="Event Count for Cities"
              optionEvent={optionEvent}
              selectedEvent={selectedEvent}
              isCitiesContainer={true}
              citiesArray={citiesArray}
              dateRange={dateRange}
              citiesDate={citiesDate}
              setCitiesDate={setCitiesDate}
              isCountryContainer={false}
              // setAnalyseDate={setAnalyseDate}
            />
            <InfluencerMix
              eventMatrixList={eventMatrixList}
              campaignById={campaignById}
              breadCrumbsName="Devices Platform"
              cardHeader="Event Count for Devices"
              optionEvent={optionEvent}
              selectedEvent={selectedEvent}
              dateRange={dateRange}
              isCitiesContainer={false}
              isCountryContainer={false}
              isInfluencerContainer={false}
              isDeviceEventContainer={true}
              eventInfluencer={eventInfluencer}
              deviceEventDetailsData={deviceEventDetailsData}
            />
          </>
        )}
      </Fragment>
    </>
  );
};

export default Analyze;
