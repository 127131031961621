import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import MultiSelectOption from "../../Comman/MultiSelectOption";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

const StepTwoAudianceDetails = (props) => {
  const {
    nextStep,
    prevStep,
    setFormTitle,
    setStepTwoDetails,
    stepTwoDetails,
  } = props;
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({});
  const [disable, setDisable] = useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);

  useEffect(() => {
    setFormErrors(validate(stepTwoDetails));
  }, [stepTwoDetails, formValues]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
      setDisable(false);
    }
  }, [formErrors]);

  useEffect(() => {
    setFormTitle("Add Audience Details");
    setFormValues(stepTwoDetails);
  }, [stepTwoDetails, formErrors]);

  const validate = (values) => {
    let errors = {};
    if (!values.audienceAge || values.audienceAge.length === 0) {
      errors.audienceAge = "Please Select at least one Age group";
    }
    if (!values.audienceGender || values.audienceGender.length === 0) {
      errors.audienceGender = "Please Select at least one Gender";
    }
    if (!values.audienceIntrest || values.audienceIntrest.length === 0) {
      errors.audienceIntrest = "Please Select at least one Intrest";
    }
    if (!values.platformTags || values.platformTags.length === 0) {
      errors.platformTags = "Please Select at least one Platform tags";
    }
    return errors;
  };

  const ageOptionList = [
    { value: "13-17", label: "13-17" },
    { value: "18-24", label: "18-24" },
    { value: "25-34", label: "25-34" },
    { value: "35-44", label: "35-44" },
    { value: "45-64", label: "45-64" },
    { value: "65-74", label: "65-74" },
    { value: "75-84", label: "75-84" },
  ];
  const genderOptionList = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  const intrestOptionList = [
    { value: "clothes", label: "Clothes" },
    { value: "healthylifeStyle", label: "Healthy Lifestyle" },
    { value: "pets", label: "Pets" },
    { value: "activewear", label: "Activewear" },
    { value: "photography", label: "Photography" },
    { value: "foodGrocery", label: "Food & Grocery" },
    { value: "wedding", label: "Wedding" },
    { value: "gaming", label: "Gaming" },
    { value: "friends", label: "Friends" },
    { value: "music", label: "Music" },
  ];

  const onSubmit = () => {
    setIsSubmitClick(true);
    setFormErrors({ ...validate(stepTwoDetails) });
    if (Object.keys(formErrors).length !== 0) {
      setDisable(true);
    } else {
      nextStep();
    }
  };

  return (
    <section>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Audience Age*</label>
            <div
              className={
                isSubmitClick && formErrors.audienceAge && "error-form-control"
              }
            >
              <MultiSelectOption
                className="multi-select"
                optionList={ageOptionList}
                value={stepTwoDetails.audienceAge}
                onChange={(value) =>
                  setStepTwoDetails({
                    ...stepTwoDetails,
                    audienceAge: value,
                  })
                }
              />
            </div>
            {isSubmitClick && formErrors.audienceAge && (
              <div className="text-danger fs-12">{formErrors.audienceAge}</div>
            )}
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Gender*</label>
            <div
              className={
                isSubmitClick &&
                formErrors.audienceGender &&
                "error-form-control"
              }
            >
              <MultiSelectOption
                className="multi-select"
                optionList={genderOptionList}
                value={stepTwoDetails.audienceGender}
                onChange={(value) =>
                  setStepTwoDetails({
                    ...stepTwoDetails,
                    audienceGender: value,
                  })
                }
              />
            </div>
            {isSubmitClick && formErrors.audienceGender && (
              <div className="text-danger fs-12">
                {formErrors.audienceGender}
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Intrest*</label>
            <div
              className={
                isSubmitClick &&
                formErrors.audienceIntrest &&
                "error-form-control"
              }
            >
              <MultiSelectOption
                className="multi-select"
                value={stepTwoDetails.audienceIntrest}
                optionList={intrestOptionList}
                onChange={(value) =>
                  setStepTwoDetails({
                    ...stepTwoDetails,
                    audienceIntrest: value,
                  })
                }
              />
            </div>
            {isSubmitClick && formErrors.audienceIntrest && (
              <div className="text-danger fs-12">
                {formErrors.audienceIntrest}
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">PlatForm*</label>
            <div
              className={
                isSubmitClick && formErrors.platformTags && "error-form-control"
              }
            >
              <ReactTagInput
                tags={stepTwoDetails.platformTags}
                onChange={(newTags) =>
                  setStepTwoDetails({
                    ...stepTwoDetails,
                    platformTags: newTags,
                  })
                }
              />
            </div>
            {isSubmitClick && formErrors.platformTags && (
              <div className="text-danger fs-12">{formErrors.platformTags}</div>
            )}
          </div>
        </div>
      </div>
      <div className="d-inline">
        <button className="multip-prev-button" onClick={prevStep}>
          Prev
        </button>
        <Button
          className="multip-form-button"
          variant="primary"
          onClick={onSubmit}
          disabled={disable}
        >
          Next
        </Button>
      </div>
    </section>
  );
};

export default StepTwoAudianceDetails;
