export const LOGIN_REDUCER = {
  SET_EMAIL: "SET_EMAIL",
  SET_PASSWORD: "SET_PASSWORD",
  SET_EMAIL_ERROR: "SET_EMAIL_ERROR",
  SET_PASSWORD_ERROR: "SET_PASSWORD_ERROR",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  LOGIN_REQUEST: "SET_LOGIN_REQUEST",
  LOGIN_MESSAGE: "LOGIN_MESSAGE",
  LOGIN_REQUEST_FAILED: "LOGIN_REQUEST_FAILED",
  RESET_LOGIN_DETAILS: "RESET_LOGIN_DETAILS",
  SET_USER_NAME: "SET_USER_NAME",
  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
  SET_COMPANY_ID: "SET_COMPANY_ID",
};

export const DASHBOARD_REDUCER = {
  SET_CAMPAIGN_LIST: "SET_CAMPAIGN_LIST",
  SET_SELECTED_CAMPAIGN: "SET_SELECTED_CAMPAIGN",
  CAMPAIGN_LIST_REQUEST: "CAMPAIGN_LIST_REQUEST",
  RECENT_CAMPAIGN_REQUEST: "RECENT_CAMPAIGN_REQUEST",
  SET_DASHBOARD_LOADING: "SET_DASHBOARD_LOADING",
  SUCCESS_CAMPAIGN_LIST: "SUCCESS_CAMPAIGN_LIST",
  SUCCESS_SELECTED_CAMPAIGN: "SUCCESS_SELECTED_CAMPAIGN",
  GET_CAMPAIGN_BY_ID: "GET_CAMPAIGN_BY_ID",
  SET_CAMPAIGN_BY_ID: "SET_CAMPAIGN_BY_ID",
  SET_EVENT_MATRIX_LIST: "SET_EVENT_MATRIX_LIST",
  SET_CAMPAIGN_LIST_PERMISSION: "SET_CAMPAIGN_LIST_PERMISSION",
  RESET_DASHBOARD_DETAILS: "RESET_DASHBOARD_DETAILS",
  GET_EVENT_MATRIX_INFLUENCER_DATA: "GET_EVENT_MATRIX_INFLUENCER_DATA",
  IS_CAMPAIGN_SUMMARY: "IS_CAMPAIGN_SUMMARY",
  SET_DASHBOARD_DATE_RANGE: "SET_DASHBOARD_DATE_RANGE",
  SET_INFLUENCER_ARRAY: "SET_INFLUENCER_ARRAY",
  SET_EVENT_MATRIX_ARRAY: "SET_EVENT_MATRIX_ARRAY",
  GET_INFLUENCER_CITIES_DETAILS: "GET_INFLUENCER_CITIES_DETAILS",
  SET_CAMPAIGN_EVENT_MATRIX: "SET_CAMPAIGN_EVENT_MATRIX",
  SET_TOTAL_EVENT_MATRIX_COUNT: "SET_TOTAL_EVENT_MATRIX_COUNT",
  CAMPAIGN_EVENT_MATRIX_REQUEST: "CAMPAIGN_EVENT_MATRIX_REQUEST",
};
export const CAMPAIGN_DETAILS = {
  SHOW_ADD_CAMPAIGN_FORM: "SHOW_ADD_CAMPAIGN_FORM",
  RESET_CAMPAIGN_DETAILS: "RESET_CAMPAIGN_DETAILS",
  ADD_CAMPAIGN_NAME: "ADD_CAMPAIGN_NAME",
  ADD_CAMPAIGN_BUGET: "ADD_CAMPAIGN_BUGET",
  ADD_CAMPAIGN_START_DATE: "ADD_CAMPAIGN_START_DATE",
  ADD_CAMPAIGN_END_DATE: "ADD_CAMPAIGN_END_DATE",
  ADD_CAMPAIGN_GOAL: "ADD_CAMPAIGN_GOAL",
  ADD_CAMPAIGN_EVENT: "ADD_CAMPAIGN_EVENT",
  ADD_CAMPAIGN_TARGET_LINK: "ADD_CAMPAIGN_TARGET_LINK",
  ADD_AUDIENCE_AGE: "ADD_AUDIENCE_AGE",
  ADD_AUDIENCE_GENDER: "ADD_AUDIENCE_GENDER",
  ADD_AUDIENCE_INTREST: "ADD_AUDIENCE_INTREST",
  ADD_AUDIENCE_PLATFORM: "ADD_AUDIENCE_PLATFORM",
  ADD_INFLUENCER_DETAILS: "ADD_INFLUENCER_DETAILS",
  ADD_HASH_TAG: "ADD_HASH_TAG",
  ADD_AREA_OF_IMPACT: "ADD_AREA_OF_IMPACT",
  CREATE_CAMPAIGN_REQUEST: "CREATE_CAMPAIGN_REQUEST",
  IS_UPDATE_CAMPAIGN_CLICK: "IS_UPDATE_CAMPAIGN_CLICK",
  UPDATE_CAMPAIGN_REQUEST: "UPDATE_CAMPAIGN_REQUEST",
  SHOW_INFLUENCER_DETAILS: "SHOW_INFLUENCER_DETAILS",
  IS_CAMPAIGN_NAME_PRESENT: "IS_CAMPAIGN_NAME_PRESENT",
  SET_EDIT_CAMPAIGN_FORM: "SET_EDIT_CAMPAIGN_FORM",
  SUCCESS_CAMPAIGN_RESPONSE: "SUCCESS_CAMPAIGN_RESPONSE",
  DELETE_CAMPAIGN_REQUEST: "DELETE_CAMPAIGN_REQUEST",
  SUCCESS_DELETE_RESPONSE: "SUCCESS_DELETE_RESPONSE",
};

export const ANALYZE_REDUCER = {
  SHOW_INFLUENCER_DETAILS: "SHOW_INFLUENCER_DETAILS",
  SET_INFLUENCER_DETAILS_ON_CLICK: "SET_INFLUENCER_DETAILS_ON_CLICK",
  SET_ON_CLICK_DONUT_GRAPH: "SET_ON_CLICK_DONUT_GRAPH",
  SELECTED_MATRIX_VALUE: "SELECTED_MATRIX_VALUE",
  DEFAULT_EVENT_VALUE: "DEFAULT_EVENT_VALUE",
  SET_INFLUENCER_USER_DATE_RANGE: "SET_INFLUENCER_USER_DATE_RANGE",
  SET_INFLUENCER_USER_DATE_LABEL: "SET_INFLUENCER_USER_DATE_LABEL",
  SET_TOTAL_INFLUE_EVENT_MATRIX: "SET_TOTAL_INFLUE_EVENT_MATRIX",
  SET_SELECT_EVENT_MATRIX_ERROR: "SET_SELECT_EVENT_MATRIX_ERROR",
  SET_INLFUENCER_DATE_RANGE: "SET_INLFUENCER_DATE_RANGE",
  SET_CITIES_DATE_RANGE: "SET_CITIES_DATE_RANGE",
  GET_CITIES_DATE_REQUEST: "GET_CITIES_DATE_REQUEST",
  GET_COUNTRIES_DATE_REQUEST: "GET_COUNTRIES_DATE_REQUEST",
  SET_CITIES_DATA: "SET_CITIES_DATA",
  SET_INFL_TOTAL_COUNT: "SET_INFL_TOTAL_COUNT",
  SET_COUNTRY_DATE_RANGE: "SET_COUNTRY_DATE_RANGE",
  SET_COUNTRIES_DATA: "SET_COUNTRIES_DATA",
  SET_INFLUENCER_DATE_RANGE: "SET_INFLUENCER_DATE_RANGE",
  SET_DEVICE_EVENT_DATE_RANGE: "SET_DEVICE_EVENT_DATE_RANGE",
  SET_DEVICE_EVENT_DATE_LABEL: "SET_DEVICE_EVENT_DATE_LABEL",
  GET_INFLU_EVENT_MATRIX_REQUEST: "GET_INFLU_EVENT_MATRIX_REQUEST",
  SET_INFLUENCER_EVENT_ARRAY: "SET_INFLUENCER_EVENT_ARRAY",
  GET_ALL_INFLUENCER_EVENT_REQUEST: "GET_ALL_INFLUENCER_EVENT_REQUEST",
  SET_ALL_INFLUENCER_EVENT_DATA: "SET_ALL_INFLUENCER_EVENT_DATA",
  GET_DEVICE_EVENT_DETAILS_REQUEST: "GET_DEVICE_EVENT_DETAILS_REQUEST",
  SET_DEVICE_EVENT_DETAILS_DATA: "SET_DEVICE_EVENT_DETAILS_DATA",
  SET_TOTAL_DEVICE_EVENT_COUNT: "SET_TOTAL_DEVICE_EVENT_COUNT",
};

export const SEGMENTATION_REDUCER = {
  USER_PROPERTY_LIST_REQUEST: "USER_PROPERTY_LIST_REQUEST",
  SET_IS_FIELD_LIST_SUCCESS: "SET_IS_FIELD_LIST_SUCCESS",
  SET_USER_PROPERTY_FIELD_LIST: "SET_USER_PROPERTY_FIELD_LIST ",
  SET_QUERY_COUNT_NUMBER: "SET_QUERY_COUNT_NUMBER",
  SET_DOWNLOAD_URL_LINK: "SET_DOWNLOAD_URL_LINK",
  GET_QUERY_COUNT_REQUEST: "GET_QUERY_COUNT_REQUEST",
  SET_IS_QUERY_LOADED: "SET_IS_QUERY_LOADED",
  SET_QUERY_COUNT_REQUEST_ERROR: "SET_QUERY_COUNT_REQUEST_ERROR",
  SET_SHOW_SEGMENT_DATA: "SET_SHOW_SEGMENT_DATA",
  SET_RECENT_SEGMENT_DATA: "SET_RECENT_SEGMENT_DATA",
  FETCH_QUERY_REQUEST: "FETCH_QUERY_REQUEST",
  SET_FETCH_DATA_ARRAY: "SET_FETCH_DATA_ARRAY",
  IS_FETCH_DATA_LOADED: "IS_FETCH_DATA_LOADED",
  DOWNLOAD_FETCH_URL: "DOWNLOAD_FETCH_URL",
  SET_DOWNLOAD_URL: "SET_DOWNLOAD_URL",
  GET_SEGMENT_LIST_BY_CAMPAIGN: "GET_SEGMENT_LIST_BY_CAMPAIGN",
  SET_SEGMENT_LIST_BY_CAMPAIGN: "SET_SEGMENT_LIST_BY_CAMPAIGN",
  DELETE_SEGMENT_REQUEST: "DELETE_SEGMENT_REQUEST",
};
export const REGISTERATION_REDUCER = {
  SET_IF_EMAIL_EXIST: "SET_IF_EMAIL_EXIST",
  SET_IS_COMPANY_REGISTER_SUCCESS: "SET_IS_COMPANY_REGISTER_SUCCESS",
  REGISTER_EMAIL_ID: "REGISTER_EMAIL_ID",
  REGISTER_USER_NAME: "REGISTER_USER_NAME",
  REGISTER_PHONE_NUMBER: "REGISTER_PHONE_NUMBER",
  IS_EMAIL_ID_PRESENT_REQUEST: "IS_EMAIL_ID_PRESENT_REQUEST",
  IS_SIGN_UP_CLICK: "IS_SIGN_UP_CLICK",
  GENERATE_OTP_REQUEST: "GENERATE_OTP_REQUEST",
  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  GET_COMPANY_LIST_REQUEST: "GET_COMPANY_LIST_REQUEST",
  COMPANY_REGISTER_REQUEST: "COMPANY_REGISTER_REQUEST",
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  SEND_OTP_VALUE: "SEND_OTP_VALUE",
  SET_IS_OTP_INVALID: "SET_IS_OTP_INVALID",
  IS_OTP_SEND: "IS_OTP_SEND",
  IS_USER_VERIFIED: "IS_USER_VERIFIED",
  SET_REGISTER_USER_ID: "SET_REGISTER_USER_ID",
  SET_USER_PASSWORD: "SET_USER_PASSWORD",
  SET_COMPANY_LIST: "SET_COMPANY_LIST",
  SET_SELECTED_COMPANY: "SET_SELECTED_COMPANY",
  SET_CREATED_COMPANY_ID: "SET_CREATED_COMPANY_ID",
  SET_SUCCESS_COMPANY_CREATION: "SET_SUCCESS_COMPANY_CREATION",
  SET_IS_JOIN_BUTTON_CLICK: "SET_IS_JOIN_BUTTON_CLICK",
  SET_REGISTRATION_STATUS: "SET_REGISTRATION_STATUS",
  SET_SUCCESS_REGISTER_USER: "SET_SUCCESS_REGISTER_USER",
  ADD_USER_COMPANY_REQUEST: "ADD_USER_COMPANY_REQUEST",
  CHANGE_USER_REGISTRATION_STATUS: "CHANGE_USER_REGISTRATION_STATUS",
  UPDATE_USER_PROFILE_REQUEST: "UPDATE_USER_PROFILE_REQUEST",
};

export const ADMIN_USER_REDUCER = {
  GET_REQUESTED_USER_LIST_REQUEST: "GET_REQUESTED_USER_LIST_REQUEST",
  UPDATE_REGISTRATION_STATUS_REQUEST: "UPDATE_REGISTRATION_STATUS_REQUEST",
  SET_REQUEST_USER_LIST: "SET_REQUEST_USER_LIST",
  SET_USER_PROFILE_DETAILS: "SET_USER_PROFILE_DETAILS",
  GET_USER_PROFILE_DETAILS: "GET_USER_PROFILE_DETAILS",
};

export const COMMON_ACTION_REDUCER = {
  SET_SELECT_DATE_RANGE: "SET_SELECT_DATE_RANGE",
  SET_INFLUENCER_LABEL_DATE_RANGE: "SET_INFLUENCER_LABEL_DATE_RANGE",
  SET_CITIES_LABEL_DATE_RANGE: "SET_CITIES_LABEL_DATE_RANGE",
  SET_COUNTRIES_LABEL_DATE_RANGE: "SET_COUNTRIES_LABEL_DATE_RANGE",
};
