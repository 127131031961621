import { callApi } from "./apiHelper";

export const getCampaignList = () => {
  return callApi("/resource/getCampaignsList", "GET");
};

export const getRecentCampaign = () => {
  return callApi("/resource/getRecentCampaign", "GET");
};

export const getCampaignEventMatrix = (data) => {
  return callApi(
    "/resource/eventsMatrix/getAllEventDetails",
    "POST",
    data,
    {},
    {}
  );
};

export const getCampaignById = (campaignId) => {
  return callApi(
    `/resource/getCampaignById`,
    "GET",
    {},
    {},
    {
      campaignId,
    }
  );
};

export const getEventMatrixInfluencerData = (data) => {
  return callApi(
    `/resource/eventsMatrix/getEventDetails`,
    "POST",
    data,
    {},
    {}
  );
};
