import React, { useEffect, useState } from "react";
import { Card, Table, Button, Modal, Col, Pagination } from "react-bootstrap";
import swal from "sweetalert";
import TablePagination from "../../components/Comman/TablePagination";
import { InputFieldInSegment } from "../../components/Comman/ReadOnlyInputField";
import swalMessage from "@sweetalert/with-react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsFetchDataLoaded,
  deleteSegmentQuery,
} from "../../../actions/Segmentation/segmentationAction";

const QueryResultTable = (props) => {
  const { queryResultArray, setQueryResult } = props;
  const dispatch = useDispatch();
  const [basicModal, setBasicModal] = useState(false);
  const [editNameModal, setEditNameModal] = useState(false);
  const [editName, setEditName] = useState("");
  const [tableData, setTableDate] = useState([]);

  const { userName } = useSelector((state) => state.loginReducer);
  const {
    isQueryLoaded,

    recentSegmentData,
    userQueryRequestData,
    isFetchDataLoaded,
    downlaodUrlLink,
    segmentListByCampaign,
  } = useSelector((state) => state.segmentationReducer);

  useEffect(() => {}, [
    isQueryLoaded,
    recentSegmentData,
    userQueryRequestData,
    isFetchDataLoaded,
  ]);

  useEffect(() => {
    setQueryResult(segmentListByCampaign);
  }, [setQueryResult]);

  const columnsData = [
    {
      Header: "Sr no",
      Footer: "Sr no",
      accessor: "no",
    },
    {
      Header: "Query Time",
      Footer: "Query Time",
      accessor: "time",
    },
    {
      Header: "User Count",
      Footer: "User Count",
      accessor: "count",
    },
    {
      Header: "Download",
      Footer: "Download",
      accessor: "download",
    },
    {
      Header: "Delete",
      Footer: "Delete",
      accessor: "delete",
    },
    {
      Header: "View",
      Footer: "View",
      accessor: "view",
    },
  ];

  const deleteSegment = (index, segmentIdData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this segment!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteSegmentQuery({ segmentId: segmentIdData.segmentId }));
        queryResultArray.splice(index, 1);
        setQueryResult([...queryResultArray]);
      }
    });
  };
  useEffect(() => {
    const mockData = queryResultArray?.map((data, i) => {
      return {
        no: i + 1,
        time: data.queryTime,
        // name: data.source,
        count: data.count,
        download: data.isDownloadUrl ? (
          <a
            href={data.downlaodUrl}
            disabled={data.isDownloadUrl ? false : true}
            key={i}
            className="a-button"
          >
            <span style={{ color: "white" }}>
              <i className="fa fa-download color-warning"></i>
            </span>
          </a>
        ) : (
          "No data"
        ),
        delete: (
          <Button onClick={() => deleteSegment(i, data)} className="a-button">
            <i className="fas fa-trash"></i>
          </Button>
        ),
        view: (
          <Button onClick={() => onViewClick(data)} className="a-button">
            <i className="fas fa-eye"></i>
          </Button>
        ),
      };
    });

    setTableDate([...mockData]);
  }, [queryResultArray]);

  useEffect(() => {}, [downlaodUrlLink]);

  // const downlaodDataQuery = async (segmentId) => {
  //   const getData = await callApi(
  //     `/segment/getCsvS3Url`,
  //     "GET",
  //     { segmentId },
  //     {},
  //     {}
  //   ).catch((error) => {
  //     if (error.response.status === 401) {
  //       dispatch(resetLoginDetails());
  //       dispatch(resetDashboardDetails());
  //       dispatch(logoutRequest(history));
  //     }
  //   });
  //   return getData;
  // };

  // const { data, isLoading, status } = useQuery("downlaodData", (segmentId) =>
  //   downlaodDataQuery(segmentId)
  // );

  // const onDeleteButtonClick = (index) => {
  //   queryResultArray.splice(index, 1);
  //   setQueryResult([...queryResultArray]);
  // };
  const onShowDataModalClick = () => {
    setBasicModal(false);
    dispatch(setIsFetchDataLoaded(true));
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  // const functionData = (data) => {
  //   if (Array.isArray(data)) {
  //     for (var i = 0; i < data.length; i++) {
  //       var obj = data[i];
  //       for (var key in obj) {
  //         if (key === "$and" || key === "$or") {
  //           functionData(obj);
  //         } else {
  //           // render(key, obj[key]);
  //           return (
  //             <div>
  //               <Col>
  //                 <span>{key}</span>
  //                 <InputFieldInSegment
  //                   labelName={(
  //                     key.split(".")[1].charAt(0).toUpperCase() +
  //                     key.split(".")[1].slice(1)
  //                   )
  //                     .replace(/([A-Z])/g, " $1")
  //                     .trim()}
  //                   value={(
  //                     obj[key].charAt(0).toUpperCase() + obj[key].slice(1)
  //                   )
  //                     .replace(/([A-Z])/g, " $1")
  //                     .trim()}
  //                 />
  //               </Col>
  //             </div>
  //           );
  //         }
  //       }
  //     }
  //   } else {
  //     if (Object.keys(data)[0] === "$and" || Object.keys(data)[0] === "$or") {
  //       const operationString = Object.keys(data)[0] === "$or" ? "OR" : "AND";
  //       renderData(operationString);
  //       return (
  //         <div style={{ paddingBottom: "1rem" }}>
  //           <span
  //             style={{
  //               fontWeight: "bold",
  //               marginBottom: "12px",
  //             }}
  //           >
  //             {Object.keys(data)[0] === "$or" ? "OR" : "AND"}
  //           </span>
  //         </div>
  //       );
  //       functionData(Object.values(data)[0]);
  //     }
  //   }
  // };

  // const onDetailClick = (data) => {
  //   swalMessage({
  //     content: (
  //       <div>
  //         <p>
  //           Segment created by:&nbsp;&nbsp;
  //           <span style={{ fontWeight: "600" }}>{userName}</span>
  //         </p>
  //         <div>{functionData(data?.queryData)}</div>
  //       </div>
  //     ),
  //     buttons: false,
  //   });
  // };
  const onViewClick = (data) => {
    swalMessage({
      content: (
        <div>
          <p>
            Segment created by:&nbsp;&nbsp;
            <span style={{ fontWeight: "600" }}>{userName}</span>
          </p>
          <div>
            {"$or" in data?.queryData || "$and" in data?.queryData ? (
              data?.queryData?.[Object.keys(data?.queryData)[0]].map(
                (obj, index) =>
                  Object.keys(obj)[0] === "$and" ||
                  Object.keys(obj)[0] === "$or" ? (
                    Object.values(obj)[0].map((innerObj, index) => (
                      <div>
                        <Col>
                          <InputFieldInSegment
                            labelName={(
                              Object.keys(innerObj)[0]
                                ?.split(".")[1]
                                ?.charAt(0)
                                ?.toUpperCase() +
                              Object.keys(innerObj)[0]?.split(".")[1]?.slice(1)
                            )
                              ?.replace(/([A-Z])/g, " $1")
                              ?.trim()}
                            value={(
                              Object.values(innerObj)[0]
                                ?.charAt(0)
                                ?.toUpperCase() +
                              Object.values(innerObj)[0]?.slice(1)
                            )
                              ?.replace(/([A-Z])/g, " $1")
                              ?.trim()}
                          />
                        </Col>
                        <div style={{ paddingBottom: "1rem" }}>
                          <span
                            style={{
                              display:
                                index !==
                                data?.queryData?.[
                                  Object.keys(data?.queryData)[0]
                                ].length -
                                  1
                                  ? ""
                                  : "none",
                              fontWeight: "bold",
                              marginBottom: "12px",
                            }}
                          >
                            {Object.keys(obj)[0] === "$or" ? "OR" : "AND"}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <Col>
                        <InputFieldInSegment
                          labelName={(
                            Object.keys(obj)[0]
                              ?.split(".")[1]
                              ?.charAt(0)
                              ?.toUpperCase() +
                            Object.keys(obj)[0]?.split(".")[1]?.slice(1)
                          )
                            ?.replace(/([A-Z])/g, " $1")
                            ?.trim()}
                          value={(
                            Object.values(obj)[0]?.charAt(0)?.toUpperCase() +
                            Object.values(obj)[0]?.slice(1)
                          )
                            ?.replace(/([A-Z])/g, " $1")
                            ?.trim()}
                        />
                      </Col>
                      <div style={{ paddingBottom: "1rem" }}>
                        <span
                          style={{
                            display:
                              index !==
                              data?.queryData?.[Object.keys(data?.queryData)[0]]
                                .length -
                                1
                                ? ""
                                : "none",
                            fontWeight: "bold",
                            marginBottom: "12px",
                          }}
                        >
                          {Object.keys(data?.queryData)[0] === "$or"
                            ? "OR"
                            : "AND"}
                        </span>
                      </div>
                    </div>
                  )
              )
            ) : (
              // )
              <div>
                <Col>
                  <InputFieldInSegment
                    labelName={(
                      Object.keys(data?.queryData)[0]
                        ?.split(".")[1]
                        ?.charAt(0)
                        ?.toUpperCase() +
                      Object.keys(data?.queryData)[0]?.split(".")[1]?.slice(1)
                    )
                      ?.replace(/([A-Z])/g, " $1")
                      ?.trim()}
                    value={(
                      Object.values(data?.queryData)[0]
                        ?.charAt(0)
                        ?.toUpperCase() +
                      Object.values(data?.queryData)[0]?.slice(1)
                    )
                      ?.replace(/([A-Z])/g, " $1")
                      ?.trim()}
                  />
                </Col>
              </div>
            )}
          </div>
        </div>
      ),
      buttons: false,
    });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Query Result</Card.Title>
      </Card.Header>
      <Card.Body>
        <TablePagination columnsData={columnsData} mockData={tableData} />
      </Card.Body>
      <Modal className="fade" show={editNameModal} size="lg">
        <Modal.Header>
          <Modal.Title>Enter Segment Name</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditNameModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <label className="text-label">Segment Name*</label>
            <div
            // className={
            //   formErrors.nameCamp && "border border-danger input-test"
            // }
            >
              <input
                type="text"
                // style={style}
                name="segmentName"
                className="form-control"
                placeholder="Segment Name"
                required
                // value={stepOneDetails.nameCamp}
                onChange={(e) => setEditName(e.target.value)}
              />
            </div>
            {/* {formErrors.nameCamp && (
                <div className="text-danger fs-12">{formErrors.nameCamp}</div>
              )} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setEditNameModal(false)}
            variant="danger light"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="fade" show={basicModal} size="xl">
        <Modal.Header>
          <Modal.Title>Showing users after Segmentation</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => onShowDataModalClick()}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Col lg={12}>
            {/* {isFetchDataLoaded && basicModal ? (
              <ReactSpinner />
            ) : ( */}
            <Table responsive>
              <thead className="thead-info">
                {/* <tr>
                  {Object.keys(userQueryRequestData[0]).map((data) => (
                    <th scope="col">
                      {data.replace(/([A-Z])/g, " $1").trim()}
                    </th>
                  ))}
                </tr> */}
                <tr>
                  <th>sourceId</th>
                  <th>eventName</th>
                  <th>userEmail</th>
                  <th>userID</th>
                </tr>
              </thead>
              <tbody>
                {userQueryRequestData?.map((queryData, i) => (
                  <tr>
                    <th>{queryData.sourcerequestid}</th>
                    <td>{queryData.eventName}</td>
                    <td>{queryData.userEmail}</td>
                    <td>{queryData.userId}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* )} */}
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onShowDataModalClick()} variant="danger light">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};
export default QueryResultTable;
