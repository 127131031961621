import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import QueryCard from "./QueryCard";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { logoutRequest } from "../../../actions/Login/logout";
import { useDispatch, useSelector } from "react-redux";
import { resetLoginDetails } from "../../../actions/Login/loginAction";
import { resetDashboardDetails } from "../../../actions/Dashboard/dashboardAction";
import { callApi } from "../../../services/apiHelper";
import PageBreadcrumbsTitle from "../Comman/PageBreadcrumbsTitle";
import ReactSpinner from "../Comman/ReactSpinner";
import QueryResultTable from "./QueryResultTable";
import { ToastContainer, toast } from "react-toastify";
import {
  getQueryCountRequestAction,
  setIsQueryLoaded,
  setQueryCountRequestError,
  getSegmentListByCampaignRequest,
} from "../../../actions/Segmentation/segmentationAction";
import AdsManagers from "./AdsManagers";

const Optimize = () => {
  const [queryResult, setQueryResult] = useState([
    // {
    //   queryTime: "06:24 am 24 Mar 2022",
    //   source: "Segmentation",
    //   count: 12,
    // },
  ]);
  const { campaignById } = useSelector((state) => state.dashboardReducers);
  const [radioOption, setRadioOption] = useState("filterByUser");
  const [operationName, setOperationName] = useState("");
  const {
    failerQueryCount,
    showData,
    segmentListByCampaign,
    userPropertyFieldList,
  } = useSelector((state) => state.segmentationReducer);

  const [queryArray, setQueryArray] = useState([
    {
      queryNumber: 1,
      type: "UserProperty",
      relation: {},
      queryData: {},
      opeartion: "1",
    },
  ]);
  const [disableFilter, setDisableFilter] = useState(true);
  const [showCountFilter, setShowCountFilter] = useState(true);
  const [selectedTab, setSelectedTab] = useState("UserProperty");
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchFieldList = async () => {
    const getData = await callApi(
      `/segment/getFieldsList?fieldType=${selectedTab}`,
      "GET",
      {},
      {},
      {}
    ).catch((error) => {
      if (
        error.response.status === 401 ||
        error.response.data.message.contains("Token is invalid")
      ) {
        dispatch(resetLoginDetails());
        dispatch(resetDashboardDetails());
        logoutRequest(history);
      }
    });
    return getData;
  };
  const { data } = useQuery("fetchFieldList", () => fetchFieldList());

  const radios = [
    { name: "AND", value: "1" },
    { name: "OR", value: "2" },
  ];

  const notifyBottomRight = () => {
    toast.error("❌ Request Failed!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  useEffect(() => {}, [selectedTab, radioOption]);

  useEffect(() => {
    setQueryResult(segmentListByCampaign);
  }, [segmentListByCampaign]);

  useEffect(() => {
    // if (failerQueryCount) {
    //   notifyBottomRight();
    // }
    // console.log("failerQueryCount", failerQueryCount);
    // dispatch(setQueryCountRequestError(false));
  }, [failerQueryCount]);

  // useEffect(() => {
  //   if (queryArray.length === 3) {
  //     setDisableFilter(true);
  //   }
  // }, [queryArray]);
  useEffect(() => {
    dispatch(getSegmentListByCampaignRequest({ campaignId: campaignById._id }));
  }, [campaignById, dispatch]);

  const handleAddCard = (i) => {
    setDisableFilter(true);
    setShowCountFilter(true);
    const values = [...queryArray];
    values.push({
      queryNumber: values.length + 1,
      type: selectedTab,
      queryData: {},
      opeartion: i < 0 ? "1" : "",
    });
    setQueryArray([...values]);
  };

  const onOperatorButton = (i, name) => {
    const values = [...queryArray];
    values[i].opeartion = name;
    setQueryArray([...values]);
  };

  // const validate = (queryArray) => {
  //   let errors = {};
  //   if (queryArray?.length !== 0) {
  //     if (queryArray.propertyName === "UserProperty") {
  //       const queryData = queryArray.queryData;
  //       if (!queryData?.labelName) {
  //         errors.labelName = "Please Select lable";
  //       }
  //       if (!queryData?.operationName) {
  //         errors.labelName = "Please Select Operation";
  //       }
  //       if (!queryData?.value || !queryData?.valuOne || !queryData?.valueTwo) {
  //         errors.value = "Please add Value";
  //       }
  //     }
  //   }
  //   return errors;
  // };

  const onShowCountClick = () => {
    // dispatch(setShowSegmentData(false));
    dispatch(setIsQueryLoaded(true));
    const obj = {};

    const dataObject = queryArray.map((data, i) => {
      obj[data.queryNumber] = {
        type: data.type,
        relation: {
          with: data.queryNumber + 1,
          type: data.opeartion === "1" ? "AND" : "OR",
        },
        listOfqueries: [data.queryData],
      };
      if (i === queryArray.length - 1) {
        delete obj[data.queryNumber].relation.with;
        delete obj[data.queryNumber].relation.type;
      }
      return obj;
    });

    let constData = dataObject[0];
    constData.campaignId = campaignById._id;
    constData.showData = showData;

    dispatch(getQueryCountRequestAction(constData));
    dispatch(getSegmentListByCampaignRequest({ campaignId: campaignById._id }));
    if (failerQueryCount) {
      notifyBottomRight();
      dispatch(setQueryCountRequestError(false));
    }
  };
  return (
    <>
      {Object.keys(campaignById).length === 0 ? (
        <div>Add Campaign</div>
      ) : (
        <>
          {data?.data?.data ? (
            <>
              <PageBreadcrumbsTitle motherMenu={"Create Segment"} />
              <Card>
                <Card.Header>
                  <div div key="inline-radio">
                    <Form.Check
                      size="lg"
                      inline
                      label="All Users"
                      name="group1"
                      type="radio"
                      onChange={() => setRadioOption("allUser")}
                      id={`inline-radio-2`}
                    />
                    <Form.Check
                      inline
                      label=" Filter Users by"
                      name="group1"
                      type="radio"
                      defaultChecked
                      onChange={() => setRadioOption("filterByUser")}
                      id={`inline-radio-2`}
                    />
                  </div>
                </Card.Header>
                <Card.Body>
                  {radioOption === "allUser" ? (
                    <div>
                      <input
                        value="All user"
                        disabled="true"
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <>
                      {queryArray.map((data, i) => (
                        <>
                          {i !== 0 && (
                            <ButtonGroup style={{ margin: "1rem" }}>
                              {radios.map((radio, idx) => (
                                <ToggleButton
                                  key={idx}
                                  type="radio"
                                  defaultChecked={true}
                                  style={{ backgroundColor: "rgb (237,59,91)" }}
                                  variant={"outline-primary"}
                                  className="radioButton"
                                  name="radio"
                                  value={radio.value}
                                  checked={
                                    data?.opeartion === "" && idx === 0
                                      ? radios[0].value
                                      : data?.opeartion === radio.value
                                  }
                                  onChange={(e) =>
                                    onOperatorButton(i, e.currentTarget.value)
                                  }
                                >
                                  {radio.name}
                                </ToggleButton>
                              ))}
                            </ButtonGroup>
                          )}

                          <QueryCard
                            fieldList={data?.data?.data}
                            index={i}
                            queryArray={queryArray}
                            setQueryArray={setQueryArray}
                            operationName={operationName}
                            setOperationName={setOperationName}
                            setDisableFilter={setDisableFilter}
                            setShowCountFilter={setShowCountFilter}
                            setSelectedTab={setSelectedTab}
                            selectedTab={selectedTab}
                            userPropertyFieldList={userPropertyFieldList}
                            setQueryResult={setQueryResult}
                          />
                        </>
                      ))}
                      <Row style={{ marginTop: "1rem" }}>
                        <Col sm={3}>
                          <Button
                            onClick={() => handleAddCard()}
                            disabled={disableFilter}
                          >
                            + Filter
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <span style={{ fontWeight: "600", fontSize: "18px" }}>
                        {queryResult?.length !== 0 && queryResult[0]?.count}
                      </span>
                      {/* <div
                    className="form-group mb-3"
                    style={{ marginTop: "1rem" }}
                  >
                    <div>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="gridCheck"
                        disabled={
                          radioOption === "allUser" ? false : disableFilter
                        }
                        onClick={() => {
                          dispatch(setShowSegmentData(true));
                        }}
                        style={{ marginTop: "14px" }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckBox1"
                      >
                        Fetch Data
                      </label>
                    </div>
                  </div> */}
                    </Col>
                    <Col sm={2}>
                      <Button
                        onClick={() => onShowCountClick()}
                        disabled={
                          radioOption === "allUser" ? false : showCountFilter
                        }
                      >
                        Show Count
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
              <QueryResultTable
                queryResultArray={queryResult}
                setQueryResult={setQueryResult}
              />
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <div className="mt-3">
                <AdsManagers />
              </div>
            </>
          ) : (
            <ReactSpinner />
          )}
        </>
      )}
    </>
  );
};

export default Optimize;
