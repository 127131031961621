import React, { useState, useEffect } from "react";
import CampaignInfluencerCard from "./CampaignInfluencerCard";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const InfluencerDetailsLinks = () => {
  const history = useHistory();
  const { campaignById } = useSelector((state) => state.dashboardReducers);
  const [influList, setInfluList] = useState([]);

  useEffect(() => {
    const data = campaignById?.influencers?.map((obj) => {
      return {
        influencerName: obj.name,
        shortLink: obj.shortLink,
        utmLink: obj.utmLink,
      };
    });

    setInfluList([...data]);
  }, [campaignById]);

  const onClickHandle = () => {
    history.push("/manageCampaign");
  };
  return (
    <>
      <h1> Influencer List</h1>
      <Row>
        {influList?.map((dataObj) => (
          <Col sm={4}>
            <CampaignInfluencerCard inf={dataObj} />
          </Col>
        ))}
      </Row>

      <Button onClick={() => onClickHandle()}>Back</Button>
    </>
  );
};

export default InfluencerDetailsLinks;
