import React, { useEffect, useState } from "react";
import DonutPieChart from "./DonutPieChart";
import { Row, Card, Col } from "react-bootstrap";
import PageBreadcrumbsTitle from "../Comman/PageBreadcrumbsTitle";
import { setEventMatrixValue } from "../../../actions/Analyze/analyzeAction";
import CustomDateRangePicker from "../Comman/CustomDateRangePicker";
import {
  getCitiesDataRequest,
  getCountriesDataRequest,
  setInfluencerEventData,
  getDeviceEventDataRequest,
} from "../../../actions/Analyze/analyzeAction";
import Select from "react-select";
import { callApi } from "../../../services/apiHelper";
import { useDispatch, useSelector } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/styles/index.less";

const InfluencerMix = (props) => {
  const dispatch = useDispatch();
  const {
    eventMatrixList,
    campaignById,
    isInfluencerContainer,
    breadCrumbsName,
    cardHeader,
    optionEvent,
    selectedEvent,
    isCitiesContainer,
    isCountryContainer,
    deviceEventDetailsData,
    isDeviceEventContainer,
    citiesArray,
    countriesArray,
    dateRange,
    eventInfluencer,
  } = props;
  const [citiesEvent, setCitiesEvent] = useState(selectedEvent?.value);
  const [countriesEvent, setCountriesEvent] = useState(selectedEvent?.value);
  const [deviceEvent, setDeviceEvent] = useState(selectedEvent?.value);
  const {
    citiesDateRange,
    countryDateRange,
    influencerDateRange,
    deviceEventDateRange,
    influencerEventArray,
    selectedEventMatrixValue,
  } = useSelector((state) => state.analyzeReducers);

  useEffect(() => {
    if (isCitiesContainer) {
      const data = {
        eventName: citiesEvent,
        campaignId: campaignById?._id,
        type: "eventCount",
        startDate: citiesDateRange?.startDate,
        endDate: citiesDateRange?.endDate,
      };
      dispatch(getCitiesDataRequest(data));
    }
  }, [
    dispatch,
    campaignById,
    isCitiesContainer,
    citiesEvent,
    citiesDateRange,
    selectedEvent,
  ]);

  useEffect(() => {
    if (isCountryContainer) {
      const data = {
        eventName: countriesEvent,
        campaignId: campaignById?._id,
        type: "eventCount",
        byCountry: "true",
        startDate: countryDateRange?.startDate,
        endDate: countryDateRange?.endDate,
      };

      dispatch(getCountriesDataRequest(data));
    }
  }, [
    dispatch,
    campaignById,
    countryDateRange,
    isCountryContainer,
    countriesEvent,
    selectedEvent,
  ]);

  useEffect(() => {
    if (isDeviceEventContainer) {
      const data = {
        eventName: deviceEvent,
        campaignId: campaignById?._id,
        type: "eventCount",
        startDate: deviceEventDateRange?.startDate,
        endDate: deviceEventDateRange?.endDate,
      };

      dispatch(getDeviceEventDataRequest(data));
    }
  }, [
    dispatch,
    isDeviceEventContainer,
    campaignById,
    deviceEventDateRange,
    deviceEvent,
    selectedEvent,
  ]);

  useEffect(() => {
    eventInfluencer?.map(async (obj) => {
      await callApi(
        "/resource/eventsMatrix/getEventDetails",
        "POST",
        {
          // setInfluencerEvent(e.value);
          eventName: selectedEventMatrixValue,
          campaignId: campaignById?._id,
          startDate: influencerDateRange?.startDate,
          influencerId: obj,
          endDate: influencerDateRange?.endDate,
        },
        {},
        {}
      ).then((data) => {
        const datavalue = data?.data?.data;
        dispatch(setInfluencerEventData(datavalue, selectedEventMatrixValue));
      });
    });
  }, [
    dispatch,
    campaignById,
    selectedEventMatrixValue,
    influencerDateRange,
    eventInfluencer,
    eventMatrixList,
  ]);

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f5f2f2" : isSelected ? "#f9e3cb" : null,
        color: "#333333",
      };
    },
  };

  const onChangeHandle = (e) => {
    if (isCitiesContainer) {
      setCitiesEvent(e.value);
    } else if (isCountryContainer) {
      setCountriesEvent(e.value);
    } else if (isDeviceEventContainer) {
      setDeviceEvent(e.value);
    } else {
      dispatch(setInfluencerEventData([]));
      // setInfluencerEvent(e.value);
      dispatch(setEventMatrixValue(e.value));
    }
  };

  return (
    <>
      <PageBreadcrumbsTitle motherMenu={breadCrumbsName} />
      <Row style={{ marginBottom: "1rem" }}>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <h4 className="card-title">{cardHeader}</h4>
            </Card.Header>

            <Card.Body>
              <Row className="d-flex justify-content-end">
                <Col xl="3" sm="3" lg="4">
                  <div>
                    <label className="text-label">
                      <h5>Event List</h5>
                    </label>
                    <Select
                      className="singleSelectMenu"
                      defaultValue={selectedEvent}
                      options={optionEvent}
                      styles={colourStyles}
                      onChange={(e) => onChangeHandle(e)}
                    />
                  </div>
                </Col>
                <Col xl="3" sm="3" lg="4">
                  <div>
                    <label className="text-label">
                      <h5>Date</h5>
                    </label>
                    <div>
                      <CustomDateRangePicker
                        fontWidth="100%"
                        isCitiesContainer={isCitiesContainer}
                        isCountryContainer={isCountryContainer}
                        isInfluencerContainer={isInfluencerContainer}
                        isDeviceEventContainer={isDeviceEventContainer}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div style={{ marginLeft: "2rem", width: "100%" }}>
                {/* {influencerArray?.length === 0 && !isCitiesContainer ? (
                  <div class="text-center mt-3">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : ( */}
                <DonutPieChart
                  campaignById={campaignById}
                  eventMatrixList={eventMatrixList}
                  isCitiesContainer={isCitiesContainer}
                  citiesArray={citiesArray}
                  countriesArray={countriesArray}
                  dateRange={dateRange}
                  isCountryContainer={isCountryContainer}
                  influencerEventArray={influencerEventArray}
                  deviceEventDetailsData={deviceEventDetailsData}
                  isDeviceEventContainer={isDeviceEventContainer}
                />
                {/* )} */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InfluencerMix;
