import { takeLatest, call, put } from "redux-saga/effects";
import { CAMPAIGN_DETAILS } from "../constants/actionConstants";
import {
  createCampaign,
  updateCampaign,
  deleteCampaign,
} from "../services/createCampaignService";
import swal from "sweetalert";
import {
  setOnSuccessCampaignList,
  getCampaignListRequest,
  getCampaignByIdRequest,
  getRecentCampaignRequest,
} from "../actions/Dashboard/dashboardAction";
import {
  setCampaignSuccessResponse,
  setIsCampaignNamePresent,
  setSuccessDeleteResponse,
} from "../actions/Campaign/addCampaignAction";
import { logoutRequest } from "../actions/Login/logout";
import { resetDashboardDetails } from "../actions/Dashboard/dashboardAction";
import { resetLoginDetails } from "../actions/Login/loginAction";

export function* createCampaignRequestSaga(action) {
  try {
    const data = yield call(createCampaign, action.value);
    swal(
      "Campaign created Successfully!",
      "You will be able to perform other action.",
      "success",
      {
        buttons: false,
      }
    );
    yield put(getCampaignListRequest(action.history));
    yield put(getCampaignByIdRequest(data?.data?.data?.campaignId));
    yield put(setCampaignSuccessResponse(true));
    yield put(setOnSuccessCampaignList(true));
  } catch (error) {
    if (error?.response?.data?.message === "Campaign name already exist") {
      yield put(setIsCampaignNamePresent(true));
      yield action.history.push("/addCampaign");
    }
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* updateCampaignRequestSaga(action) {
  try {
    const data = yield call(updateCampaign, action.value);
    yield put(getCampaignByIdRequest(data?.data?.data?.updatedCampaign?._id));
    yield put(getCampaignListRequest(action.history));
    swal(
      "Campaign is successfully updated!",
      "You will be able to perform other action.",
      "success",
      {
        buttons: false,
      }
    );
    yield action.history.push("/dashboard");
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export function* deleteCampaignRequestSaga(action) {
  try {
    yield call(deleteCampaign, action.value);
    swal(
      "Campaign deleted Successfully!",
      "You can create new Campaign.",
      "success",
      {
        buttons: false,
      }
    );
    yield put(getCampaignListRequest(action.history));
    yield put(getRecentCampaignRequest(action.history));
    yield put(setSuccessDeleteResponse(true));
  } catch (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.data?.message.contains("Token is invalid")
    ) {
      yield put(resetLoginDetails());
      yield put(resetDashboardDetails());
      logoutRequest(action.history);
    }
  }
}

export default function* createCampaignSaga() {
  yield takeLatest(
    CAMPAIGN_DETAILS.CREATE_CAMPAIGN_REQUEST,
    createCampaignRequestSaga
  );
  yield takeLatest(
    CAMPAIGN_DETAILS.UPDATE_CAMPAIGN_REQUEST,
    updateCampaignRequestSaga
  );
  yield takeLatest(
    CAMPAIGN_DETAILS.DELETE_CAMPAIGN_REQUEST,
    deleteCampaignRequestSaga
  );
}
